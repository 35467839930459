//Расшаривалка, которая появляется при выделении текста в блоке
//основана на обычной расшаривалке
//Также есть просто класс share-on-select-component-elem
//который навешивается для ослеживания выделения и появления бокса
.share-on-select-component-box {
  display: none;
  height: 45px;
  box-sizing: border-box;
  padding: 10px;
  background: $white;
  box-shadow: 0 0 2px 2px rgba($black, .1);
  border-radius: 2px;
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 10;
  @extend .clearfix;

  &.__active {
    display: block;
  }

  > * {
    display: inline-block;
    float: left;

    &:first-of-type {
      margin-right: .5rem;
    }
  }

  .ct-typo {
    padding:  .2em .5em .2em 0;
    color: #999;
    text-transform: uppercase;
    font-size: 1.05rem;
  }
}

//Окошко расшаривалки когда опечатку выделили. Его стили специально из компонента
//вынесли, потому что оно js-сом выносится в конец body
.share-on-select-component-mistype-thanx {
  position: fixed;
  z-index: 10;
  right: 20px;
  top: 20px;
  padding: 20px 20px 0 60px;
  width: 250px;
  box-sizing: border-box;
  background: $white;
  box-shadow: 0 0 5px 5px rgba($black, .1);
  display: none;

  p {
    font-size: 1.3em;
  }

  span {
    position: absolute;
    left: 10px;
    top: 20px;
    @include svg-image("ok", 40, 40);
  }
}