section.main.profile.index.edit {
  //Левая колонка
  .leftcol {
    .avatar {
      cursor: pointer;

      input[type=file] {
        display: none;
      }

      .shadow {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255, .5);
      }

      .upload {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -15px;
        margin-top: -15px;
        @include svg-image("upload", 30, 30);
      }
    }

    .file-name {
      margin: .6em 0 0 0;
    }
  }

  //Правая колонка
  .rightcol {
    button[type=submit] {
      color: $white;
      background: #6DCD4C;
      display: block;
      width: 100%;
      box-sizing: border-box;
      text-transform: uppercase;
      padding: .7em 1em;
      border: none;
      cursor: pointer;
      font-size: 1.1em;
    }

    //Вк, фб
    .table-data {
      .row {
        &.social {
          .cell {
            vertical-align: middle;

            &:first-child {
              text-align: right;
              padding-right: 10px;

              span {
                &.vk {
                  @include svg-image("vk-color", 44, 30);

                  &.disabled {
                    @include svg-image("vk-color-disabled", 44, 30);
                  }
                }

                &.fb {
                  @include svg-image("fb-color", 44, 30);

                  &.disabled {
                    @include svg-image("fb-color-disabled", 44, 30);
                  }
                }
              }
            }

            &:last-child {
              input {
                min-width: 14em;
              }
            }
          }
        }

        &.date {
          .calendar-icon {
            @include svg-image("calendar", 14, 14);
            margin: 1px 0 0 4px;
          }
        }

        .cell {
          .checkbox {
            margin: .5em 0 0 0;
            @extend .clearfix;

            .text {
              display: inline-block;
              float: left;
              color: #a7a7a7;
              font-size: .85em;
              margin: .15em 0 0 0;
            }

            label {
              float: left;
              display: inline-block;
              margin: 0 0 0 10px;
              cursor: pointer;

              em {
                @include sprite-image("checkbox2", 44, 20);
              }

              input[type=checkbox] {
                display: none;
              }

              input[type=checkbox]:checked + em {
                @include sprite-image("checkbox2-checked", 44, 20);
              }
            }
          }
        }

        &.tags {
          .search-title {
            font-size: .8em;
            color: #717171;
            margin: 0 0 .4em 0;
          }

          input.search-field {
            display: block;
            border: 1px solid #979797;
            width: 100%;
            border-radius: 0;
            padding-top: .4em;
            padding-bottom: .4em;
            margin: 0 0 .8em 0;
          }

          .added-items {
            .item {
              &::after {
                content: " -";
              }
            }
          }

          .to-add-items {
            .item {
              &::after {
                content: " +";
              }
            }
          }
        }
      }
    }
  }

  //Третья колонка
  .thirdcol {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        a {
          padding: .7em 20px 0.7em 0;
          display: block;
          border-bottom: 1px solid #DADADA;
          text-transform: uppercase;
          color: $black;
          background: image-url("svg-icons/arrow-right-grey.svg")  no-repeat;
          background-position: 100% 10px;
          background-size: 9px 17px;

          .num {
            display: inline-block;
            color: #434343;
            padding: .1em 1.1em;
            border-radius: 1em;
            font-size: .8em;
            border: 1px solid #434343;
            margin: 0 0 0 1em;
            position: relative;
            top: -.3em;
          }
        }

        &:first-child {
          a {
            border-top: 1px solid #dadada;
          }
        }
      }
    }
  }

  @media #{$large-up} {
    //Левая колонка
    .leftcol {
      .avatar {
        .upload {
          @include svg-image("upload", 40, 40);
        }
      }
    }

    //Правая колонка
    .rightcol {
      button[type=submit] {
        display: inline-block;
        width: auto;
        padding-left: 2em;
        padding-right: 2em;
      }

      .table-data {
        .row {
          &.social, &.submit {
            .cell {
              &:first-child {
                &:after {
                  content: "";
                }
              }
            }
          }
        }
      }
    }
    //Третья колонка
    .thirdcol {
      float: right;
      margin-left: 20px;
      width: 200px;

      ul {
        li {
          &:first-of-type {
            a {
              border-top: none;
            }
          }
        }
      }
    }
  }

  @media #{$xlarge-up} {
    //Третья колонка
    .thirdcol {
      float: left;
      margin-left: 50px;
      width: 280px;

      ul {
        li {
          a {
            color: #434343;
            font-size: 1.2em;
            background-position: 100% 13px;
          }
        }
      }
    }
  }
}