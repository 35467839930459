section.main.films.cinema {
  //Основное изображение
  .cinema-image {
    color: $white;
    background-size: cover;
    background-position: center center;
    font-size: 1rem;

    .shadow {
      background: linear-gradient(to top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .3) 50%);
      padding-top: 2.5em;
      padding-bottom: 1em;
      position: relative;
      min-height: 180px;
    }

    .ct-fi-open {
      right: 15px;
      bottom: 130px;
    }


    .like-recommends {
      position: absolute;
      right: 0;
      top: 0;
    }

    .title {
      display: inline-block;
      margin: 0 0 .5em 0;
      font-size: 1.7em;
      color: $white;
    }

    .meta {
      font-size: .85rem;
      margin: 0 140px 0 0;

      .location {
        margin: 0 0 .5em 0;

        .ct-icon, .ct-text {
          cursor: pointer;
        }
      }

      .phone {
        margin: 0 0 .5em 0;
      }

      .website {
        margin: 0 0 1em 0;
      }

      .social {
        margin: 0 0 1em 0;

        a {
          display: inline-block;
          float: left;
          margin: 0 20px 0 0;

          &.fb {
            @include svg-image("fb-transparent", 11, 20);
          }

          &.vk {
            @include svg-image("vk-transparent", 23, 13);
            margin-top: 3px;
          }

          &.email {
            @include svg-image("email-transparent", 19, 15);
            margin-top: 2px;
            margin-right: 0;
          }
        }
      }
    }

    .rate-title {
      opacity: .7;
      margin: 0 0 .5em 0;
    }

    .map {
      position: absolute;
      right: 10px;
      bottom: 10px;
      height: 110px;
      width: 130px;
    }
  }

  //Отзывы
  .reviews {
    position: relative;
    margin: 0 0 2.5em 0;

    .control {
      position: absolute;
      top: 30px;
      cursor: pointer;

      &.left {
        left: 0;
        @include sprite-image("arrow-left-corner-black-small", 9, 14);
      }

      &.right {
        right: 0;
        @include sprite-image("arrow-right-corner-black-small", 9, 14);
      }
    }

    .root {
      position: relative;
      overflow: hidden;
      margin: 0 15px 0 20px;

      .list {
        width: 20000em;
        position: relative;

        .item {
          float: left;
          //За шириной чтобы было респонсив будет следить JS
          width: 270px;

          .user {
            float: left;
            width: 55px;

            a.avatar {
              display: block;
              position: relative;
              margin: 0 0 .5em 0;

              img {
                border-radius: 50%;
                width: 100%;
              }
            }

            a.name {
              text-transform: uppercase;
              color: $black;
              font-size: .75em;
              display: inline-block;
            }
          }

          .text {
            margin: 0 0 0 60px;
            font-size: .9em;
            border-radius: 10px;
            padding: .5em 1em;
          }
        }
      }
    }
  }

  //Фильмы
  .movies {
    margin: 2.5em 0 0 0;

    article {
      padding: 0 0 .5em 0;
      margin: 0 0 1.5em 0;
      border-bottom: 1px solid #E0E0E0;
      @extend .clearfix;

      &:last-child {
        border-bottom: none;
      }

      .img {
        float: left;
        width: 25%;
        margin: 5px 0 0 0;
        position: relative;

        .poster-container {
          display: block;

          .poster {
            width: 100%;
          }

          .default {
            display: block;
            background-size: cover;
            background-image: image-url("svg-icons/films-default-bg.svg");
            background-position: center center;

            img {
              width: 100%;
            }
          }
        }

        .like-recommends {
          position: absolute;
          right: 0;
          top: 0;
        }

        .trailer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $black;
          padding: .3em 0 .1em 7px;

          a {
            display: inline-block;
            @extend .clearfix;
            text-transform: uppercase;
            color: $white;
            font-size: .75em;

            .caret {
              float: left;
              @include caret($white, 5, right);
              margin: .1em 0 0 0;
            }

            .text {
              display: inline-block;
              float: left;
            }
          }
        }
      }

      .meta {
        float: left;
        width: 75%;
        box-sizing: border-box;
        padding: 0 0 0 15px;

        .name {
          font-size: 1.2em;
          margin: 0 0 .3em 0;

          a {
            display: inline-block;
            color: $black;
          }
        }

        .options {
          @extend .clearfix;

          span {
            float: left;
            margin: 0 1em 0 0;

            &.imax {
              color: #b2b2b2;
              font-size: 1.1em;
            }

            &.for-children {
              @include svg-image("for-children-grey", 16, 20);
            }
          }
        }

        .genre {
          margin: .6em 0 .4em 0;
          font-size: .85em;
          color: #b2b2b2;
        }
      }

      .sessions {
        clear: both;
        @extend .clearfix;
        padding: 1.5em 0 0 0;

        .item {
          float: left;
          margin: 0 1em 1em 0;

          .time {
            display: inline-block;
            padding: .3em 1em;
            border: 1px solid $black;
            margin: 0 0 .2em 0;
            color: $black;
            font-size: 1.4em;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media #{$large-up} {
    //Основное изображение
    .cinema-image {
      .shadow {
        padding-top: 16em;
        padding-bottom: 1.5em;

        .container {
          position: relative;
          min-height: 200px;
        }
      }

      .ct-fi-open {
        top: -110px;
        right: 10px;
        bottom: auto;
      }

      .like-recommends {
        top: auto;
        bottom: 210px;
        right: 10px;
        width: 210px;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
      }

      .map {
        width: 210px;
        height: 100px;
        bottom: 100px;
        right: 10px;
      }

      .rating2-component {
        position: absolute;
        right: 10px;
        bottom: 30px;
      }

      .opinion-button-component {
        position: absolute;
        right: 10px;
        bottom: -20px;
      }

      $margin-right: 220px;

      .title {
        font-size: 3em;
        margin-right: $margin-right;
      }

      .meta {
        margin-right: $margin-right;
        margin-bottom: 1em;

        .location {
          float: left;
          width: 33%;
          margin-right: 3%;
        }

        .phone {
          float: left;
          width: 30%;
          margin-right: 3%;
        }

        .website {
          float: left;
          width: 30%;
          word-break: break-all;
        }

        .social {
          padding-top: 1em;
          clear: both;
        }
      }

      .rate-title {
        float: left;
        margin: 0 1.5em 0 0;
        font-size: 1.4em;
      }

      .rate-component {
        float: left;
        margin: .2em 0 0 0;
      }
    }

    //Отзывы
    .reviews {
      margin-bottom: 4em;

      .control {
        &.left {
          @include sprite-image("arrow-left-corner-black-big", 27, 46);
        }

        &.right {
          @include sprite-image("arrow-right-corner-black-big", 27, 46);
        }
      }

      .root {
        margin-left: 60px;
        margin-right: 60px;

        .list {
          .item {
            .user {
              width: 65px;

              a.name {
                font-size: 1em;
              }
            }

            .text {
              margin-left: 90px;
              margin-right: .7em;
              font-size: 1.2em;
            }
          }
        }
      }
    }

    //Фильмы
    .movies {
      .container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
      }

      article {
        flex-basis: 50%;
        border-bottom: none;
        padding-bottom: 0;
        margin: 0 0 3em 0;

        &:first-of-type {
          //Чтобы в сафари на новую строчку не падал правый блок
          //баг с флексами в сафари
          margin-left: -1px;
        }

        .img {
          .like-recommends {
            font-size: 1rem;
          }

          .trailer {
            padding: .4em 0 .2em 12px;

            a {
              font-size: .85em;

              .caret {
                margin: .25em 0 0 0;
              }
            }
          }
        }

        .meta {
          margin-bottom: 1em;

          .genre {
            font-size: 1em;
          }
        }

        .sessions {
          padding-top: .5em;
          clear: none;
          float: left;
          width: 75%;
          box-sizing: border-box;
          padding-left: 15px;

          .item {
            .time {
              font-size: 1.1em;
              padding-top: .3em;
              padding-bottom: .3em;
            }
          }
        }
      }
    }
  }

  @media #{$xlarge-up} {
    //Основное изображение
    .cinema-image {
      .shadow {
        $margin-right: 320px;

        .title, .meta {
          margin-right: $margin-right;
        }

        .like-recommends, .map {
          width: 300px;
        }

        .opinion-button-component {
          right: 50px;
        }
      }
    }

    //Фильмы
    .movies {
      article {
        .img {
          width: 30%;

          .like-recommends {
            &::after {
              display: inline-block;
              margin-left: .3em;
            }
          }

          .trailer {
            padding-left: 17px;
          }
        }

        .meta {
          width: 70%;
        }

        .sessions {
          width: 70%;

          .item {
            .time {
              font-size: 1.4em;
            }
          }
        }
      }
    }
  }
}

#films-cinema-map-modal {
  .map-in-modal {
    height: 300px;
  }

  @media #{$large-up} {
    .map-in-modal {
      height: 400px;
    }
  }
}
