//Теги
.tags-component {
  @include components-mixin;

  .ct-tag {
    float: left;
    font-size: .8em;
    color: $white !important;
    margin: 0 .1em .4em 0;
    display: inline-block;

    &::before {
      content: "#";
      display: inline-block;
    }
  }

  &.__grey {
    .ct-tag {
      color: #bebebe !important;
    }
  }

  &.__dark-grey {
    .ct-tag {
      color: #434343 !important;
    }
  }

  &.__transparent {
    .ct-tag {
      opacity: .8;
    }
  }

  &.__big {
    .ct-tag {
      font-size: 1.2em;
      margin-right: .4em;
    }
  }

  &.__one-size {
    font-size: 1em;
  }

  @media #{$large-up} {
    .ct-tag {
      font-size: 1em;
      margin-right: .5em;
    }

    &.__big {
      .ct-tag {
        font-size: 1.3em;
      }
    }
  }
}