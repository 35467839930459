//ВИЗИВИГ
.wysiwyg-component {
  @extend .clearfix;

  img {
    display: inline-block;
    margin: 5px;
  }

  //Пустые ссылки без атрибута href с атрибутом id у нас используются для якорей. Не показываем их
  a[id]:not([href]) {
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
  }

  //Раскрывающийся блок
  .cw_expand {
    span {
      display: block;
    }

    .text_1 {
      margin-bottom: .5em;
    }

    .text_separator {
      text-align: center;
      padding: .5em 0;
      background: #f8f8f8;
      cursor: pointer;
      font-size: .9em;
      color: #888;
      transition: background-color .4s;

      &:hover {
        background: darken(#f8f8f8, 2%);
      }
    }

    .text_2 {
      display: none;
    }

    &.__opened {
      .text_separator {
        display: none;
      }

      .text_2 {
        display: block;
      }
    }
  }

  //Галерея
  //До инициализации показываем только первое изображение
  .cw_gallery > img:not(.img_1) {
    display: none;
  }

  //Виджет с двумя меняющимися картинками
  .two_images {
    position: relative;
    overflow: hidden;

    .img_1 {
      position: relative;
      z-index: 2;
      transition: opacity .5s;

      &:hover {
        opacity: 0;
      }
    }

    //Дополнительное изображение
    .img_2 {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}