.header-section .top {
  margin: 0;
  position: relative;
  z-index: 300;
  background: $black;
  color: $white;

  > .container {
    display: flex;
    align-items: center;
    height: 60px;
  }

  a {
    color: $white;
  }

  .divider {
    flex: 1;
  }

  a.logo {
    @include svg-image("logo", 100, 36);
  }

  .city {
    margin-top: 3px;
    margin-left: 30px;
    color: $white;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.54px;

    &-current {
      position: relative;
      padding-left: 22px;
      max-width: 300px;
      cursor: pointer;

      &:hover {
        color: #6DCD4C;
      }

      &:before {
        content: '';
        position: absolute;
        top: -4px;
        left: 0;
        transform: rotate(45deg);
        @include svg-image("geo-arrow", 14, 14);
      }
    }
    &-wrapper {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #303030;
    }
    &-list {
      margin: 0 0 0 -1.5em;
      padding: 0;
      list-style-type: none;
      display: flex;
    }
    &-item {
      margin-left: 1.5em;

      &.is-active {
        color: #6DCD4C;
        pointer-events: none;
      }
    }
    &-link {
      display: inline-block;
      padding: 20px 0;
      color: inherit;
      &:hover {
        color: #6DCD4C;
        text-decoration: none;
      }
    }
  }

  .social-component {
    margin-left: 30px;
  }

  .user-lk {
    position: relative;
    display: flex;

    .user-lk-trigger {
      @include sprite-image("user", 24, 24);

      &.active {
        border: 4px solid transparent;
        outline: 1px solid $white;
        background-repeat: no-repeat;
      }
    }

    .user-lk-submenu {
      display: none;
      position: absolute;
      z-index: 5;
      right: 0;
      top: 100%;
      margin: 8px 0 0 0;
      background: $white;
      color: $black;
      padding: .8em 0 .8em 0;
      box-shadow: 0 0 7px 7px rgba($black, .1);

      .caret {
        @include caret($white, 7, "up");
        position: absolute;
        right: 8px;
        top: -14px;
      }

      a {
        color: $black;
        display: inline-block;
      }

      .name {
        text-align: center;
        padding: 0 2em .4em 2em;
        border-bottom: 1px solid #E5E5E5;
        margin: 0 0 .5em 0;

        a {
          font-size: 1.3em;
          @include text-ellipsis;
          max-width: 10em;
        }
      }

      .logout {
        text-align: center;
        padding: 0 2em 0 2em;
        text-transform: uppercase;
        font-size: .9em;
      }
    }

    &.active {
      margin-left: 14px;

      .user-lk-trigger {
        @include svg-image("user-black", 30, 30);
        border: none;
        outline: none;
      }

      .user-lk-submenu {
        display: block;
      }
    }
  }

  .menu-trigger {
    float: left;
    @include sprite-image("menu-closed", 25, 25);
    margin: 2px 22px 0 0;
    cursor: pointer;
    transition: transform .8s;

    .header-section.opened & {
        transform: rotate(180deg);
        @include sprite-image("menu-opened", 25, 25);
    }

    @media #{$large-up} {
      & {
        display: none !important;
      }
    }
  }
}
