.infinity-scroll-component {
  .ct-is-pagination {
    display: none;
  }

  .ct-is-loader {
    width: 80px;
    height: 80px;
    margin: 1em auto;
    overflow: hidden;
    display: none;
    position: relative;

    &.__visible {
      display: block;
    }
  }
}