//Компонент Центральное заведение. Код длинный, поэтому его выделили//Основное заведение
.central-place-component {
  background-size: cover;
  background-position: top center;
  margin: 0 0 2em 0;

  .ct-gradient {
    padding: 1em 0 0 0;
  }

  //Верхний блок
  .ct-top-block {
    @extend .clearfix;
    margin: 0 0 1em 0;

    .ct-logo {
      float: left;
      width: 100px;

      img {
        width: 100%;
      }
    }

    .ct-info {
      float: left;
      width: 60%;
      box-sizing: border-box;
      padding: .5em 0 0 10px;
      color: $white;
      margin: 0 0 1em 0;

      a {
        color: inherit;
      }

      .ct-type {
        opacity: .8;
        font-size: .8em;
      }

      .ct-name {
        margin: 0 0 .3em 0;
        font-size: 1.3em;
      }

      .ct-meta {
        .ct-location {
          margin: 0 0 .2em 0;
        }
      }
    }

    .ct-description {
      color: white;
      clear: both;
      font-size: 1.1em;
      margin: 0 0 1em 0;
    }

    .ct-map {
      clear: both;
      width: 270px;
      height: 100px;
    }
  }

  //Правый блок
  .ct-right-block {
    margin-left: -15px;
    margin-right: -15px;

    //Правый блок у нас может содеражать несколько вариантов совершенно
    //разной информации
    //Баннер
    &.__banner {
      padding: 20px;
      background: $black;

      .ct-img {
        display: block;
        width: 250px;
        max-width: 100%;
        margin: 0 auto 1em auto;

        img {
          width: 100%;
        }
      }

      .ct-more {
        color: $white;
        width: 250px;
        max-width: 100%;
        text-align: center;
        display: block;
        margin: 0 auto 0 auto;

        a {
          color: $white;
          font-size: 1.1em;
          background-image: image-url("svg-icons/arrow-right-white.svg");
          background-position: 100% 6px;
          background-size: 15px 28px;
          background-repeat: no-repeat;
          display: inline-block;
          padding: .5em 20px .5em 0;
        }
      }
    }

    //Фильм
    &.__film {
      padding: 20px;
      background: $black;

      .buy-ticket-component {
        max-width: 240px;
        margin: 0 auto;
      }

      .ct-film-info {
        display: block;
        width: 240px;
        max-width: 100%;
        margin: 0 auto 0 auto;
        background: #333;
        position: relative;
        padding: 0 0 1em 0;
        overflow: hidden;

        .ct-img {
          background-size: cover;
          background-position: center center;
          background-image: image-url("svg-icons/films-default-bg.svg");
          position: relative;
          margin: 0 0 .5em 0;

          .ct-options {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            @extend .clearfix;

            .ct-option {
              display: inline-block;
              float: right;
              background: rgba(0, 0, 0, .8);
              padding: .3em .7em;
              color: $white;
              font-size: .75em;
              text-transform: uppercase;
            }

            .ct-like-recommends {
              float: right;
            }
          }

          .ct-trailer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            @extend .clearfix;
            padding: .2em 5px .3em 15px;
            background: rgba($black, .7);
            color: $white;

            .ct-trigger {
              cursor: pointer;
              display: inline-block;
              text-transform: uppercase;
              font-size: .7em;
              padding: .3em .5em .3em 17px;
              position: relative;
              color: $white;

              .ct-caret {
                position: absolute;
                top: 3px;
                left: 5px;
                @include caret($white, 5, right);
              }
            }

          }

          img {
            width: 100%;
          }
        }

        .ct-name {
          padding: 0 15px 0 15px;
          margin: 0 0 .8em 0;
          font-size: 1.15em;

          a {
            display: inline-block;
            color: $white;
            max-width: 100%;
            word-wrap: break-word;
          }
        }

        .ct-duration {
          padding: 0 15px 0 15px;
          color: rgba($white, .6);
          font-size: .9em;
          margin: 0 0 .3em 0;
        }

        .ct-genre {
          color: rgba($white, .6);
          font-size: .9em;
          padding: 0 15px 0 15px;
        }
      }

      .ct-sessions {
        @extend .clearfix;
        display: block;
        width: 240px;
        max-width: 100%;
        margin: 0 auto 0 auto;

        .ct-session {
          float: left;
          color: white;
          margin: 0 27px .5em 0;
          font-size: 1.15em;
          &:nth-child(3n) {
            margin-right: 0;
          }

          a {
            display: inline-block;
            color: black;
            padding: .2em .8em;
            font-size: 1em;
            border: 1px solid white;
            margin: 0 0 .3em 0;
            background-color: white;
            &:hover {
              box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.6);
            }
          }

          span {
            display: block;
            font-size: .9em;
          }
        }
      }
    }

    //Событие
    &.__event {
      background-size: cover;
      background-position: top center;

      .ct-gradient {
        background: rgba($black, .7);
        padding: 15px 15px 10px 15px;
        color: $white;
        @extend .clearfix;

        a {
          color: inherit;
        }

        .ct-date {
          float: left;
          margin: 0 1em 1em 0;
          font-size: .9em;
        }

        .ct-category {
          float: left;
          margin: 0 0 1em 0;
          font-size: .9em;

          .ct-category-type {
            opacity: .8;
            font-size: .8em;
          }
        }

        .ct-name {
          clear: both;
          font-size: 1.2em;
          margin: 0 0 1em 0;
        }

        .ct-location {
          float: left;
          margin: 0 0 1em 0;
        }

        .ct-go {
          float: right;
        }
      }
    }

    //Прошедшие события
    &.__past-events {
      padding: 15px;
      background: $black;
      color: $white;

      a {
        color: inherit;
      }

      .ct-title {
        font-size: 1.2em;
        margin: 0 0 1em 0;
      }

      article {
        margin: 0 0 1em 0;
        padding: 0 0 1em 0;
        border-bottom: 1px solid #888;

        .ct-date {
          opacity: .7;
          text-transform: uppercase;
          font-size: .85em;
          margin: 0 0 .5em 0;
        }

        .ct-name {
          margin: 0 0 .7em 0;
        }

        .ct-meta {
          @extend .clearfix;

          .ct-r-v-l {
            float: left;
            margin: 0 1em 0 0;
          }

          .ct-go {
            font-size: .7em;
            opacity: .7;
          }
        }
      }
    }

    //Видео
    &.__video-block {
      background: $black;

      .ct-wrapper {
        max-width: 320px;
        margin: 0 auto 0 auto;

        .ct-image {
          background-size: cover;
          background-position: top center;
          position: relative;

          .ct-spacer {
            width: 100%;
          }

          .ct-play-button {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -25px 0 0 -25px;
            @include svg-image("play-button", 50, 50);
            cursor: pointer;
          }
        }

        .ct-text {
          padding: 15px 30px 15px 15px;
          color: $white;
          position: relative;
          min-height: 50px;
          box-sizing: border-box;

          a {
            color: inherit;
          }

          .ct-name {
            font-size: 1.4em;
            margin: 0 0 .7em 0;
          }

          .ct-arrow {
            position: absolute;
            right: 15px;
            top: 20px;
            @include svg-image("arrow-right-white", 14, 27);
          }

          .ct-meta {
            @extend .clearfix;

            .ct-views {
              float: left;
              margin: 0 1em .7em 0;
            }

            .ct-tags {
              float: left;
            }
          }
        }
      }
    }

    //Фото-альбом
    &.__photo-album {
      position: relative;
      background: $black;

      .ct-wrapper {
        max-width: 320px;
        position: relative;
        margin: 0 auto 0 auto;
      }

      .ct-photos {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        @extend .clearfix;
        max-height: 100%;
        overflow: hidden;

        article {
          float: left;
          width: 33.3%;
          background-size: cover;
          background-position: top center;

          img {
            width: 100%;
          }
        }
      }

      .ct-shadow {
        padding: 15px;
        position: relative;
        background: rgba($black, .7);
        color: $white;

        a {
          color: inherit;
        }

        .ct-name {
          font-size: 1.5em;
          margin: 1em 0 10em 0;
        }

        .ct-meta {
          @extend .clearfix;

          > * {
            float: left;
            margin: 0 1.2rem .5rem 0;
            min-height: 1.7rem;
            box-sizing: border-box;
          }

          .ct-text {
            opacity: .7;
          }
        }
      }
    }
  }

  @media #{$large-up} {
    .ct-gradient {
      padding-top: 50px;
      padding-bottom: 20px;
    }

    //Верхний блок
    .ct-top-block {
      float: left;
      width: 65%;

      .ct-logo {
        width: 150px;
      }

      .ct-info {
        padding-left: 20px;

        .ct-type {
          font-size: 1em;
        }

        .ct-name {
          font-size: 2.4em;
          margin-bottom: .5em;
        }

        .ct-meta {
          .ct-location {
            margin-bottom: 1em;
          }

          .ct-r-v-l {
            margin-bottom: .3em;
          }
        }
      }

      .ct-description {
        margin-left: 170px;
      }

      .ct-map {
        margin-left: 170px;
      }
    }

    //Правый блок
    .ct-right-block {
      margin-left: 0;
      margin-right: 0;
      float: right;
      width: 30%;

      //Баннер
      &.__banner {
        background: transparent;

        .ct-img, .ct-more {
          margin-left: 0;
          margin-right: 0;
        }

        .ct-more {
          text-align: left;
        }
      }

      //Фильм
      &.__film {
        padding: 0;
        background: transparent;
        margin-top: -30px;

        .buy-ticket-component {
          margin: 0;
        }

        .ct-film-info {
          display: block;
          width: auto;
          max-width: 240px;
          margin-left: 0;
          background: black;

          .ct-img {
            .ct-options {
              .ct-option {
                font-size: 1em;
              }
            }
          }
        }

        .ct-sessions {
          display: block;
          width: auto;
          max-width: 240px;
          margin-left: 0;
        }
      }

      //Событие
      &.__event {
        .ct-gradient {
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 15px;

          .ct-date {
            font-size: 1.2em;
            margin-right: 2em;
          }

          .ct-category {
            font-size: 1.2em;
          }

          .ct-name {
            font-size: 1.5em;
            margin-bottom: 2em;
          }

          .ct-location {
            margin-top: .4em;
            width: 100px;
          }
        }
      }

      //Прошедшие события
      &.ct-past-events {
        background: transparent;
        padding: 0;

        .ct-title {
          font-size: 1.6em;
          margin-bottom: 1.3em;
        }

        article {
          border-bottom-color: $white;

          .ct-date {
            font-size: .9em;
            margin-bottom: 1em;
          }

          .ct-name {
            font-size: 1.4em;
            margin-bottom: 1em;
          }

          .ct-meta {
            .ct-go {
              font-size: 1em;
            }
          }
        }
      }

      //Фото-альбом
      &.__photo-album {
        .ct-shadow {
          .ct-name {
            margin-bottom: 5em;
          }
        }
      }
    }

    //Если в правой колонке событие
    &.__with-event {
      .ct-top-block {
        width: 50%;
      }

      .ct-right-block {
        width: 45%;
      }
    }
  }

  @media #{$xlarge-up} {
    //Правый блок
    .ct-right-block {
      //Событие
      &.__event {
        .ct-gradient {
          padding: 20px 30px 20px 30px;

          .ct-name {
            font-size: 1.9em;
          }

          .ct-location {
            width: 170px;
          }
        }
      }

      //Фото-альбом
      &.__photo-album {
        .ct-shadow {
          .ct-name {
            margin-bottom: 8em;
          }
        }
      }
    }
  }
}
