.places-cards-component {
  @include components-mixin;

  margin-bottom: 53px;
  .ct-pc {
    &-list {
      @extend .clearfix;
    }
    &-item {
      position: relative;
      padding: 0 12px 20px;
      color: #FFF;
      text-decoration: none;
      overflow: hidden;
      display: flex;

      float: left;

      width: calc(100% / 2);
      flex: 0 0 calc(100% / 2);
      height: 240px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;


      @media #{$xlarge-up} {
        width: calc(100% / 5);
        flex: 0 0 calc(100% / 5);
      }
      // @media #{$xxlarge-up} {
      //   padding: 0 24px 30px;
      // }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-size: 100%;
        transition: background-size .2s ease-in-out;
      }
      &:nth-child(1n):before {
        background-image: linear-gradient(-49deg, rgba(60,59,158,0.03) 0%, rgba(106,40,143,0.95) 71%);
      }
      &:nth-child(2n):before {
        background-image: linear-gradient(61deg, rgba(92,160,52,0.95) 0%, rgba(200,204,0,0.35) 100%);
      }
      &:nth-child(3n):before {
        background-image: linear-gradient(217deg, rgba(153,30,33,0.35) 0%, rgba(76,137,76,0.90) 100%);
      }
      &:nth-child(4n):before {
        background-image: linear-gradient(238deg, rgba(142,99,33,0.35) 0%, rgba(156,52,112,0.90) 100%);
      }
      &:nth-child(5n):before {
        background-image: linear-gradient(217deg, rgba(34,70,140,0.30) 0%, rgba(36,134,147,0.95) 100%);
      }
      &:nth-child(6n):before {
        background-image: linear-gradient(34deg, rgba(92,160,52,0.95) 0%, rgba(200,204,0,0.35) 100%);
      }
      &:nth-child(7n):before {
        background-image: linear-gradient(270deg, rgba(153,30,33,0.35) 5%, rgba(76,137,76,0.90) 79%);
      }

      &:hover:before {
        background-size: 400%;
      }

      &.__large {
        height: 480px;

        @media #{$xlarge-up} {
          width: calc(100% * 375 / 1412);
          flex-basis: calc(100% * 375 / 1412);
        }

        .ct-pc-item-content {
          margin-top: 0;
        }
        .ct-pc-item-title {
          font-size: 28px;
          letter-spacing: 1.37px;

          @media #{$xxlarge-up} {
            font-size: 32px;
          }
        }
      }
      &.__wide {
        @media #{$xlarge-up} {
          width: calc(100% * 470 / 1412);
          flex-basis: calc(100% * 470 / 1412);
        }
      }

      &-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        width: 100%;
      }
      &-header {
        display: flex;
        height: 28px;
        color: #FFF;
        letter-spacing: 0.6px;
        font-size: 10px;
        line-height: 16px;

        @media #{$xxlarge-up} {
          font-size: 14px;
        }
      }
      &-content {
        margin-top: auto;
        .ct-pc-item.__large & {
          margin-top: 0;
        }
      }
      &-type {
        position: relative;
        z-index: 3;
        padding: 6px 14px 5px;
        background-color: #fff;
        text-align: center;
        color: #000;
        box-shadow: 0 3px 0 0 #D6D6D6;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 60px);

        &:hover {
          color: #000;
          text-decoration: none;
        }
      }
      &-rates {
        padding: 6px 14px 5px;
        background-color: #1C1C1C;
        box-shadow: 0 3px 0 0 #000;
        > svg {
          width: 12px;
          height: 12px;
          fill: currentColor;
        }
      }
      &-recommended {
        position: absolute;
        top: 0;
        right: 0;
        padding: 6px 14px 5px;
        background-color: #FF5050;
        box-shadow: 0 3px 0 0 #C93F3F;
        > svg {
          width: 14px;
          height: 14px;
          fill: currentColor;
        }
      }
      &-title {
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 1.03px;
        color: inherit;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: -12px;
          bottom: -20px;
          left: -12px;

          @media #{$xxlarge-up} {
            right: -24px;
            bottom: -30px;
            left: -24px;
          }
        }

        &:hover {
          color: inherit;
          text-decoration: none;
        }

        @media #{$xxlarge-up} {
          font-size: 24px;
        }
      }
      &-annotation {
        margin-top: 12px;
        font-size: 13px;
        letter-spacing: 0.69px;
        line-height: 22px;
        font-style: italic;
        max-height: 46px;
        overflow: hidden;

        @media #{$xxlarge-up} {
          font-size: 16px;
        }
      }
      &-place {
        position: relative;
        z-index: 2;
        margin-top: 5px;
        padding-left: 16px;
        font-size: 10px;
        letter-spacing: 0.64px;
        color: inherit;

        &:hover {
          color: inherit;
          text-decoration: none;
        }

        @media #{$xxlarge-up} {
          font-size: 15px;
        }

        > svg {
          position: absolute;
          top: -1px;
          left: 0;
          width: 12px;
          height: 16px;
          fill: currentColor;
          opacity: 0.85;
        }
      }
    }

  }
  .ct-pc-item-recommended + .ct-pc-item-type {
    max-width: calc(100% - 105px);
  }
}
