//Типы заведений-событий (иконки)
.e-p-types-component {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin: 0 5px 5px 0;

  //Иконки
  &.__attraction {
    background-image: image-url("e-p-types/attraction-black.svg");
  }

  &.__bar {
    background-image: image-url("e-p-types/bar-black.svg");
  }

  &.__cafe {
    background-image: image-url("e-p-types/cafe-black.svg");
  }

  &.__cinema {
    background-image: image-url("e-p-types/cinema-black.svg");
  }

  &.__circus {
    background-image: image-url("e-p-types/circus-black.svg");
  }

  &.__club {
    background-image: image-url("e-p-types/club-black.svg");
  }

  &.__concert {
    background-image: image-url("e-p-types/concert-black.svg");
  }

  &.__exhibition {
    background-image: image-url("e-p-types/exhibition-black.svg");
  }

  &.__festival {
    background-image: image-url("e-p-types/festival-black.svg");
  }

  &.__fitness {
    background-image: image-url("e-p-types/fitness-black.svg");
  }

  &.__forum {
    background-image: image-url("e-p-types/forum-black.svg");
  }

  &.__haircut {
    background-image: image-url("e-p-types/haircut-black.svg");
  }

  &.__hotel {
    background-image: image-url("e-p-types/hotel-black.svg");
  }

  &.__karaoke {
    background-image: image-url("e-p-types/karaoke-black.svg");
  }

  &.__lecture {
    background-image: image-url("e-p-types/lecture-black.svg");
  }

  &.__museum {
    background-image: image-url("e-p-types/museum-black.svg");
  }

  &.__opening {
    background-image: image-url("e-p-types/opening-black.svg");
  }

  &.__park {
    background-image: image-url("e-p-types/park-black.svg");
  }

  &.__performance {
    background-image: image-url("e-p-types/performance-black.svg");
  }

  &.__playground {
    background-image: image-url("e-p-types/playground-black.svg");
  }

  &.__presentation {
    background-image: image-url("e-p-types/presentation-black.svg");
  }

  &.__restaurant {
    background-image: image-url("e-p-types/restaurant-black.svg");
  }

  &.__rink {
    background-image: image-url("e-p-types/rink-black.svg");
  }

  &.__shop {
    background-image: image-url("e-p-types/shop-black.svg");
  }

  &.__spa {
    background-image: image-url("e-p-types/spa-black.svg");
  }

  &.__stadium {
    background-image: image-url("e-p-types/stadium-black.svg");
  }

  &.__theater {
    background-image: image-url("e-p-types/theater-black.svg");
  }

  &.__games {
    background-image: image-url("e-p-types/games-black.svg");
  }

  &.__active {
    background-image: image-url("e-p-types/active-black.svg");
  }

  &.__kids {
    background-image: image-url("e-p-types/kids-black.svg");
  }

  &.__education {
    background-image: image-url("e-p-types/education-black.svg");
  }

  &.__sport {
    background-image: image-url("e-p-types/sport-black.svg");
  }

  &.__tickets {
    background-image: image-url("e-p-types/tickets-black.svg");
  }

  &.__outdoor {
    background-image: image-url("e-p-types/outdoor-black.svg");
  }

  //Белый вариант
  &.__white {
    width: 12px;
    height: 12px;
  }

  //Полупрозрачный вариант
  &.__transparent {
    opacity: .8;
    width: 12px;
    height: 12px;
  }

  //И для белого и для полупрозрачного варианта используются белые иконки
  &.__white, &.__transparent {
    &.__attraction {
      background-image: image-url("e-p-types/attraction-white.svg");
    }

    &.__bar {
      background-image: image-url("e-p-types/bar-white.svg");
    }

    &.__cafe {
      background-image: image-url("e-p-types/cafe-white.svg");
    }

    &.__cinema {
      background-image: image-url("e-p-types/cinema-white.svg");
    }

    &.__circus {
      background-image: image-url("e-p-types/circus-white.svg");
    }

    &.__club {
      background-image: image-url("e-p-types/club-white.svg");
    }

    &.__concert {
      background-image: image-url("e-p-types/concert-white.svg");
    }

    &.__exhibition {
      background-image: image-url("e-p-types/exhibition-white.svg");
    }

    &.__festival {
      background-image: image-url("e-p-types/festival-white.svg");
    }

    &.__fitness {
      background-image: image-url("e-p-types/fitness-white.svg");
    }

    &.__forum {
      background-image: image-url("e-p-types/forum-white.svg");
    }

    &.__haircut {
      background-image: image-url("e-p-types/haircut-white.svg");
    }

    &.__hotel {
      background-image: image-url("e-p-types/hotel-white.svg");
    }

    &.__karaoke {
      background-image: image-url("e-p-types/karaoke-white.svg");
    }

    &.__lecture {
      background-image: image-url("e-p-types/lecture-white.svg");
    }

    &.__museum {
      background-image: image-url("e-p-types/museum-white.svg");
    }

    &.__opening {
      background-image: image-url("e-p-types/opening-white.svg");
    }

    &.__park {
      background-image: image-url("e-p-types/park-white.svg");
    }

    &.__performance {
      background-image: image-url("e-p-types/performance-white.svg");
    }

    &.__playground {
      background-image: image-url("e-p-types/playground-white.svg");
    }

    &.__presentation {
      background-image: image-url("e-p-types/presentation-white.svg");
    }

    &.__restaurant {
      background-image: image-url("e-p-types/restaurant-white.svg");
    }

    &.__rink {
      background-image: image-url("e-p-types/rink-white.svg");
    }

    &.__shop {
      background-image: image-url("e-p-types/shop-white.svg");
    }

    &.__spa {
      background-image: image-url("e-p-types/spa-white.svg");
    }

    &.__stadium {
      background-image: image-url("e-p-types/stadium-white.svg");
    }

    &.__theater {
      background-image: image-url("e-p-types/theater-white.svg");
    }

    &.__games {
      background-image: image-url("e-p-types/games-white.svg");
    }

    &.__active {
      background-image: image-url("e-p-types/active-white.svg");
    }

    &.__kids {
      background-image: image-url("e-p-types/kids-white.svg");
    }

    &.__education {
      background-image: image-url("e-p-types/education-white.svg");
    }

    &.__sport {
      background-image: image-url("e-p-types/sport-white.svg");
    }

    &.__tickets {
      background-image: image-url("e-p-types/tickets-white.svg");
    }

    &.__outdoor {
      background-image: image-url("e-p-types/outdoor-white.svg");
    }
  }

  @media #{$large-up} {
    &.__white {
      width: 25px;
      height: 25px;
    }

    &.__transparent {
      width: 15px;
      height: 15px;
    }
  }
}