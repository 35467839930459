//Рейтинг
.rating-component {
  @include components-mixin;

  .ct-star {
    float: left;
    margin: 0 2px 0 0;
    @include sprite-image("rating-component-star-white-small", 7, 7);
  }

  &.__grey {
    .ct-star {
      @include sprite-image("rating-component-star-grey-small", 7, 7);
    }
  }

  &.__transparent {
    .ct-star {
      opacity: .8;
    }
  }

  @mixin large() {
    .ct-star {
      @include sprite-image("rating-component-star-white-big", 15, 14);
    }

    &.__grey {
      .ct-star {
        @include sprite-image("rating-component-star-grey-big", 15, 14);
      }
    }
  }

  //Одного размера на всех экранах
  &.__one-size {
    @include large();
  }

  @media #{$large-up} {
    @include large();
  }
}