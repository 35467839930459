//Пагинация
//Тут у нас html генерирует гем. Полностью мы его не контролируем, поэтому
//стили написаны без префиксов
.pagination-component {
  text-align: center;
  font-size: 1.3rem;

  &.__text-right {
    text-align: right;
  }

  a, .current {
    display: inline-block;
    padding: .1em .7em;
    color: $black;
    font-style: normal;
  }

  .current {
    border: 1px solid $black;
  }

  a.previous_page, a.next_page {
    padding: 0;
    position: relative;
    top: .4em;
  }

  a.previous_page {
    @include sprite-image("pagination-component-prev", 14, 26);
    margin-right: .3em;
  }

  a.next_page {
    @include sprite-image("pagination-component-next", 14, 26);
    margin-left: .3em;
  }
}