section.main.places.show {
  //Основное изображение
  .place-image {
    color: $white;
    background-size: cover;
    background-position: center center;
    margin: 0 0 1em 0;
    font-size: 1rem;

    .shadow {
      background: linear-gradient(to top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .3) 50%);
      padding-top: 2.5em;
      position: relative;
      min-height: 220px;
    }

    .ct-fi-open {
      right: 15px;
      bottom: 130px;
    }

    .like-recommends {
      position: absolute;
      right: 0;
      top: 0;
    }

    .links {
      margin: 0 0 2em 0;

      a {
        display: inline-block;
        color: $black;
        background-color: rgba(255, 255, 255, .7);
        padding: .5em 1em .5em 1em;
        font-size: .7em;
        border-radius: 1.8em;
        transition: background-color .3s;

        &:hover {
          text-decoration: none;
          background-color: $white;
        }
      }
    }

    .type {
      text-transform: uppercase;
      font-size: .9em;
      margin: 0 0 .5em 0;
      color: $_font-color-transparent;

      a {
        color: inherit;
      }
    }

    .title {
      display: inline-block;
      margin: 0 0 .5em 0;
      font-size: 1.4em;
      color: $white;
    }

    .meta {
      font-size: .85rem;
      margin: 0 140px 0 0;

      .location {
        margin: 0 0 .5em 0;

        .ct-icon, .ct-text {
          cursor: pointer;
        }
      }

      .phone {
        margin: 0 0 .5em 0;
      }

      .website {
        margin: 0 0 1em 0;
      }

      .social {
        margin: 0 0 1em 0;

        a {
          display: inline-block;
          float: left;
          margin: 0 20px 0 0;

          &.fb {
            @include svg-image("fb-transparent", 11, 20);
          }

          &.vk {
            @include svg-image("vk-transparent", 23, 13);
            margin-top: 3px;
          }

          &.email {
            @include svg-image("email-transparent", 19, 15);
            margin-top: 2px;
            margin-right: 0;
          }
        }
      }
    }
  }

  //Отзывы
  .reviews {
    position: relative;
    margin: 0 0 2.5em 0;

    .control {
      position: absolute;
      top: 30px;
      cursor: pointer;

      &.left {
        left: 0;
        @include sprite-image("arrow-left-corner-black-small", 9, 14);
      }

      &.right {
        right: 0;
        @include sprite-image("arrow-right-corner-black-small", 9, 14);
      }
    }

    .root {
      position: relative;
      overflow: hidden;
      margin: 0 30px 0 30px;

      .list {
        width: 20000em;
        position: relative;

        .item {
          float: left;
          //За шириной чтобы было респонсив будет следить JS
          width: 270px;

          .user {
            float: left;
            width: 55px;

            a.avatar {
              display: block;
              position: relative;
              margin: 0 0 .5em 0;

              img {
                border-radius: 50%;
                width: 100%;
              }

              .icon {
                position: absolute;
                right: -2px;
                bottom: -2px;
                @include sprite-image("glass-icon", 25, 25);
              }
            }

            a.name {
              text-transform: uppercase;
              color: $black;
              font-size: .75em;
              display: inline-block;
            }
          }

          .text {
            margin: 0 0 0 70px;
            font-size: .9em;
            background: linear-gradient(to right, #eee, $white);
            border-radius: 10px;
            padding: .5em 1em;
          }
        }
      }
    }
  }

  //Левая колонка
  .leftcol {
    margin: 0 0 2em 0;

    .main-text {
      color: $tuatara;

      .description {
        font-size: 1.3rem;
        margin: 0 0 1em 0;
      }

      .wysiwyg-component {
        font-size: 1.2rem;
        margin: 0 0 1em 0;
        position: relative;

        p, ul, li {
          font-size: 1.2rem;
          color: $tuatara;
        }
      }
    }

    .share {
      @extend .clearfix;

      .text {
        text-transform: uppercase;
        margin: 0 0 .4em 0;
        color: #a9a9a9;
      }
    }

    .video-report {
      background-size: cover;
      background-position: center center;
      margin: 0 0 1.5em 0;
      color: $white;

      .shadow {
        background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, .17) 100%);
        padding: 2em 2em 2em 2em;

        .left {
          float: left;
          width: 75%;
          box-sizing: border-box;
          padding: .3em 0 0 0;

          .text {
            margin: 0 0 .3em 0;
          }
        }

        .play {
          float: right;
          width: 25%;
          text-align: right;

          .icon {
            cursor: pointer;
            @include sprite-image("play-small", 50, 50);
          }
        }
      }
    }

    .photo-report {
      margin: 0 0 1.5em 0;
    }
  }

  //Правая колонка
  .rightcol {
    margin: 0 0 3em 0;

    .block {
      margin: 0 0 1em 0;
      padding: 0 0 1em 0;
      border-bottom: 1px solid #DADADA;

      .title {
        color: #A4A4A4;
        font-size: 1.3em;
        margin: 0 0 .5em 0;
      }

      &.working-time {
        .interval {
          margin: 0 0 .3em 0;
          color: #434343;
          font-size: 1.2em;

          .weekday {
            min-width: 3.5em;
            display: inline-block;
          }
        }
      }

      &.check {
        .currency {
          color: #434343;
          font-size: 1.4em;

          .asymp, .num {
            display: inline-block;
            float: left;
            margin: 0 .2em 0 0;
          }

          img {
            display: inline-block;
            float: left;
            width: 11px;
            margin: .3em 0 0 .2em;
          }
        }
      }

      &.closest-events {
        border-bottom: none;
        padding-bottom: 0;

        .item {
          position: relative;
          padding-left: 30px;
          margin: 0 0 1.5em 0;

          .item-icon {
            position: absolute;
            left: -2px;
            top: .6em;
          }

          a.item-title {
            font-size: 1.5em;
            color: $black;
            display: inline-block;
          }

          .item-date {
            a {
              color: inherit;
            }
          }
        }

        .other {
          margin: 1em 0 1.5em 0;
          padding: 1em 0 0 0;
          border-top: 1px solid $black;
          text-transform: uppercase;
          font-size: 1.1em;

          a {
            color: $black;
          }
        }
      }

      &.tags {
        border-bottom: none;
        padding-bottom: 0;
      }

      .create-descr-button {
        background-color: #71B2DC;
        color: #FFFFFF;
        padding: 8px 80px;
        cursor: pointer;
        font-size: 1.4em;
        text-align: center;
        text-transform: uppercase;
        .logo {
          @include svg-image("logo-white", 56, 20);
          position: relative;
          top: 2px;
        }
      }
    }

    .next-events {
      .arrow {
        @include svg-image("arrow-down-black", 19, 11);
      }
    }

    .past-events {
      display: block;
      padding: .7em 40px .7em .3em;
      border-top: 1px solid #434343;
      border-bottom: 1px solid #434343;
      text-transform: uppercase;
      color: #434343;
      position: relative;

      .arrow {
        position: absolute;
        right: 0;
        top: .4em;
        @include svg-image("arrow-right-grey", 14, 27);
      }
    }
  }

  .places-tile {
    margin-bottom: 3em;

    a.more {
      display: inline-block;
      text-transform: uppercase;
      color: $_font-color-transparent-black;
      margin: 1em 0 .5em 15px;
    }
  }

  @media #{$large-up} {
    .place-image {
      margin-bottom: 3em;

      .shadow {
        padding-top: 12em;
        padding-bottom: 1em;
        min-height: 350px;
      }

      .ct-fi-open {
        bottom: 190px;
      }

      .links {
        a {
          font-size: 1em;
        }
      }

      .like-recommends {
        top: auto;
        bottom: 110px;
        right: 10px;
      }

      .rating2-component {
        position: absolute;
        right: 10px;
        bottom: 30px;
      }

      .opinion-button-component {
        position: absolute;
        right: 10px;
        bottom: -20px;
      }

      .type {
        text-transform: capitalize;
        font-size: 1.3em;
      }

      .title {
        font-size: 3em;
        margin-right: 210px;
      }

      .meta {
        margin-right: 200px;

        .location {
          float: left;
          width: 25%;
          margin-right: 3%;
        }

        .phone {
          float: left;
          width: 27%;
          margin-right: 3%;
        }

        .website {
          float: left;
          width: 26%;
          margin-right: 3%;
          word-break: break-all;
        }

        .social {
          float: left;
          width: 13%;

          a {
            margin-right: 12px;
          }
        }
      }
    }

    .reviews {
      margin-bottom: 4em;

      .control {
        &.left {
          @include sprite-image("arrow-left-corner-black-big", 27, 46);
        }

        &.right {
          @include sprite-image("arrow-right-corner-black-big", 27, 46);
        }
      }

      .root {
        margin-left: 60px;
        margin-right: 60px;

        .list {
          .item {
            .user {
              width: 65px;

              a.name {
                font-size: 1em;
              }
            }

            .text {
              margin-left: 90px;
              margin-right: .7em;
              font-size: 1.2em;
            }
          }
        }
      }
    }

    .leftcol {
      float: left;
      width: 65%;
      margin-bottom: 4em;

      .main-text {
        .description {
          font-size: 2.2em;
        }

        .wysiwyg-component {
          font-size: 1.4rem;

          p, ul, li {
            font-size: 1.4rem;
          }
        }
      }

      .video-report {
        .shadow {
          padding-left: 3em;
          padding-right: 3em;

          .left {
            .text {
              font-size: 1.6em;
              margin-top: 1em;
              margin-bottom: .7em;
            }
          }

          .play {
            .icon {
              @include sprite-image("play-big", 117, 117);
            }
          }
        }
      }

      .opinions-title {
        font-size: 1.54em;
        text-transform: uppercase;
      }

      .opinions-link {
        display: inline-block;
        margin-top: 1em;
        color: black;
        font-weight: bold;
        font-size: 1.23em;
        text-transform: uppercase;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -16px;
          width: 11px;
          height: 17px;
          background: image-url('svg-icons/arrow-right-thick-no-fill.svg') center center / cover;

        }
      }
    }

    .rightcol {
      float: right;
      width: 32%;

      .map {
        height: 200px;
        margin-bottom: 2em;
      }

      .block {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 2em;

        .title {
          font-size: 1.5em;
        }

        &.working-time {
          .interval {
            font-size: 1.4em;
          }
        }

        &.check {
          .title {
            margin-bottom: .2em;
          }

          .currency {
            font-size: 1.6em;

            img {
              width: 12px;
              margin-top: .35em;
            }
          }
        }
      }

      .past-events {
        text-transform: none;
        font-size: 1.5em;
        padding-top: .3em;
        padding-bottom: .3em;
      }
    }

    .places-tile {
      margin-bottom: 4em;

      .container.no-pad {
        padding-left: 15px;
        padding-right: 15px;
      }

      a.more {
        font-size: 1.3em;
        margin-left: 5px;
      }
    }
  }

  @media #{$xlarge-up} {
    .place-image {
      .container {
        position: relative;
      }

      .like-recommends {
        width: 300px;
      }

      .opinion-button-component {
        right: 60px;
        bottom: -30px;
      }

      .title, .meta {
        margin-right: 320px;
      }
    }
  }
}

#places-show-map-modal {
  .map-in-modal {
    height: 300px;
  }

  @media #{$large-up} {
    .map-in-modal {
      height: 400px;
    }
  }
}
