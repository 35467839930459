.telegram-link-component {
  margin-bottom: 1.5em;
  .ct-tl-inner {
    background: #3cacde;
    display: inline-block;
    padding: .5em 1em .5em .7em;
    border-radius: 2px;
    color: white;
    @extend .clearfix;
    img {
      float: left;
      width: 20px;
      height: 20px;
      fill: white;
      display: inline-block;
      margin-right: 1em; }
    span {
      float: left; } } }
