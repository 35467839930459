section.main.search.index {
  margin: 1em 0 0 0;

  //Вернуться к поиску
  .back-to-search {
    display: none;
  }

  //Форма поиска
  .search-form {
    @extend .clearfix;
    position: relative;
    margin: 0 0 .7em 0;

    .input-container {
      position: relative;
      background: #f2f2f2;
      border: 1px solid #ccc;
      padding-right: 66px;

      .query-container {
        @extend .clearfix;

        .query {
          height: 31px;
          display: inline-block;
          float: left;
          font-family: $font-family-sans-serif;
          width: 100%;
          outline: none;
          font-size: 1.2rem;
          color: $black;
          border-radius: 0;
          padding: .3em .5em;
          border: none;
          background: transparent;

          &::-moz-placeholder {
            font-size: 1rem;
          }
          &:-ms-input-placeholder {
            font-size: 1rem;
          }
          &::-webkit-input-placeholder {
            font-size: 1rem;
          }
        }

        .selected {
          display: inline-block;
          float: left;
          margin: .4em .5em .4em .4em;
          background: #FFA0A0;
          padding: .4em 1em;
          border-radius: 1.5em;
          font-size: .75em;
          text-transform: uppercase;
          border: 1px solid transparent;

          .remove {
            @include svg-image("cross-grey", 7, 7);
            margin: 0 0 0 .5em;
            cursor: pointer;
          }

          &.red {
            background: #FFA0A0;
          }

          &.green {
            background: #88EC62;
          }

          &.grey {
            background: #e4e4e4;
          }

          &.color {
            color: #F875C6;
            border: 1px solid #F875C6;
            background: $white;
          }
        }
      }

      .autocomplete {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        margin: 1px 0 0 0;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: rgba(#E3E3E3, .95);
        z-index: 2;

        .elem {
          cursor: pointer;
          padding: .5em 1em;
          font-size: 1.1em;

          &:hover {
            background: darken(#e3e3e3, 7%);
          }
        }

        &.active {
          display: block;
        }
      }
    }

    .buttons-container {
      position: absolute;
      right: 0;
      top: 0;
      @extend .clearfix;

      .submit, .reset {
        border: none;
        width: 33px;
        height: 33px;
        display: inline-block;
        float: right;
        cursor: pointer;
        outline: none;
      }

      .submit {
        background: #6DCD4C image-url("svg-icons/magnifier-white.svg") no-repeat center center;
        background-size: 16px 16px;
      }

      .reset {
        background: $black image-url("svg-icons/cross-white.svg") no-repeat center center;
        background-size: 15px 16px;
      }
    }
  }

  //Популярные запросы
  .popular-queries {
    overflow: hidden;
    margin: 0 0 1em 0;
    //Нету популярных запросов пока
    display: none;

    .title {
      position: relative;
      font-size: .7em;
      text-transform: uppercase;
      padding: .5em 0 0 0;
      margin: 0 0 2.5em 0;

      .time-machine {
        position: absolute;
        right: 0;
        top: 0;
        @include svg-image("time-machine-black", 19, 18);

        &.active {
          background-size: 19px 18px;
          height: 30px;
          width: 30px;
          border: 1px solid black;
          background-position: center center;
        }
      }
    }

    .queries {
      white-space: nowrap;
      overflow-x: scroll;
      padding: 0 0 .3em 0;

      a {
        padding: .8em 1em;
        color: #666;
        background: #e4e4e4;
        text-transform: uppercase;
        font-size: .75em;
        border-radius: 1.5em;
        display: inline-block;

        &:hover {
          text-decoration: none;
          background: lighten(#e4e4e4, 3%);
        }
      }
    }
  }

  //Фильтры
  .filter-block {
    display: none;
    margin: 0 0 1em 0;

    .filter-heading {
      position: relative;
      text-transform: uppercase;
      font-size: 1.2rem;
      padding: 0 20px .2em 1em;
      border-bottom: 1px solid $black;
      cursor: pointer;

      .caret {
        @include caret($black, 7, down);
        position: absolute;
        right: 0;
        top: .5em;
      }
    }

    .filter-content {
      background: #f4f4f4;
      padding: 1em 0 1em 0;
      display: none;

      .filter-item {
        padding: .4em 25px .4em 15px;
        font-size: 1.2rem;
        cursor: pointer;
        position: relative;

        .filter-item-help {
          font-size: .6em;
          text-transform: uppercase;
          color: #b7b7b7;
          display: inline-block;
          margin-left: 1em;
        }

        .filter-item-deselect {
          position: absolute;
          @include svg-image("cross-black", 10, 10);
          cursor: pointer;
          right: 10px;
          top: 10px;
          display: none;
        }

        &:hover {
          background: darken(#f4f4f4, 10%);
          cursor: pointer;
        }

        &.active {
          background: darken(#f4f4f4, 10%);

          .filter-item-deselect {
            display: block;
          }
        }
      }

      .filter-title {
        text-transform: uppercase;
        background: #E4E4E4;
        padding: .5em 15px .5em 15px;
        margin: .8em 0 0 0;
      }

      .filter-divider {
        border-bottom: 1px solid #D6D6D6;
        margin: 0 0 .5em 0;
        padding: 0 0 .5em 0;
      }

      //.filter-item могут быть внутри блока .filter-items-with-expand
      //в этом блоке есть кнопка Еще, она раскрывает список
      .filter-items-with-expand {
        .more {
          padding: .4em 15px .4em 15px;
          @extend .clearfix;
          cursor: pointer;

          .text {
            float: left;
            display: inline-block;
            text-transform: uppercase;
          }

          .caret {
            float: left;
            margin: .4em 0 0 .3em;
            @include caret($black, 6, down);
          }
          &:hover {
            background: darken(#f4f4f4, 10%);
          }
        }

        //У нас если больше 3-х .filter-item, то остальные спрятаны под кнопкой еще
        .filter-item {
          display: none;

          &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
            display: block;
          }
        }

        .filter-item:nth-of-type(1) + .more,
        .filter-item:nth-of-type(2) + .more,
        .filter-item:nth-of-type(3) + .more {
          display: none;
        }

        &.active {
          .filter-item {
            display: block;
          }

          .more {
            .caret {
              margin-top: 0;
              @include caret($black, 6, up);
            }
          }
        }
      }

    }

    //Раскрытое состояние фильтра
    &.active {
      .filter-heading {
        .caret {
          top: .2em;
          @include caret($black, 7, up);
        }
      }

      .filter-content {
        display: block;
      }
    }

    //Теги
    &.tags {
      border-bottom: 1px solid #d6d6d6;

      .tags-items {
        @extend .clearfix;
        padding-left: 1em;
        padding-right: 1em;

        .tags-item {
          display: none;
          float: left;
          border: 1px solid #F875C6;
          border-radius: 1.5em;
          padding: .3em .7em;
          background: $white;
          color: #F875C6;
          text-transform: uppercase;
          margin: 0 .5em .8em 0;
          font-size: .8rem;

          &:nth-child(2n) {
            border-color: #F07373;
            color: #F07373;
          }

          &:nth-child(3n) {
            border-color: #33A4D7;
            color: #33A4D7;
          }

          &:nth-child(4n) {
            border-color: #5087E5;
            color: #5087E5;
          }

          &:nth-child(5n) {
            border-color: #50E8D2;
            color: #50E8D2;
          }

          &:hover, &.active {
            text-decoration: none;
            box-shadow: 0 0 5px 5px rgba(255, 255, 255, 1);
          }

          &.visible {
            display: inline-block;
          }
        }

        .tags-more {
          display: none;
          clear: both;
          padding: .9em 0 0 0;
          text-align: center;
          text-transform: uppercase;
          font-size: .8em;

          a {
            color: #A3A3A3;
          }

          &.visible {
            display: block;
          }
        }
      }
    }

    //Где
    &.where {
      .filter-content {
        .filter-item {
          &.near-me {
            background-image: image-url("svg-icons/location-black.svg");
            background-repeat: no-repeat;
            background-position: 8px 9px;
            background-size: 10px 16px;
            padding-left: 30px;
          }

          &.disabled {
            cursor: default;
            opacity: .8;

            &:hover {
              background-color: inherit;
            }
          }
        }
      }
    }

    //Когда
    &.when {
      .where-date {
        padding: .5em 15px .5em 15px;
        @extend .clearfix;

        .where-date-title {
          margin: 0 0 .7em 0;
        }

        .date-select {
          -moz-appearance: none;
          -webkit-appearance: none;
          background: transparent image-url("svg-icons/caret-down-black.svg") no-repeat 95% .7em;
          background-size: 11px 7px;
          border-radius: 0;
          padding: .3em 20px .3em .3em;
          border: 1px solid $black;
          float: left;
          width: 120px;
          cursor: pointer;
          outline: none;
          font-size: 1em;
          margin: 0 0 1em 0;

          &::-moz-placeholder {
            color: $black;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: $black;
          }

          &::-webkit-input-placeholder {
            color: $black;
          }

          &:disabled {
            cursor: default;
            opacity: .5;
          }
        }

        em {
          display: inline-block;
          font-style: normal;
          float: left;
          margin: .4em .7em 0 0;
          width: 1em;
        }
      }
    }

    //Сколько
    &.how-much {
      margin-bottom: 2em;

      .rating {
        @extend .clearfix;

        .star {
          float: left;
          margin: 0 3px 0 0;
          width: 15px;
          height: 15px;
          background-size: contain;
          background: image-url("svg-icons/star-black.svg") no-repeat;
        }
      }
    }
  }

  //Триггер фильтров
  .filters-trigger {
    margin: 2em 0 0 0;
    background: #FF8E7F;
    display: block;
    color: $white;
    text-align: center;
    padding: .8em 0 .8em 0;
    text-transform: uppercase;

    .text {
      display: inline-block;
      position: relative;

      .caret {
        @include caret($white, 7, down);
        position: absolute;
        right: -20px;
        top: .3em;
      }
    }
  }

  &.active {
    .filter-block {
      display: block;
    }

    .filters-trigger {
      .text {
        .caret {
          @include caret($white, 7, up);
          top: -.2em;
        }
      }
    }
  }

  //Чекбокс
  .filter-checkbox {
    cursor: pointer;
    display: block;
    @extend .clearfix;
    margin: 0 0 1em 0;

    input[type=checkbox] {
      display: none;
    }

    i {
      @include svg-image("checkbox", 25, 25);
      float: left;
      margin: 0 .6em 0 0;
    }

    input[type=checkbox]:checked + i {
      @include svg-image("checkbox-checked", 25, 25);
    }

    em {
      font-style: normal;
      float: left;
      margin: .2em 0 0 0;
      font-size: 1.1rem;
    }
  }

  //Левая колонка
  .leftcol {
    margin: 0 0 1em 0;
  }

  //Правая колонка
  .rightcol {
    transition: opacity .5s;

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }

    .meta {
      color: #535353;
      @extend .clearfix;
      margin: 0 0 1em 0;

      .amount {
        //Не выводится пока количество результатов
        display: none;
        font-size: .8em;
        float: left;
        text-transform: uppercase;

        .num {
          display: inline-block;
          margin: 0 0 0 .5em;
          font-size: 1.1em;
        }
      }

      .sort {
        //Пока спрячем сортировку. Она выставляется автоматически
        display: none;
        float: right;
        position: relative;

        .sort-help {
          display: none;
        }

        .sort-trigger {
          cursor: pointer;
          padding-right: 18px;
          position: relative;
          text-transform: uppercase;

          .caret {
            @include caret($black, 6, down);
            position: absolute;
            right: 0;
            top: .4em;
          }
        }

        .sort-dropdown {
          position: absolute;
          z-index: 2;
          right: 0;
          top: 100%;
          background: $white;
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .3);
          padding: .3em 0;
          display: none;

          .sort-dropdown-item {
            padding: .5em 1em;
            cursor: pointer;

            &:hover {
              background: #efefef;
            }
          }
        }

        &.active {
          .sort-trigger {
            .caret {
              @include caret($black, 6, up);
              top: 0;
            }
          }

          .sort-dropdown {
            display: block;
          }
        }
      }
    }

    //Результаты поиска
    .results {
      margin-left: -15px;
      margin-right: -15px;

      .result {
        cursor: pointer;
        @extend .clearfix;
        background: linear-gradient(135deg, #d5ffdd, #ffb7b7);
        margin: 0 0 10px 0;
        position: relative;

        &:nth-child(2n) {
          background: linear-gradient(135deg, #FFF4D5, #B7E9FF);
        }

        &:nth-child(3n) {
          background: linear-gradient(135deg, #D5DDFF, #B7E9FF);
        }

        &:nth-child(4n) {
          background: linear-gradient(135deg, #FFD8D5, #E8B7FF);
        }

        &:nth-child(5n) {
          background: linear-gradient(135deg, #C8ECF4, #B7FFCB);
        }

        .recommends {
          position: absolute;
          right: 0;
          top: 0;
        }

        .result-img {
          float: right;
          width: 100px;
          background-size: cover;
          background-position: center center;

          img {
            width: 100%;
          }
        }

        .result-content {
          margin: 15px 105px 0 15px;
          position: relative;
          @extend .clearfix;

          .for-children-icon {
            position: absolute;
            right: 0px;
            top: -5px;
            @include svg-image("for-children-grey", 11, 14);
          }

          .result-meta-top {
            color: #818181;
            font-size: .8em;
            margin-right: 33px;

            a {
              color: inherit;
            }

            .result-meta-item {
              float: left;
              margin: 0 1em 1em 0;
              min-height: 1.5em;

              .with-icon {
                .elem-icon {
                  float: left;
                  width: 15px;
                  height: 15px;
                  margin: 0 5px 0 0;
                  background-size: contain;
                  background-position: center center;
                  background-repeat: no-repeat;

                  &.e-p-types-component {
                    opacity: .7;
                  }

                  &.benefit-kupon {
                    background-image: image-url("svg-icons/benefit-kupon-grey.svg");
                  }

                  &.benefit-discount {
                    background-image: image-url("svg-icons/benefit-discount-grey.svg");
                  }

                  &.benefit-gift {
                    background-image: image-url("svg-icons/benefit-gift-grey.svg");
                  }
                }

                .elem-text {
                  float: left;
                  display: inline-block;
                }
              }
            }
          }

          .result-description {
            float: left;
            width: 100%;
            margin: 0 0 .5em 0;
            font-size: 1.2em;

            &.small {
              font-size: 1em;
            }

            a {
              color: $black;
              display: inline-block;
            }
          }

          .result-meta-bottom {
            .result-meta-item {
              float: left;
              margin: 0 1em 1em 0;
              color: #818181;
              font-size: .8em;

              a {
                color: inherit;
              }

              .result-location {
                .location-icon {
                  float: left;
                  @include svg-image("location-grey", 7, 10);
                  margin: 2px 3px 0 0;
                }

                .location-text {
                  float: left;
                }
              }

              .result-rating {
                float: left;
                margin: 0 .5em 0 0;

                .rating-star {
                  float: left;
                  margin: .5em 1px 0 0;
                  @include svg-image("star-grey", 7, 7);
                }
              }

              .result-views {
                float: left;
                margin: 0 .5em 0 0;

                .views-icon {
                  float: left;
                  margin: .5em .3em 0 0;
                  @include svg-image("views-grey", 10, 6);
                }

                .views-num {
                  float: left;
                  font-size: .9em;
                  margin: .1em 0 0 0;
                }
              }

              .result-likes {
                float: left;

                .result-icon {
                  float: left;
                  margin: .5em .3em 0 0;
                  @include svg-image("likes-grey", 6, 6);
                }

                .result-num {
                  float: left;
                  font-size: .9em;
                  margin: .1em 0 0 0;
                }
              }

              .result-tags {
                float: left;

                .result-tag {
                  display: inline-block;
                  margin: 0 .5em .5em 0;
                  float: left;

                  &::before {
                    content: "#";
                    display: inline-block;
                  }
                }
              }
            }
          }
        }

        //Прошедшее событие
        &.past {
          background: #efefef;

          .result-img {
            opacity: .3;
          }

          //Когда добавляем opacity к изображению выше, то оно начинает иметь z-index
          //и перекрывает .recommends
          .recommends {
            z-index: 2;
          }

          .result-content {
            .is-past {
              position: absolute;
              right: -5px;
              top: -15px;
              text-transform: uppercase;
              padding: .2em .4em;
              font-size: .75em;
              background: $white;
              width: 50px;
              box-sizing: border-box;
            }

            .is-past + .for-children-icon {
              right: 50px;
              top: -11px;
            }

            .result-meta-top {
              margin-right: 65px;
            }
          }
        }

        //Фотоальбом
        &.album {
          overflow: hidden;
          position: relative;

          .images {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            @extend .clearfix;
            opacity: .1;

            .images-item {
              float: left;
              width: 25%;
              background: center center no-repeat;
              background-size: cover;

              img {
                display: block;
                width: 100%;
              }
            }
          }

          .result-content {
            margin: 0;
            padding: 15px;
          }
        }

        //Видеоальбом
        &.video {
          position: relative;
          min-height: 70px;

          .play-button {
            position: absolute;
            right: 10px;
            top: 10px;
            @include svg-image("play-button", 50, 50);
          }

          .result-content {
            margin-right: 70px;
          }
        }

        //Суперсобытие
        &.super {
          background-size: cover;
          background-position: center center;

          .result-content {
            background: linear-gradient(135deg, rgba(#B21B1B, .85), rgba(#3743AD, .7));
            margin: 0;
            padding: 15px 60px 15px 15px;
            color: $white;
            position: relative;

            .recommends {
              position: absolute;
              right: 0;
              top: 0;
            }

            .for-children-icon {
              @include svg-image("for-children-white", 11, 14);
              position: absolute;
              right: 5px;
              top: 5px;
            }

            .recommends + .for-children-icon {
              right: 40px;
            }

            a {
              color: $white;
            }

            .result-meta-top {
              .result-meta-item {
                color: $white;

                small {
                  display: block;
                  color: $white;
                  font-size: .75em;
                  opacity: .8;
                }
              }
            }

            .result-meta-bottom {
              .result-meta-item {
                .result-location {
                  .location-icon {
                    opacity: .8;
                    @include svg-image("location-white", 7, 10);
                  }

                  a {
                    opacity: .8;
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }

    //Показать еще
    .show-more-results {
      display: none;
      color: $white;
      text-transform: uppercase;
      text-align: center;
      padding: .7em 1em;
      background: #6DCD4C;
      margin: .5em -15px 0 -15px;

      &.active {
        display: block;
      }
    }

    //Ничего не найдено
    .found-nothing {
      display: none;
      font-size: 1.1em;
      margin: 0 0 1em 0;
      position: relative;
      top: -.5em;
      color: #888888;

      &.visible {
        display: block;
      }
    }

    //Прошлые запросы
    .past-queries {
      display: none;
      .past-queries-title {
        color: #999;
        text-transform: uppercase;
        margin: 0 0 .7em 0;
      }

      .past-queries-elem {
        font-size: 1.2em;
        margin: 0 0 .8em 0;

        a {
          color: $black;
        }
      }
    }
  }

  @media #{$large-up} {
    //Вернуться к поиску
    .back-to-search {
      position: fixed;
      bottom: 30px;
      left: 20px;
      z-index: 2;
      display: inline-block;
      color: #FF4D4D;
      text-align: center;
      padding: .8em 2em;
      //width: 10em;
      text-transform: uppercase;
      border: 1px solid #FF4D4D;
      font-size: 1.1em;
      background: $white;
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s;

      &.visible {
        opacity: 1;
        visibility: visible;
      }
    }

    //Форма поиска
    .search-form {
      margin-bottom: 1.2em;

      .input-container {
        padding-right: 160px;

        .query-container {
          padding: 0 0 0 45px;
          background-image: image-url("icons/search-grey.png");
          background-position: 10px 12px;
          background-repeat: no-repeat;
          background-size: 22px 22px;

          @media #{$retina} {
            background-image: image-url("icons-retina/search-grey.png");
          }

          .query {
            height: 48px;
            font-size: 1.5rem;
            padding-top: 0;

            &::-moz-placeholder {
              font-size: 1.5rem;
            }
            &:-ms-input-placeholder {
              font-size: 1.5rem;
            }
            &::-webkit-input-placeholder {
              font-size: 1.5rem;
            }
          }

          .selected {
            font-size: .9em;
            margin-top: .8em;
            margin-bottom: 0;

            .remove {
              width: 9px;
              height: 9px;
            }
          }
        }

        .autocomplete {
          margin-top: 3px;

          .elem {
            font-size: 1.4em;
          }
        }
      }

      .buttons-container {
        .submit, .reset {
          height: 50px;
        }

        .submit {
          background-image: none;
          width: 90px;
          text-align: center;
          font-size: 1.2em;

          &::before {
            content: "Найти";
            color: $white;
            text-transform: uppercase;
          }
        }

        .reset {
          width: 60px;
          background-size: 27px 27px;
        }
      }
    }

    //Популярные запросы
    .popular-queries {
      .title {
        font-size: .9em;
        height: 30px;
        margin-bottom: 0;

        .time-machine {
          &.active {
            width: 60px;
            height: 40px;
          }
        }
      }

      .queries {
        position: relative;
        white-space: normal;
        overflow-x: visible;
        margin: -30px 65px 1em 160px;

        a {
          margin: 0 .3em .7em 0;
          font-size: .85em;
        }
      }
    }

    //Фильтры
    .filter-block {
      display: block !important;

      .filter-heading {
        font-size: 1.4rem;

        .caret {
          @include caret($black, 8, down);
        }
      }

      &.tags {
        border-top: 1px solid #d6d6d6;
        margin-bottom: 2em;

        .filter-heading {
          display: none;
        }

        .filter-content {
          display: block !important;
        }

        .tags-items {
          .tags-item {
            font-size: 1.1rem;
          }

          .tags-more {
            font-size: 1.1em;
          }
        }
      }
    }

    //Триггер фильтров
    .filters-trigger {
      display: none;
    }

    //Левая колонка
    .leftcol {
      float: left;
      width: 25%;
    }

    //Правая колонка
    .rightcol {
      float: right;
      width: 72%;

      .meta {
        .amount {
          font-size: .9em;

          .num {
            font-size: 1.2em;
          }
        }

        .sort {
          font-size: 1.1em;

          .sort-help {
            display: block;
            position: absolute;
            left: -10.5em;
            top: .25em;
            text-transform: uppercase;
            font-size: .7em;
          }
        }
      }

      //Результаты
      .results {
        margin-left: 0;
        margin-right: 0;

        .result {
          font-size: 1.5em;

          .result-img {
            width: 150px;
          }

          .result-content {
            margin-top: 25px;
            margin-left: 25px;
            margin-right: 160px;

            .for-children-icon {
              @include svg-image("for-children-grey", 14, 17);
            }

            .result-meta-top {
              margin-right: 50px;

              .result-meta-item {
                .with-icon {
                  .elem-icon {
                    margin-top: 3px;
                  }
                }
              }
            }

            .result-meta-bottom {
              .result-meta-item {
                .result-location {
                  .location-icon {
                    @include svg-image("location-grey", 11, 17);
                    margin-right: 5px;
                  }
                }

                .result-rating {
                  .rating-star {
                    margin-top: .3em;
                    @include svg-image("star-grey", 15, 15);
                  }
                }

                .result-views {
                  .views-icon {
                    margin-top: .3em;
                    @include svg-image("views-grey", 19, 12);
                  }
                }

                .result-likes {
                  .result-icon {
                    margin-top: .3em;
                    @include svg-image("likes-grey", 13, 13);
                  }
                }
              }
            }
          }

          &.past {
            .result-content {
              .is-past {
                right: -10px;
                top: -25px;
                font-size: .7em;
                width: 75px;
              }

              .is-past + .for-children-icon {
                right: 70px;
                top: -20px;
              }

              .result-meta-top {
                margin-right: 90px;
              }
            }
          }

          &.super {
            .result-content {
              padding: 25px 70px 25px 25px;

              .for-children-icon {
                top: 10px;
                right: 10px;
                @include svg-image("for-children-white", 14, 17);
              }

              .recommends + .for-children-icon {
                top: 7px;
                right: 55px;
              }

              .result-description {
                margin-bottom: 1.2em;
              }

              .result-meta-bottom {
                .result-meta-item {
                  .result-location {
                    .location-icon {
                      @include svg-image("location-white", 11, 17);
                    }
                  }
                }
              }
            }
          }

          &.album {
            .result-content {
              padding-left: 25px;
              padding-top: 25px;
              padding-right: 25px;
            }
          }

          &.video {
            min-height: 90px;

            .play-button {
              top: 20px;
            }
          }
        }
      }

      .show-more-results {
        font-size: 1.2em;
        margin-left: 0;
        margin-right: 0;
      }

      //Прошлые запросы
      .past-queries {
        .past-queries-title {
          margin-bottom: 1.2em;
        }

        .past-queries-elem {
          font-size: 1.7em;
          margin-bottom: 1em;
        }
      }
    }
  }

  @media #{$xlarge-up} {
    .rightcol {
      width: 70%;

      .results {
        .result {
          .result-img {
            width: 200px;
          }

          .result-content {
            margin-right: 210px;
          }
        }
      }
    }
  }
}