section.main.articles {
  //Новости
  .positions {
    margin: 0 10px 0 10px;

    //Новосеть
    article {
      margin: 0 0 1.5em 0;
      width: 270px;
      @extend .clearfix;

      //Левая часть
      a.image {
        display: inline-block;
        position: relative;
        width: 100%;

        img {
          width: 100%;
        }

        .icons {
          display: inline-block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          box-sizing: border-box;
          padding: 15px 10px 10px 10px;
          @extend .clearfix;
          background: linear-gradient(to top, rgba($black, .6), rgba($black, 0));

          * {
            float: left;
            display: inline-block;
            margin: 0 12px 0 0;

            &.video {
              @include svg-image("news-video", 18, 18);
            }

            &.user {
              @include svg-image("news-user", 19, 19);
            }

            &.comment {
              margin-top: 3px;
              @include svg-image("news-comment", 19, 15);
            }

            &.photo {
              @include svg-image("news-photo", 19, 19);
            }
          }
        }
      }

      //Правая часть
      .text {
        padding: .5em 0 .5em 0;
        box-sizing: border-box;

        a {
          color: inherit;

          &:hover {
            text-decoration: none;
          }
        }

        a.title {
          color: $black;
          font-size: 1.1em;
          display: inline-block;
          margin: 0 0 .7em 0;
        }

        .date {
          color: #4A4A4A;
          text-transform: uppercase;
          font-size: .8em;
        }
      }

      //Специальная новость - на темном фоне
      &.special {
        background: #4F4F4F;
        color: $white;

        .text {
          padding-left: .5em ;
          padding-right: .5em;

          .meta-top {
            text-transform: uppercase;
            font-size: .8em;
            margin: 0 0 .8em 0;

            .meta-top-last {
              background: $white;
              color: #4f4f4f;
              padding: .3em .5em;
              display: inline-block;
              margin-right: 1em;
            }

            .meta-top-date {
              opacity: .8;
            }
          }

          a.title {
            color: $white;
          }

          .meta-bottom {
            @extend .clearfix;

            .location {
              margin: 0 0 .5em 0;
            }

            .rating {
              float: left;
              margin-right: 15px;
              margin-top: 2px;
            }

            .views {
              float: left;
            }
          }
        }
      }
    }
  }

  @media #{$medium-up} {
    .positions {
      margin-left: 0;
      margin-right: 0;

      article {
        width: auto;

        a.image {
          float: left;
          width: 50%;
          max-width: 270px;
        }

        .text {
          float: left;
          width: 50%;
          padding-left: 1em;
        }

        &.special {
          .text {
            padding-left: 1em;
          }
        }
      }
    }
  }

  @media #{$large-up} {
    .positions {
      article {
        a.image {
          width: 30%;
        }

        .text {
          width: 70%;
          font-size: 1.4em;

          a.title {
            font-size: 1.4em;
          }

          .date {
            font-size: .7em;
          }
        }

        &.special {
          .text {
            .meta-top {
              font-size: .6em;
            }

            .title {
              margin-bottom: .8em;
            }

            .meta-bottom {
              > * {
                float: left;
                min-height: 2rem !important;
                margin-top: 0 !important;
                margin-bottom: .5rem !important;
                margin-right: 1.4rem !important;
                box-sizing: border-box !important;
              }
            }
          }
        }
      }
    }
  }
}