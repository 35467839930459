//Плитка новостей
.news-tile-component {
  .ct-positions {
    margin: 0 10px 0 10px;

    //Новосеть
    article {
      margin: 0 0 1.5em 0;
      width: 270px;
      @extend .clearfix;

      //Левая часть
      .ct-image {
        display: inline-block;
        position: relative;
        width: 100%;
        background-position: center center;
        background-size: cover;

        img {
          width: 100%;
        }

      }
      .ct-icons {
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 15px 10px 10px 10px;
        @extend .clearfix;
        background: linear-gradient(to top, rgba($black, .6), rgba($black, 0));

        * {
          float: left;
          display: inline-block;
          margin: 0 12px 0 0;

          &.ct-video {
            @include svg-image("news-video", 18, 18);
          }

          &.ct-user {
            @include svg-image("news-user", 19, 19);
          }

          &.ct-comment {
            margin-top: 3px;
            @include svg-image("news-comment", 19, 15);
          }

          &.ct-photo {
            @include svg-image("news-photo", 19, 19);
          }
        }
      }

      //Правая часть
      .ct-text {
        padding: .5em 1em .5em 0;
        box-sizing: border-box;

        a {
          color: inherit;

          &:hover {
            text-decoration: none;
          }
        }

        a.ct-title {
          color: $black;
          font-size: 1.1em;
          display: inline-block;
          margin: 0 0 .7em 0;
        }

        .ct-date {
          color: #4A4A4A;
          text-transform: uppercase;
          font-size: .8em;
        }
      }
    }
  }

  @media #{$medium-up} {
    .ct-positions {
      margin-left: 0;
      margin-right: 0;

      article {
        float: left;
        width: 50%;
        box-sizing: border-box;
        padding-right: 1px;
      }
    }
  }

  @media #{$large-up} {
    margin-bottom: 5em;

    .ct-positions {
      article {
        width: 25%;

        .ct-text {
          font-size: 1.3em;
        }
      }
    }
  }
}
