//Телефон
.phone-component {
  @include components-mixin;
  position: relative;
  padding-left: 14px;

  .ct-icon {
    position: absolute;
    left: 0;
    top: .3em;
    @include sprite-image("phone-component-icon-white-small", 10, 11);
  }

  .ct-text {
    color: $white;
    font-size: .85em;

    a {
      color: inherit;
    }
  }

  &.__transparent {
    .ct-icon, .ct-text {
      opacity: .8;
    }
  }

  @media #{$large} {
    padding-left: 22px;

    .ct-icon {
      top: .2em;
      @include sprite-image("phone-component-icon-white-big", 17, 18);
    }

    .ct-text {
      font-size: 1.3em;
    }
  }
}