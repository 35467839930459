.search {
  $active-color: #6DCD4C;
  $search-form-height: 40px;
  position: relative;
  margin-left: auto;

  %button {
    display: inline-block;
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline-width: 0;
    outline: none;
    text-align: center;
    > svg {
      fill: currentColor;
    }

    &:hover {
      color: $active-color;
    }
  }

  &-toggler {
    @extend %button;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    color: $white;
    transition: border-radius .15s ease;
    border-radius: 0;

    &:hover {
      border-radius: 50%;
    }

    .search.__active &,
    .search.__active &:hover  {
      border-radius: 0;
      box-shadow: 0 0 0 10px black;
      background: black;
    }
    > svg {
      width: inherit;
      height: inherit;
    }
  }
  &-container {
    display: none;
    position: absolute;
    top: 35px;
    right: 0;
    width: 314px;
    height: $search-form-height;
    background-color: black;
    box-shadow: 0 0 0 10px black;
  }
  &.__active .search-container {
    display: flex;
  }

  &-form {
    position: relative;
    width: 281px;
  }
  &-field {
    padding: 10px 50px 10px 19px;
    width: 100%;
    height: $search-form-height;
    border: none;
    outline-width: 0;
    outline: none;
    font-size: 15px;
    color: $black;
    letter-spacing: 0.54px;

    &:focus {
      box-shadow: 0 0 0 1px $active-color;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.35);
      letter-spacing: 0.54px;
    }
  }
  &-control {
    @extend %button;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 5px 0 0;
    width: 54px;
    height: $search-form-height;
    color: rgba(0, 0, 0, 0.5);
    > svg {
      width: 18px;
      height: 18px;
    }

    &:focus {
      color: $active-color;
    }
  }
  &-close {
    @extend %button;
    position: absolute;
    right: 0;
    margin: 0 -14px 0 0;
    padding: 5px 0 0;
    width: 48px;
    height: $search-form-height;
    text-align: center;
    color: $white;
    > svg {
      width: 20px;
      height: 20px;
    }
  }
}
