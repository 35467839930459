section.main.articles.index {
  margin: 2em 0 0 0;

  .pagination-component {
    &.top {
      margin: 0 0 2em 0;
    }

    &.bottom {
      margin: 2em 0 0 0;
    }
  }

  @media #{$large-up} {
    .page-title {
      float: left;
      text-transform: uppercase;
      font-size: 2.3em;
    }
    .pagination-component {
      &.top {
        float: right;
      }
    }
  }
}