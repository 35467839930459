//Переменные
$_font-color-transparent: rgba(255,255,255, .6);
$_font-color-transparent-black: rgba(0,0,0, .5);
$_container-padding: 15px;


//Шрифт
@font-face {
  font-family: SF;
  src: url("/fonts/website/SF.woff");
  font-weight: normal;
}

@font-face {
  font-family: SF;
  src: url("/fonts/website/SF-Medium.woff");
  font-weight: bold;
}

//Настройки
@import "extras/url-helper";
@import "extras/sprite-setup";
@import "extras/svg-image";
@import "plugins/foundation/_foundation_settings";

//Плагины
@import "plugins/normalize",
"plugins/jquery-ui",
"plugins/jquery.smartbanner",
"plugins/animate",
"plugins/slick",
"plugins/slick-theme",
"plugins/swipebox";

@import
"plugins/foundation/foundation/components/alert-boxes",
"plugins/foundation/foundation/components/flex-video",
"plugins/foundation/foundation/components/grid",
"plugins/foundation/foundation/components/reveal",
"plugins/foundation/foundation/components/type",
"plugins/foundation/foundation/components/visibility";

//Спрайт
@import "generated/sprite";

//Наши файлы
@import "partials/index";
@import "partials/components/index";
@import "partials/main_parts/index";
@import "partials/pages/index";


//TODO оптимизация
//foundation ~ 60kb
//sprite_setup + svg_image ~ 60kb - перевести все на svg-спрайт
//components ~105kb
//pages ~250kb - сделать сборку для каждой страницы по-отдельности и подлючать
//необходимый файл только на нужной странице
