//Галерея. Бывает вариант, когда мы разметку галереи используем, но
//не стилизуем ее. Тогда нужно блоку поставить класс __unstyled
.gallery-component:not(.__unstyled) {
  @include components-mixin;
  position: relative;

  //Обертка для галереи, контролов и информации
  .ct-wrap {
    position: relative;

    //Сама галерея
    .ct-root {
      overflow: hidden;
      visibility: hidden;
      position: relative;
      margin: 0 0 .5em 0;

      .ct-list {
        overflow: hidden;
        position: relative;
      }
    }
  }

  //У инициализованной галерери все изображения видны
  &.__initialized {
    .ct-gallery-item {
      display: block;
    }
  }

  //Элемент галереи
  .ct-gallery-item {
    float: left;
    width: 100%;
    position: relative;
    display: none;

    //У не инициализованной галереи видно только первое изображение
    //иначе она растянется вертикально
    &:first-of-type {
      display: block;
    }

    .ct-main-img {
      background: $black no-repeat center center;
      background-size: 30px 30px;
      background-image: image-url("main/preloader.gif");
      cursor: pointer;

      &[data-interchange] {
        background-size: contain;
        background-image: none;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  //Информация
  .ct-info {
    margin: 0 0 1.5em 0;

    .ct-text {
      margin: 0 0 .9em 0;
      color: $_font-color-transparent;

      .ct-name {
        text-transform: uppercase;
        display: inline-block;
        margin-right: 1em;
      }
    }
  }

  &.__text-above {
    .ct-root {
      margin-bottom: 0;
    }

    .ct-info {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      padding: .8em 15px .8em 15px;
      background: rgba($black, .7);
      margin: 0;

      .ct-text {
        margin: 0;
      }
    }
  }

  //Миниатюры
  .ct-minis {
    position: relative;
    overflow: hidden;

    .ct-list {
      width: 20000em;
      position: relative;
    }

    a {
      float: left;
      width: 100px;
      background-color: $black;

      .ct-mini-bg {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-image: image-url("main/preloader.gif");
        opacity: .5;

        &[data-interchange] {
          background-size: cover;
          background-image: none;
        }

        img {
          width: 100%;
        }
      }

      &.__active {
        .ct-mini-bg {
          opacity: 1;
        }
      }
    }
  }

  @media #{$medium-up} {
    &.__text-above {
      .ct-info {
        padding: 1.5em 15px 1.5em 15px;

        .ct-text {
          padding-bottom: .5em;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }

  @media #{$large-up} {
    .ct-info {
      .ct-text {
        font-size: 1.2em;
      }
    }
  }

  @media screen and (min-width: 940px) {
    .ct-control {
      position: absolute;
      top: 50%;
      //Контролы хорошо бы отцентрировать по вертикали по центру, но это
      //нельзя сделать совсем точно, т.к. они располагаются в одном контейнере
      //и с фотками и с текстом. Поэтому примерно по центру. 23 - половина контрола
      //30 - примерно половина текста, итого примерно 55
      margin-top: -23px;
      cursor: pointer;

      &.__left {
        @include sprite-image("gallery-component-arrow-left-corner", 27, 46);
        left: -35px;
      }

      &.__right {
        @include sprite-image("gallery-component-arrow-right-corner", 27, 46);
        right: -35px;
      }
    }
  }

  @media #{$xlarge-up} {
    .ct-control {
      display: none !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .ct-control {
      display: block !important;
    }
  }
}