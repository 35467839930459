.rating2-component {
  $bg-yellow: #F0DA85;
  $bg-red: #d7887d;
  $color-green: #85F093;
  $color-yellow: #FFE25F;
  width: 300px;
  @extend .clearfix;
  @include components-mixin;
  margin-bottom: 1em;
  height: 3.5em;
  position: relative;
  .ct-rc-tooltip {
    display: none;
    color: black;
    position: absolute;
    box-shadow: 0 5px 5px rgba(black, .2);
    left: 20%;
    width: 80%;
    box-sizing: border-box;
    top: 100%;
    margin-top: 1px;
    background: white;
    max-width: 100%;
    font-size: .86rem;
    z-index: 5;
    padding: .8em 0 .8em .8em;
    .ct-rc-tooltip-close {
      position: absolute;
      right: .5em;
      top: .5em;
      width: 1em;
      height: 1em;
      padding: .6em;
      cursor: pointer;
      background: image-url('svg-icons/cross-black-thick.svg') center center / 80% 80% no-repeat; }
    .ct-rc-tooltip-title {
      text-transform: uppercase;
      margin-bottom: 1.5em;
      margin-right: 30px; }
    table {
      width: 100%;
      tr {
        cursor: pointer;
        &.__green {
          background: linear-gradient(to bottom, $color-green 0%, $color-green 87%, white 87%, white 100%); }
        &.__yellow {
          background: linear-gradient(to bottom, $bg-yellow 0%, $bg-yellow 87%, white 87%, white 100%); }
        &.__red {
          background: linear-gradient(to bottom, $bg-red 0%, $bg-red 87%, white 87%, white 100%); } }
      td {
        font-weight: bold;
        padding: .5em .5em .7em .5em;
        text-transform: uppercase;
        &.__date {
          padding-left: 1em; }
        &.__rating-delta {
          font-weight: normal; }
        &.__arrow {
          background: white;
          text-align: center;
          img {
            width: 15px;
            height: 15px; } } } } }
  .ct-rc-number {
    cursor: pointer;
    float: left;
    height: 100%;
    background: $color-green;
    box-sizing: border-box;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 1.85em;
    padding-top: .3em;
    width: 20%;
    &.__green {
      background: $color-green; }
    &.__yellow {
      background: $bg-yellow; }
    &.__red {
      background: $bg-red; } }
  .ct-rc-chart {
    cursor: pointer;
    float: left;
    height: 100%;
    width: 30%;
    box-sizing: border-box;
    background: white;
    color: #C5C5C5;
    padding: .3em 0 0 .3em;
    border-right: 1px solid #EEEEEE;
    .ct-rc-chart-container {
      @extend .clearfix;
      max-width: 80px;
      margin: 0 auto; }
    .ct-rc-chart-icon {
      float: left;
      display: inline-block;
      width: 20px;
      height: 16px;
      margin-right: .8em;
      margin-left: .2em; }
    .ct-rc-chart-number {
      float: left;
      font-size: 1.23em; }
    .ct-rc-chart-text {
      padding-top: .4em;
      text-transform: uppercase;
      font-size: .55em;
      clear: both; } }
  .ct-rc-rating {
    float: left;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    background: white;
    .ct-rc-rating-star {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin: .8em .3em 0 .3em; }
    &.__1, &.__2, &.__3, &.__4, &.__5 {
      .ct-rc-rating-star {
        background: image-url('svg-icons/star-yellow.svg') center center / cover; } }
    &.__1 {
      .ct-rc-rating-star:nth-of-type(n + 2) {
        background-image: image-url('svg-icons/star-grey.svg'); } }
    &.__2 {
      .ct-rc-rating-star:nth-of-type(n + 3) {
        background-image: image-url('svg-icons/star-grey.svg'); } }
    &.__3 {
      .ct-rc-rating-star:nth-of-type(n + 4) {
        background-image: image-url('svg-icons/star-grey.svg'); } }
    &.__4 {
      .ct-rc-rating-star:nth-of-type(n + 5) {
        background-image: image-url('svg-icons/star-grey.svg'); } }
    &.__5 {
      .ct-rc-rating-star:nth-of-type(n + 6) {
        background-image: image-url('svg-icons/star-grey.svg'); } } }
  //Подсказка сверху
  &.__tooltip-top {
    .ct-rc-tooltip {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 2px;
      box-shadow: 0 -2px 7px rgba(black, .1); } }
  //Специальный вариант для страницы заведения
  &.__for-place-page {
    width: 210px;
    .ct-rc-number {
      width: 50px; }
    .ct-rc-chart {
      width: 80px;
      .ct-rc-chart-text {
        font-size: .5em; } }
    .ct-rc-rating {
      width: 80px;
      .ct-rc-rating-star {
        width: 13px;
        height: 13px;
        margin: 1.2em 0 0 0; } } }
  @media #{$xlarge-up} {
    &.__for-place-page {
      width: 300px;
      .ct-rc-number {
        width: 20%; }
      .ct-rc-chart {
        width: 30%;
        .ct-rc-chart-text {
          font-size: .55em; } }
      .ct-rc-rating {
        width: 50%;
        .ct-rc-rating-star {
          width: 18px;
          height: 18px;
          margin: .8em .3em 0 .3em; } } } } }
