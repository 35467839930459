section.places.category2 {
  //Заголовок страницы
  .page-title {
    text-transform: uppercase;
    @extend .clearfix;
    margin: 0 0 1.5em 0;
    background: #F4F4F4;
    padding: .6em 0 .8em 0;

    .icon {
      float: left;
      margin: 0 .8em 0 0;
    }

    .text {
      float: left;
      font-size: 1.1em;
      margin: .3em 0 0 0;
    }

    @media #{$large-up} {
      background: none;
      margin-bottom: 2.5em;

      .icon {
        width: 30px;
        height: 30px;
        margin-right: 1em;
      }

      .text {
        margin-top: 0;
        font-size: 2em;
      }
    }

    @media #{$xlarge-up} {
      .icon {
        margin-top: .4em;
      }

      .text {
        font-size: 2.6em;
      }
    }
  }

  //Фильтр
  .filter {
    background: #f4f4f4;

    .custom-select {
      position: relative;
      float: left;
      max-width: 45%;
      margin: .5em 0 .5em 0;


      &.price {
        float: right;
      }

      .trigger {
        padding: 5px 8px;
        cursor: pointer;
        @extend .clearfix;

        .text {
          line-height: 1.2;
          float: left;

          &.upcase {
            text-transform: uppercase;
          }
        }

        .caret {
          float: left;
          @include caret($black, 6, down);
          margin: .3em 0 0 .4em;
        }
      }

      .dropdown {
        display: none;
        position: absolute;
        z-index: 2;
        top: 100%;
        padding: 7px 0;
        min-width: 100%;
        box-sizing: border-box;
        background: darken(#f4f4f4, 5%);
        max-height: 200px;
        overflow-y: auto;

        .dropdown-elem {
          cursor: pointer;
          padding: 5px 8px;

          .text {
            white-space: nowrap;
            text-transform: uppercase;
            line-height: 1.2;
          }
        }
      }

      &.active {
        .trigger {
          background: #D8D8D8;

          .caret {
            @include caret($black, 6, up);
            margin-top: 0;
          }
        }

        .dropdown {
          display: block;
        }
      }
    }

    .rating {
      clear: both;
      text-align: center;
      border-top: 1px solid #e2e2e2;
      margin: 0 -15px 1em -15px;
      padding: 1em 0 0 0;

      .star {
        @include svg-image("star-black", 20, 20);
        margin: 0 4px 0 0;
        cursor: pointer;
      }

      &.__1 {
        .star:nth-child(n + 2) {
          @include svg-image("star-black-hollow", 20, 20);
        }
      }

      &.__2 {
        .star:nth-child(n + 3) {
          @include svg-image("star-black-hollow", 20, 20);
        }
      }

      &.__3 {
        .star:nth-child(n + 4) {
          @include svg-image("star-black-hollow", 20, 20);
        }
      }

      &.__4 {
        .star:nth-child(n + 5) {
          @include svg-image("star-black-hollow", 20, 20);
        }
      }
    }

    .button-container {
      margin: 0 -15px 0 -15px;

      button[type=submit] {
        display: block;
        width: 100%;
        border: none;
        box-sizing: border-box;
        background: #6DCD4C;
        color: $white;
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        font-size: 1.2em;
        text-align: center;
        padding: .5em 1em .5em 1em;
      }
    }

    @media #{$large-up} {
      padding-top: 1em;
      padding-bottom: 1em;
      margin-bottom: 3em;

      .custom-select {
        font-size: 1.3em;
        margin-right: 2em;

        &.price {
          float: left;
        }
      }

      .rating {
        border-top: none;
        margin: 0 3.5em 0 0;
        clear: none;
        float: left;
      }

      .button-container {
        margin: .5em 0 0 0;
        float: right;

        button {
          width: auto;
        }
      }
    }
  }
}