//Заголовок с иконой
.title-with-icon-component {
  @include components-mixin;
  margin: 0 0 1em 0;
  position: relative;
  padding: 0 0 0 35px;

  .ct-title-icon {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;

    &.__location {
      @include sprite-image("title-with-icon-component-location", 17, 27);
    }

    &.__calendar {
      @include sprite-image("title-with-icon-component-calendar-big-black", 30, 30);
    }
  }

  .ct-title-text {
    text-transform: uppercase;
    font-size: 1.5rem;

    a {
      color: inherit;
    }
  }

  //C отступом слева
  &.__left-pad {
    margin-left: 15px;
  }
}