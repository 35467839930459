//Прилипающий баннер при скроллинге
.sticky-banner-component {
  position: relative;
  display: none;

  .ct-banner {
    margin: 0 0 2em 0;
  }

  @media #{$large-up} {
    display: block;
  }
}