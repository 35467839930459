.top-events-component {
  display: flex;
  .ct-te-item {
    position: relative;
    flex: 1;
    color: #fff;
    text-decoration: none;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      background-size: 100%;
      transition: background-size .2s ease-in-out;
    }
    &:nth-child(1n):before {
      background-image: linear-gradient(44deg, rgba(92,160,52,0.95) 54%, rgba(200,204,0,0.35) 100%);
    }
    &:nth-child(2n):before {
      background-image: linear-gradient(270deg, rgba(60,59,158,0.03) 0%, rgba(106,40,143,0.95) 97%);
    }
    &:nth-child(3n):before {
      background-image: linear-gradient(270deg, rgba(142,99,33,0.35) 0%, rgba(156,52,112,0.90) 82%);
    }
    &:hover {
      color: #fff;
      text-decoration: none;
      &:before {
        background-size: 400%;
      }
    }
    &-inner {
      @extend .container;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 23px 25px;
      height: 160px;
      overflow: hidden;
    }
    &-type {
      display: inline-block;
      margin-bottom: 12px;
      padding: 6px 14px 3px;
      color: #000;
      background-color: #fff;
      font-size: 12px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      box-shadow: 0 3px 0 0 #D6D6D6;
      text-decoration: none;
      position: relative;
      z-index: 3;

      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
    &-date {
      font-size: 12px;
      text-transform: uppercase;
      opacity: 0.5;
    }
    &-title {
      display: flex;
      flex: 1;
      align-items: flex-end;
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.9px;
      line-height: 26px;
      color: inherit;
      text-decoration: none;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: -12px;
        bottom: -20px;
        left: -12px;

        // @media #{$xxlarge-up} {
        //   right: -18px;
        //   bottom: -30px;
        //   left: -24px;
        // }
      }
      &:hover {
        color: inherit;
        text-decoration: none;
      }

      // @media #{$xxlarge-up} {
      //   font-size: 21px;
      // }
    }
    &-location {
      display: block;
      padding-left: 16px;
      color: inherit;
      text-decoration: none;
      font-size: 12px;
      letter-spacing: 0.64px;
      position: relative;
      z-index: 2;

      &:hover {
        color: inherit;
        text-decoration: none;
      }

      // @media #{$xxlarge-up} {
      //   font-size: 15px;
      // }
      > svg {
        position: absolute;
        top: -1px;
        left: 0;
        width: 10px;
        height: 16px;
        fill: currentColor;
        opacity: 0.85;
      }
    }
  }
}
