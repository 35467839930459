//Суперсобытия
section.super-events {
  position: relative;
  z-index: 200;

  article {
    cursor: pointer;
    font-size: 1rem;
    background-size: cover;

    .bg-gradient {
      padding: 10px 0 10px 0;
      color: $white;
      height: 100%;
      box-sizing: border-box;
    }

    a {
      color: $white;
    }

    .date-and-place {
      .date {
        float: left;
        margin: .3em 2em 0 0;
        color: $_font-color-transparent;
        font-size: .8em;

        a {
          color: $_font-color-transparent;
        }
      }

      .place {
        float: left;

        .place-subname {
          color: $_font-color-transparent;
          font-size: .8em;

          a {
            color: $_font-color-transparent;
          }
        }
      }
    }

    .name {
      padding: .5em 0 0 0;
      margin: 0 0 .5em 0;
      min-height: 2em;
      font-size: 1.2em;

      a {
        display: inline-block;
      }
    }

    .location {
      float: left;
      margin-top: 1.5em;
    }
  }

  //Узкий вариант
  &._narrow {
    article {
      .bg-gradient {
        padding-top: 3px;
        padding-bottom: 5px;
      }

      .name {
        min-height: 0;
        padding-top: .2em;
        margin-bottom: .2em;
        font-size: 1em;
      }

      .location {
        margin-top: .2em;
      }
    }
  }

  @media #{$large-up} {
    article {
      font-size: 1.5rem;

      .bg-gradient {
        padding: 2em 0 2em 0;
      }

      .date-and-place {
        .date {
          margin-right: 3.5em;
        }
      }

      .name {
        font-size: 1.6em;
        padding-top: 2em;
      }
    }

    &._2 {
      article {
        float: left;
        width: 50%;
        font-size: 1.2rem;

        .bg-gradient {
          padding: 1em .5em 1em .5em;
        }
      }
    }

    &._3 {
      article {
        float: left;
        width: 33.333333333%;
        font-size: 1.1rem;

        .bg-gradient {
          padding: 1em .5em 1em .5em;
        }
      }
    }

    //Узкий вариант
    &._narrow {
      article {
        font-size: 1rem;

        .bg-gradient {
          padding-top: .5em;
          padding-bottom: .5em;
        }

        .name {
          font-size: 1.3em;
          padding-top: .2em;
          margin-bottom: .3em;
        }

        .location {
          font-size: .8em !important;
        }
      }

      &._2, &._3 {
        font-size: 1rem;

        .bg-gradient {
          padding-top: .5em;
          padding-bottom: .5em;
        }
      }
    }
  }
}