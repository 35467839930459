.universal-gallery-component {
  .ct-ug-root {
    overflow: hidden;
    visibility: hidden;
    position: relative;

    > * {
      overflow: hidden;
      position: relative;

      > * {
        float:left;
        width:100%;
        position: relative;
        display: none;
      }
    }
  }

  &.universal-gallery-component-initialized {
    .ct-ug-root {
      > * {
        > * {
          display: block;
        }
      }
    }
  }
}