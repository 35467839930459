.modal-component {
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 1000;
  color: white;
  box-sizing: border-box;
  display: none;
  &.__visible {
    display: block;
    border: 2px solid red; }
  &.__fixed {
    top: 0 !important;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    padding: 1.5em 0;
    .ct-mc-close {
      top: 0; } }
  .ct-mc-bg {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    background: rgba(black, .7); }
  .ct-mc-container {
    position: relative;
    z-index: 2;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  .ct-mc-close {
    position: absolute;
    right: 15px;
    top: -35px;
    width: 30px;
    height: 30px;
    z-index: 3; }
  //Видео в iframe (youtube, vimeo)
  .ct-vp-video {
    position: relative;
    height: 0;
    //16:9
    padding-bottom: 56.25%;
    overflow: hidden;
    display: none;
    iframe,
    object,
    embed,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }
  //Нативное видео
  .ct-mc-video-container {
    margin-bottom: 1.5em;
    video {
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 400px; } }
  .ct-mc-video-gallery-controls {
    @extend .clearfix;
    img {
      float: left;
      display: inline-block;
      width: 2em;
      height: 2em;
      &.next {
        float: right; }
      &.__disabled {
        opacity: .7; } } } }
