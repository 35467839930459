//Немного дополнительно стилизуем алерты foundation
.alert-box {
  background: #e2e2e2;

  &::before {
    content: "Оповещение";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    text-transform: uppercase;
    padding: .4em 12px .4em 12px;
    font-size: .8em;
    background: #cfcfcf;
  }

  a:hover {
    text-decoration: none;
  }

  &.secondary {
    background: #e2e2e2;

    &::before {
      background: #cfcfcf;
      content: "Оповещение";
    }
  }

  &.success {
    background: #e2ffd5;

    &::before {
      background: #a3ff78;
      content: "Успешно";
    }
  }

  &.warning {
    background: #ffefd4;

    &::before {
      background: #ffd778;
      content: "Предупреждение";
    }
  }

  &.alert {
    background: #ffd4d4;

    &::before {
      background: #ff7878;
      content: "Ошибка";
    }
  }
}