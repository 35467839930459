$xlarge-up: 'min-width: 1024px';

$base-font-size: 13px;
$rem-base: $base-font-size;

// Allows the use of rem-calc() or lower-bound() in your settings
@import 'foundation/functions';

$base-line-height: 1.4;

$include-html-classes: true;
$include-html-global-classes: $include-html-classes;

$row-width: rem-calc(1350);
$font-family-sans-serif: SF, Arial, sans-serif;

$font-weight-light: lighter;
$font-weight-normal: normal;
$font-weight-bold: bold;

$white: #FFFFFF;
$base: #A3A3A3;
$aluminum: #999999;
$black: #000000;
$body-bg: #F1F1F1;
$body-font-color: $black;

//Мы изменили $base-font-size с 16 на 13. Вышеуказанные рекомендации не помогают правильно
//рассчитывать сетку. Поэтому сами сделаем корретировку
$small-breakpoint:  em-calc(500 * 13/16);
$medium-breakpoint: em-calc(850 * 13/16);
$large-breakpoint:  em-calc(1100 * 13/16);
$xlarge-breakpoint: em-calc(1450 * 13/16);

$small-range:   (0, $small-breakpoint);
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint);
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint);
$xlarge-range:  ($large-breakpoint  + em-calc(1), $xlarge-breakpoint);
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999));

$screen: "only screen";

$small-up: $screen;
// $medium-up: 'min-width:#{lower-bound($medium-range)}'
// $large-up: 'min-width:#{lower-bound($large-range)}'
// $xlarge-up: '(min-width:#{lower-bound($xlarge-range)})'
// $xxlarge-up: 'min-width:#{lower-bound($xxlarge-range)}'

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";


// Убрал все кроме стандартного min-resolution. Автопрефиксер добавит то, что нужно для вебкита
$retina: "#{$screen} and (min-resolution: 1.7dppx)";

$anchor-text-decoration-hover: underline;
$alert-padding-top: rem-calc(30);
$alert-padding-default-float: rem-calc(12);
$alert-padding-opposite-direction: $alert-padding-default-float;
$alert-padding-bottom: rem-calc(15);

$alert-font-size: 1rem;
$alert-font-color: $black;

$alert-border-width: 0px;
$alert-bottom-margin: .5em;

$alert-close-color: $black;
$alert-close-top: 6px;
$alert-close-position: rem-calc(2);
$alert-close-font-size: rem-calc(17);
$alert-close-opacity: 1;
$alert-close-opacity-hover: 1;
$alert-close-padding: 4px 5px 5px 5px;
$alert-close-background: rgba(0,0,0, .0);

$reveal-overlay-bg: rgba($black, .88);
$reveal-modal-bg: tranparent;
$reveal-modal-padding: rem-calc(15);
$reveal-box-shadow: 0 0 10px rgba($black, .0);

$reveal-close-top: rem-calc(15);
$reveal-close-side: rem-calc(15);
$reveal-close-color: transparent;
$reveal-radius: 0;
$reveal-round: 0;
$reveal-border-color: transparent;
