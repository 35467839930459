section.main.places.index {
  //Сегодня
  .today {
    margin: 2em 0 2em 0;

    @media #{$large-up} {
      margin: 3em 0 3em 0;
    }
  }

  //Типы заведений
  .places-types {
    margin: 2em 0 2em 0;

    @media #{$large-up} {
      margin: 3em 0 3em 0;
    }
  }

  //Основные заведения
  .main-places {
    margin: 2em 0 2em 0;

    //Основное заведение
    .main-place {
      background-size: cover;
      color: $white;
      font-size: 1rem;

      .bg-gradient {
        padding: 1em 15px 2em 15px;
        position: relative;

        .like-recommends {
          position: absolute;
          right: 0;
          bottom: 100%;
        }

        .name {
          clear: both;
          margin: 2em 0 .3em 0;
          font-size: 1.2em;

          a {
            color: $white;
          }
        }

        .type {
          padding: 0 0 0 14px;
          font-size: .9em;
          position: relative;
          color: $_font-color-transparent;
          margin: 0 0 .3em 0;

          .icon {
            position: absolute;
            left: -2px;
            top: 1px;
          }

          .type-name {
            a {
              color: inherit;
            }
          }
        }

        .location {
          margin: 0 0 .6em 0;
        }

        .meta {
          margin: 0 0 .5em 0;
        }
      }
    }

    //Другие заведения
    .other-places {
      background: $black;

      article {
        color: $white;
        padding: 1em 15px 1em 15px;
        float: left;
        width: 50%;

        a.name {
          display: inline-block;
          color: $white;
          font-size: 1.1em;
          margin: 0 0 .5em 0;
        }

        .type {
          padding: 0 0 0 14px;
          font-size: .9em;
          position: relative;
          color: $_font-color-transparent;
          margin: 0 0 .3em 0;

          .icon {
            position: absolute;
            left: -2px;
            top: 1px;
          }

          .type-name {
            a {
              color: inherit;
            }
          }
        }

        .location {
          margin: 0 0 .7em 0;
        }
      }
    }

    @media #{$large-up} {
      margin: 3em 0 3em 0;

      .main-place {
        font-size: 1.4rem;

        .bg-gradient {
          padding-left: 30px;
          padding-right: 30px;

          .like-recommends {
            bottom: auto;
            top: 0;
          }

          .name {
            font-size: 1.4em;
          }

          .type {
            float: left;
            padding-left: 19px;
            margin-right: 30px;

            .icon {
              top: 3px;
            }
          }

          .location {
            float: left;
          }

          .meta {
            margin-top: .6em;
            margin-bottom: .7em;
          }
        }
      }

      .other-places {
        article {
          font-size: 1.2em;
          width: 25%;
          padding-left: 30px;
          padding-bottom: 2em;

          .type {
            padding-left: 19px;
            font-size: 1em;

            .icon {
              top: 3px;
            }
          }
        }
      }
    }
  }

  //Районы
  .areas {
    margin: 2em 0 2em 0;

    @media #{$large-up} {
      margin: 3em 0 3em 0;
    }
  }

  //Для детей
  .places-for-children {
    padding: 2em 0 0 0;
    background: image-url("events/children-bg-small.jpg");
    margin: 2em 0 2em 0;

    .places-tile-component {
      .ct-item {
        &.__plug {
          background-image: linear-gradient(135deg, rgba(173, 98, 28, 1), rgba(103, 20, 140, 1));
        }
      }
    }

    @media #{$large-up} {
      background: image-url("events/children-bg-large.jpg");
      padding-bottom: 0;
      margin: 3em 0 3em 0;
    }
  }
}