.tabs-nav-component {
  @include components-mixin;

  .ct-tn {
    &-item {
      display: inline-flex;
      font-size: 18px;
      letter-spacing: 0.77px;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      &:not(:last-child) {
        margin-right: 30px;
      }
      &:hover {
        opacity: 0.7;
      }
      &:not(.__active) {
        color: #000;

      }
      &.__active {
        color: #6DCD4C;
        border-bottom: 2px solid currentColor;
      }
    }
  }
}
