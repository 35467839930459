section.main.articles.show {
  //Блок с картинкой
  .article-image {
    color: $white;
    background-size: cover;
    background-position: center center;
    background-repeat: repeat;
    margin: 0 0 1em 0;

    //На мобайле у нас анимация при смене УРЛ в доскроле
    &.__animated {
      @extend .animated;
      @extend .zoomInUp;
    }

    .shadow {
      background: linear-gradient(to top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .3) 50%);
    }

    .container {
      padding-top: 6em;
      position: relative;
    }

    .ct-fi-open {
      right: 15px;
      top: 15px;
    }

    .small-image {
      float: left;
      width: 35%;
      max-width: 200px;

      img {
        width: 100%;
      }
    }

    .right-block {
      float: left;
      width: 65%;
      box-sizing: border-box;
      padding-left: 1em;
      padding-bottom: .3em;

      .title {
        margin: 0 0 .5em 0;
        font-size: 1.4rem;
      }

      .meta {
        > * {
          float: left;
          margin: 0 1rem .3rem 0;
          min-height: 1.5rem;
          box-sizing: border-box;
          display: inline-block;
          color: $_font-color-transparent;

          a {
            color: inherit;
          }
        }

        .small-text {
          float: none;
          font-size: .75em;

          span {
            display: inline-block;
            margin: 0 .7em 0 0;
          }
        }
      }
    }
  }

  //Текстовый блок с новостью
  .article {
    margin: 0 0 3em 0;
    line-height: $paragraph-line-height;
    color: $tuatara;

    .title {
      font-size: 1.3rem;
      margin: 0 0 1em 0;
    }

    //Тут основное содержание новости
    .text {
      font-size: 1.2rem;

      p, ul, li {
        font-size: 1.2rem;
      }

      a {
        display: inline-block;
        color: inherit;
        background: #ebebeb;
        padding: .1em .5em;
        border-radius: 1em;
        transition: all .5s;

        &:hover {
          text-decoration: none;
          background: darken(#ebebeb, 8%);
        }
      }
    }
  }

  //Видео-отчет
  .video-report {
    background-size: cover;
    background-position: center center;
    margin: 0 0 1.5em 0;
    color: $white;

    .shadow {
      background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, .17) 100%);
      padding: 2em 2em 2em 2em;

      .left {
        float: left;
        width: 75%;
        box-sizing: border-box;
        padding: .3em 0 0 0;

        .text {
          margin: 0 0 .3em 0;
        }
      }

      .play {
        float: right;
        width: 25%;
        text-align: right;

        .icon {
          cursor: pointer;
          @include sprite-image("play-small", 50, 50);
        }
      }
    }
  }

  //Фото-отчет
  .photo-report {
    margin: 0 0 1.5em 0;
  }

  //Другие новости
  .other-news {
    text-transform: uppercase;
    margin: 0 0 1.5em 0;

    .other-news-title {
      font-size: 1.3rem;
    }

    a.other-news-all {
      font-size: .85rem;
      color: $base;
      display: inline-block;
      margin: 0 0 0 3em;
    }
  }

  @media #{$large-up} {
    //Блок с карнтинкой
    .article-image {
      margin-bottom: 2em;

      //На десктопе при смене УРЛ в доскроле анимации нет
      &.__animated {
        animation-name: pluck;
      }

      .container {
        padding-top: 15em;
      }

      .ct-fi-open {
        top: 100px;
      }

      .small-image {
        width: 300px;
        max-width: 300px;
      }

      .right-block {
        width: 60%;
        padding-left: 2em;

        .title {
          font-size: 3.3rem;
        }

        .meta {
          font-size: 1.3em;

          > * {
            margin-right: 2.5rem;
          }

          .small-text {
            font-size: .8em;
            padding-top: 1em;
          }
        }
      }
    }

    //Текстовый блок с новостью
    .article {
      float: left;
      width: 60%;
      margin-bottom: 5em;

      .title {
        font-size: 2.2rem;
      }

      .text {
        font-size: 1.4rem;

        p, ul, li {
          font-size: 1.5rem;
        }

        .promo-code {
          font-size: 3rem;
          font-weight: bolder;
        }
      }
    }

    //Видео-отчет
    .video-report {
      .shadow {
        padding-left: 3em;
        padding-right: 3em;

        .left {
          .text {
            font-size: 1.6em;
            margin-top: 1em;
            margin-bottom: .7em;
          }
        }

        .play {
          .icon {
            @include sprite-image("play-big", 117, 117);
          }
        }
      }
    }

    //Фото-отчет
    .photo-report {
      margin-bottom: 2.5em;
    }

    //Другие новости
    .other-news {
      margin-bottom: 2.5em;
      clear: both;

      .other-news-title {
        font-size: 1.7rem;
      }

      a.other-news-all {
        font-size: 1.4rem;
      }
    }

    .other-news-container {
      width: 60%;
    }
  }

  @media #{$xlarge-up} {
    //Блок с картинкой
    .article-image {
      .right-block {
        width: 70%;
      }
    }
  }
}



// redesign
section.main.articles.show.test {
  //Блок с картинкой
  .articles-header {
    position: relative;
    .container {
      padding-top: 2em;
      position: relative;
    }


    .info-line-news-component {
      font-size: 1.2em;
      display: inline-block;
      margin-bottom: 15px;
      .ct-date {
        display: inline-block;
        float: none;
      }
    }

    .views-component, .author {
      display: inline-block;
    }

    .views-component {
      margin-right: 50px;
    }

    .author {
      color: white;
      font-size: 1.3em;
    }

    .title {
      font-size: 1.5em;
      color: white;
      margin: 15px 0;
      line-height: 1.1em;
    }

    .small-text {
      color: white;
      margin: 15px 0;
    }


    .image {
      box-shadow: 0 10px 40px 0 rgba(0,0,0,0.23);
      img {
        width: 100%;
      }
    }
  }

  @media #{$large-up} {
    //Блок с карнтинкой
    .articles-header {
      margin-bottom: 5em;
      .container {
        padding-top: 8em;
        padding-bottom: 20px;
      }

      .info-line-news-component {
        font-size: 1.5em;
        margin-top: 30px;
      }

      .title {
        width: 45%;
        font-size: 2em;
        margin: 30px 0;
      }

      .image {
        width: 43%;
        position: absolute;
        bottom: -25px;
        right: 0;
      }

      .small-text {
        color: gray;
        position: absolute;
        bottom: -25px;
        margin: 0;
      }
    }
  }

  @media #{$xlarge-up} {
    .articles-header {
      .title {
        font-size: 3.1em;
      }
    }
  }
}
