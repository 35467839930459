section.main.people {

  //Блок с фото
  .photo-block {
    margin: 0 0 2em 0;

    .gallery {
      position: relative;

      .root {
        position: relative;
        overflow: hidden;

        .list {
          // width: 20000em;
          position: relative;

          article {
            // float: left;
            background-position: center center;
            background-size: cover;

            .shadow {
              background: linear-gradient(to bottom, rgba($black, .47), rgba($black, .8));
              padding: 4em 0 1em 0;
            }

            .leftcol {
              float: left;
              width: 100px;
              box-sizing: border-box;

              .img {
                border-radius: 50%;
                background-position: center center;
                background-size: cover;
                display: block;

                img {
                  display: block;
                  width: 100%;
                }
              }
            }

            .rightcol {
              float: left;
              width: 60%;
              padding-left: 15px;
              box-sizing: border-box;

              .name {
                font-size: 1.3em;
                margin: 0 0 .7em 0;
                color: $white;

                a {
                  color: $white;
                  display: inline-block;
                }
              }

              .meta {
                @extend .clearfix;

                .profession, .age {
                  opacity: .8;
                  color: $white;
                  float: left;
                  text-transform: uppercase;
                  margin: 0 1.2em .3em 0;
                }

                .wikipedia {
                  color: $white;
                  @extend .clearfix;
                  float: left;

                  .icon {
                    float: left;
                    @include svg-image("wikipedia", 13, 13);
                    margin: .25em .4em 0 0;
                  }

                  .text {
                    display: inline-block;
                    float: left;
                  }
                }
              }
            }
          }
        }
      }

      .controls {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        .control {
          position: absolute;
          top: 15px;
          cursor: pointer;

          &.left {
            @include svg-image("arrow-left-white-thick", 15, 20);
            left: 15px;
          }

          &.right {
            @include svg-image("arrow-right-white-thick", 15, 20);
            right: 15px;
          }
        }
      }

      .carousel-pagination {
        display: none;
      }
    }

    @media #{$large-up} {
      margin-bottom: 5em;

      .gallery {
        .root {
          .list {
            article {
              .shadow {
                padding-top: 10em;
                padding-bottom: 3em;
              }

              .leftcol {
                width: 330px;
                padding-left: 40px;
              }

              .rightcol {
                font-size: 1.5em;
                padding-top: 4em;
                padding-right: 0;
                padding-bottom: 50px;
                padding-left: 40px;
                width: 450px;

                .name {
                  font-size: 1.7em;
                }

                .meta {
                  .profession, .age {
                    text-transform: none;
                  }

                  .wikipedia {
                    .icon {
                      @include svg-image("wikipedia", 20, 20);
                    }
                  }
                }
              }
            }
          }
        }

        .controls {
          .control {
            top: 18em;

            &.left {
              @include svg-image("arrow-left-white-thick", 27, 42);
            }

            &.right {
              @include svg-image("arrow-right-white-thick", 27, 42);
            }
          }
        }

        .carousel-pagination {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          display: block;

          .pagination {
            position: absolute;
            right: 50px;
            bottom: 20px;

            a {
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              margin-left: 5px;
              background: #b3b3b3;
              color: transparent;

              &.active {
                background: $white;
              }
            }
          }
        }
      }
    }
  }

  .all-legends-link {
    background-color: black;
    border-top: 1px solid $white;
    padding: 1.4em 0;
    width: 100%;
    height: 60px;
    text-align: center;
    text-transform: uppercase;

    a {
      color: $white;
      font-size: 1.3em;
    }

    @media #{$large-up} {
      padding: 1em 0;
      a {
        font-size: 1.7em;
      }
    }

  }

  //Блок с круглыми фотками
  .rounded-photos-block {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 15px 2em 15px;

    article {
      flex-basis: 33%;
      box-sizing: border-box;
      padding: 0 1em 1em 0;

      .avatar {
        display: block;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
        margin: 0 0 .3em 0;

        img {
          display: block;
          width: 100%;
        }
      }

      .name {
        color: $black;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 0 .2em 0;
        font-size: .85em;
      }

      .post {
        font-size: .75em;
      }
    }

    @media #{$medium-up} {
      article {
        flex-basis: 25%;
      }
    }

    @media #{$large-up} {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 3.5em;

      article {
        flex-basis: 20%;
        font-size: 1.5em;
        padding-right: 1.5em;
        padding-bottom: 1.5em;

        .name, .post {
          margin-left: 1rem;
        }
      }
    }

    @media #{$xlarge-up} {
      article {
        font-size: 1.7em;

        .name, .post {
          margin-left: 2rem;
        }
      }
    }
  }

  //Список легенд
  .legend-items {
    margin: 0 0 2em 0;

    article {
      background-position: center center;
      background-size: cover;
      margin-bottom: 2px;

      .shadow {
        background: linear-gradient(to bottom, rgba($black, .47), rgba($black, .8));
        padding: 1em 0 1em 0;
      }

      .leftcol {
        float: left;
        width: 100px;
        box-sizing: border-box;

        .img {
          border-radius: 50%;
          background-position: center center;
          background-size: cover;
          display: block;

          img {
            display: block;
            width: 100%;
          }
        }
      }

      .rightcol {
        float: left;
        width: 60%;
        padding-left: 15px;
        box-sizing: border-box;

        .name {
          font-size: 1.3em;
          margin: 0 0 .7em 0;
          color: $white;

          a {
            color: $white;
            display: inline-block;
          }
        }

        .meta {
          @extend .clearfix;

          .profession, .age {
            opacity: .8;
            color: $white;
            float: left;
            text-transform: uppercase;
            margin: 0 1.2em .3em 0;
          }

          .wikipedia {
            color: $white;
            @extend .clearfix;
            float: left;

            .icon {
              float: left;
              @include svg-image("wikipedia", 13, 13);
              margin: .25em .4em 0 0;
            }

            .text {
              display: inline-block;
              float: left;
            }
          }
        }
      }
    }
  }

  @media #{$large-up} {

    .legend-items {
      article {
        .shadow {
          padding-top: 3em;
          padding-bottom: 3em;
        }

        .leftcol {
          width: 170px;
          padding-left: 20px;
        }

        .rightcol {
          font-size: 1.5em;
          padding-top: 1em;
          padding-left: 1em;
          // width: 450px;

          .name {
            font-size: 1.7em;
            margin-bottom: .5em;
          }

          .meta {
            .profession, .age {
              text-transform: none;
            }

            .wikipedia {
              .icon {
                @include svg-image("wikipedia", 20, 20);
              }
            }
          }
        }
      }
    }
  }

  @media #{$xlarge-up} {
    .legend-items {
      article {
        .leftcol {
          // margin-left: 50px;
          // width: 240px
        }
      }
    }
  }
}
