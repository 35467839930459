//Плитка фото
.photo-tile-component {
  .ct-item {
    float: left;
    margin: 0 0 .4em 0;
    display: inline-block;
    width: 25%;
    background-position: center center;
    background-size: cover;

    img {
      width: 100%;
    }
  }

  @media #{$medium-up} {
    .ct-item {
      width: 20%;
    }
  }

  @media #{$large-up} {
    .ct-item {
      width: 14.2%;
    }
  }

  @media #{$xlarge-up} {
    .ct-item {
      width: 12.5%;
    }
  }
}