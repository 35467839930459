//Развернуть. Тут префиксы длинные у классов, потому что
//.expand-component навешивается на элемент, в котором куча всего может быть
//помимо нашей кнопки
.expand-component {
  position: relative;

  .ct-expand-component-trigger {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 2em 0 1em 0;
    font-size: .8rem;
    text-transform: uppercase;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 30%, rgba(255, 255, 255, 1) 50%);

    .ct-expand-component-trigger-text {
      position: relative;
      padding-left: 16px;
      cursor: pointer;

      .ct-expand-component-trigger-caret {
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top-color: $black;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
  }

  &.__expand-component-collapsed {
    max-height: 200px;
    overflow: hidden;

    .ct-expand-component-trigger {
      display: block;
    }
  }
}