//Расположение
.location-component {
  @include components-mixin;
  position: relative;
  padding-left: 10px;

  .ct-icon {
    position: absolute;
    left: 0;
    top: .2em;
    @include sprite-image("location-component-icon-white-small", 7, 11);
  }

  .ct-text {
    color: $white;
    font-size: .85em;
    display: inline-block;
  }

  &.__grey {
    .ct-icon {
      @include sprite-image("location-component-icon-grey-small", 7, 11);
    }

    .ct-text {
      color: #bebebe;
    }
  }

  &.__dark-grey {
    .ct-icon {
      @include sprite-image("location-component-icon-dark-grey-small", 7, 11);
    }

    .ct-text {
      color: #4A4A4A;
    }
  }

  &.__transparent {
    .ct-icon, .ct-text {
      opacity: .8;
    }
  }

  @mixin large() {
    padding-left: 18px;

    .ct-icon {
      @include sprite-image("location-component-icon-white-big", 11, 17);
    }

    .ct-text {
      font-size: 1.05em;
    }

    &.__grey {
      .ct-icon {
        @include sprite-image("location-component-icon-grey-big", 11, 17);
      }
    }

    &.__dark-grey {
      .ct-icon {
        @include sprite-image("location-component-icon-dark-grey-big", 11, 17);
      }
    }
  }

  //Одно размера на всех экранах
  &.__one-size {
    @include large();
  }

  @media #{$large-up} {
    @include large();

    //Большой размер
    &.__large {
      .ct-text {
        font-size: 1.3em;
      }

      .ct-icon {
        top: .3em;
      }
    }
  }
}