section.main.people.index {

  //Блок с лучшими
  .best-block {
    @extend .clearfix;
    margin: 0 0 1.5em 0;

    .col {
      margin: 0 0 1.5em 0;

      .avatars {
        padding: 1em 0 0 0;
        margin: 0 0  .6em 0;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        @extend .clearfix;

        article {
          float: left;
          width: 25%;
          box-sizing: border-box;
          padding-right: 1em;
          padding-bottom: 1em;

          a {
            background-position: center center;
            background-size: cover;
            border-radius: 50%;
            display: block;

            img {
              display: block;
              width: 100%;
            }
          }
        }
      }

      .title {
        position: relative;
        display: block;
        padding-right: 30px;
        color: $black;
        font-size: 1.2em;

        .icon {
          position: absolute;
          right: 0;
          top: 5px;
          @include svg-image("arrow-right-black", 14, 14);
        }
      }
    }
  }

  //Разделы
  .sections {
    margin: 0 15px 2em 15px;

    .ct-item {
      .ct-icon {
        &.author {
          @include svg-image("people-author-black", 25, 25);
        }

        &.specialist {
          @include svg-image("people-specialist-black", 25, 25);
        }

        &.editor {
          @include svg-image("people-editor-black", 25, 25);
        }

        &.expert {
          @include svg-image("people-expert-black", 25, 25);
          background-position: center center;
        }
      }
    }
  }

  @media #{$large-up} {
    //Блок с лучшими
    .best-block {
      margin-bottom: 4.5em;

      .col {
        float: left;
        width: 30%;
        margin-right: 5%;

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        .avatars {
          border-top-width: 2px;
          border-bottom-width: 2px;

          article {
            width: 33.3%;
          }
        }

        .title {
          font-size: 1.8em;
          padding-right: 35px;

          .icon {
            @include svg-image("arrow-right-black", 14, 27);
          }
        }
      }
    }

    //Разделы
    .sections {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  @media #{$xlarge-up} {
    //Разделы
    .sections {
      margin:  0 0 2em 0;
      .ct-item {
        width: 23.5%;
        margin-right: 2%;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
