//Рейтинг-просмотры-лайки
.r-v-l-component {
  @include components-mixin;

  .ct-rating {
    float: left;
    margin: 1px 5px 5px 0;
  }

  .ct-views {
    float: left;
    margin: 0 2px 5px 0;
  }

  .ct-likes {
    float: left;
    margin: 0 0 5px 0;
  }

  @media #{$large-up} {
    .ct-rating {
      margin-right: 15px;
    }

    .ct-views {
      margin-right: 10px;
    }
  }
}