//TODO удалить этот файл после того как закончу разработку _main_block_component.scss
@keyframes translateArrow {
  0% { right: 0 }
  50% { right: -10px }
  100% { right: 0 }
}

.icons-news-component {
  display: inline-block;
  opacity: 0.6;

  .ct-icons {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    color: white;
    @extend .clearfix;

    * {
      display: inline-block;
      margin: 0 35px 0 0;
      padding-left: 20px;
      position: relative;

      &.ct-location {
        @include svg-image("location-white", 18, 18);
        display: none;
      }

      &.ct-video {
        @include svg-image("news-video", 18, 18);
      }

      &.ct-user {
        @include svg-image("news-user", 19, 19);
      }

      &.ct-comment {
        margin-top: 3px;
        @include svg-image("news-comment", 19, 15);
      }

      &.ct-photo {
        @include svg-image("news-photo", 19, 19);
      }

      @media #{$xlarge-up} {
        &.ct-location {
          display: inline-block;
          width: 100%;
          margin: 0 0 25px;
        }
      }
    }
  }
}

.info-line-news-component {
  color: black;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: left;

  .ct-label {
    color: white;
    display: inline-block;
    background-color: black;
    font-size: 1em;
    padding: 4px 5px;
    margin-right: 5px;
  }

  .ct-link {
    display: inline-block;
    font-size: 1em;
    padding-right: 20px;

    span {
      display: none;
      &.show-xs-up {
        display: inline-block;
      }
    }

    &:after {
      content: '';
      position: relative;
      right: 0;
      top: 2px;
      @include svg-image("arrow-right-black", 9, 15);
    }
  }

  .ct-link-wrap {
    display: inline-block;
    color: inherit;
    text-decoration: none;

    &:hover {
      .ct-link:after {
        animation: translateArrow 1s;
      }
    }
  }

  .ct-date {
    float: right;
    font-size: 1em;
    margin-top: 3px;
    opacity: 0.6;

    &.show-only-xlg {
      display: none;
    }
  }

  &.__white {
    color: white;

    .ct-label {
      background-color: white;
      color: black;
    }

    .ct-link {
      &:after {
        @include svg-image("arrow-right-white", 9, 15);
      }
    }
  }

  @media #{$large-up} {
    .ct-date {
      margin-top: 8px;
    }

    .ct-label {
      padding: 4px 15px;
      font-size: 1.1em;
    }

    .ct-link {
      span.show-lg-up {
        display: inline-block;
      }
    }
  }

  @media #{$xlarge-up} {
    .ct-link {
      span.show-xlg-up {
        display: inline-block;
      }
    }

    .ct-date {
      &.show-only-xlg {
        display: inline-block;
      }
      &.show-only-xs {
        display: none;
      }
    }
  }
}

.section-news-component {
  margin-bottom: 2em;

  @media #{$large-up} {
    margin-bottom: 4em;
  }
}

.two-column-news-component {
  @include components-mixin;

  .views-component {
    text-align: left;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .ct-news-item {
    margin-bottom: 15px;
    color: black;
    padding: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #F8F8F8;
    }
  }
  .ct-title {
    font-size: 1.4em;
    color: inherit;
    margin-bottom: 10px;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }

  .ct-tile-list {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
  }

  .ct-tile-item {
    color: white;
    float: left;
    width: 50%;
    background-position: center;
    background-size: cover;
    display: flex;

    .gradient-component {
      width: 100%;
      position: relative;
    }

    .ct-title {
      -webkit-font-smoothing: antialiased;
      font-weight: bold;
      line-height: 1em;
    }
    .ct-content {
      position: relative;
      height: 100%;
      padding: 10px 10px 20px;
    }

    &.__2 {
      width: 100%;
      text-align: center;

      .ct-content {
        padding-bottom: 37px;
      }

      .info-line-news-component {
        margin-bottom: 22px;
      }

      .ct-title {
        padding: 0 22px;
      }
    }
  }

  @media #{$large-up} {
    .views-component {
      bottom: 20px;
      left: 20px;
    }

    .ct-title {
      font-size: 1.7em;
    }

    .ct-col {
      float: left;
      width: 50%;
    }

    .ct-tile-item {
      .ct-title {
        font-size: 2em;
      }

      .ct-content {
        padding: 20px 20px 40px;
      }
    }
  }
}

.one-column-news-component {
  @include components-mixin;

  .views-component {
    position: absolute;
    bottom: 10px;
  }

  .ct-tile-item {
    position: relative;
    float: left;
    width: 50%;
    background-position: center;
    background-size: cover;

    &.__without-bg {
      margin-top: 10px;

      &:nth-child(2n+1) {
        border-right: 1px solid #ddd;
      }

      .ct-title {
        color: black;
        font-weight: normal;
      }

      &:hover {
        background-color: #F8F8F8;
      }
    }
  }

  .ct-inner {
    padding: 10px 10px 20px;
    height: 100%;
    position: relative;
  }

  .ct-title {
    color: white;
    font-size: 1.4em;
    display: block;
    margin: 17px 0;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
  @media #{$large-up} {
    .views-component {
      bottom: 20px;
    }

    .ct-tile-item {
      width: 25%;

      &.__2, &.__3 {
        .ct-title {
          font-size: 2.6em;
          padding: 0 30px;
          margin: 30px 0;
        }
      }

      &.__2 {
        width: 50%;
      }

      &.__3 {
        width: 75%;
      }

      &.__without-bg {
        border-right: 1px solid #ddd;

        &:nth-child(4n) {
          border-right: none;
        }

        .ct-title {
          font-size: 1.7em;
        }
      }
    }

    .ct-inner {
      padding: 20px 20px 30px;
    }

    .ct-title {
      font-size: 2em;
      line-height: 1em;
    }
  }
}

.single-news-component {
  @include components-mixin;

  .info-line-news-component {
    .views-component {
      position: relative;
      float: right;
      bottom: 0;
      margin-left: 30px;
      margin-top: 5px;
      font-size: 1em;
    }
  }

  .gradient-component {
    padding: 11px;
  }

  .ct-inner {
    position: relative;
    padding: 0;
  }

  .ct-image {
    min-height: 140px;
    width: 100%;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.23);
    img {
      width: 100%;
    }
  }

  .ct-title {
    margin: 15px 0;
    color: white;
    font-size: 1.7em;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }

  .ct-desc {
    color: white;
    font-size: 1.2em;
    opacity: 0.8;
  }

  &.__without-bg {
    .ct-title {
      color: black;
    }

    .ct-image {
      box-shadow: 0 5px 40px 0 rgba(0,0,0,0.21);

      &:hover {
        box-shadow: 0 5px 20px 0 rgba(0,0,0,0.21);
      }
    }
  }

  &.__reverse {
    .ct-inner {
      padding: 0 15px;
    }
  }

  @media #{$large-up} {
    .info-line-news-component {
      .views-component {
        margin-top: 10px;

        .ct-num {
          margin-top: -3px;
        }
      }
    }

    .gradient-component {
      padding: 50px 35px;
    }

    .icons-news-component {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .info-line-news-component {
      font-size: 1.4em;
      display: inline-block;
      width: 45%;

      .ct-link {
        &:after {
          width: 15px;
          height: 20px;
          top: 4px;
        }
      }
    }

    .ct-image {
      float: right;
      width: 50%;
      margin-left: 25px;
      margin-bottom: 25px;
    }

    .ct-title {
      font-size: 2.75em;
      line-height: 1em;
      width: 45%;
      margin-bottom: 20px;
    }

    &.__without-bg {
      .ct-inner {
        padding: 0
      }
    }

    &.__reverse {
      .ct-inner {
        padding: 0;
      }

      .ct-image {
        float: left;
      }

      .ct-title,
      .info-line-news-component {
        float: right;
      }
    }
  }
}

.choice-editors-component {
  @include components-mixin;
  margin: 2em 0;

  .title-component {
    display: inline-block;

    .ct-title {
      margin-right: .5em;
    }
  }

  .logo {
    @include svg-image("logo-white", 56, 20);
    position: relative;
    top: 4px;
  }

  .gradient-component {
    padding: 15px 0 46px 0;
  }

  .ct-inner {
    padding: 12px 12px 37px 12px;
  }

  .ct-item {
    background-color: white;
    text-align: center;
    margin-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 2.5%;
      bottom: -8px;
      background-color: white;
      opacity: 0.4;
      width: 95%;
      height: 10px;
    }

    &:hover {
      background-color: #F7F7F7;
    }

    .ct-title {
      color: black;
      font-size: 1.4em;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media #{$large-up} {
    font-size: 1.3rem;
    margin: 4em 0;

    .logo {
      @include svg-image("logo-white", 126, 45);
      top: 9px;
    }

    .gradient-component {
      padding: 28px 0 73px 0;
    }

    .info-line-news-component {
      margin-bottom: 40px;
    }

    .ct-item {
      width: 49.8%;
      float: left;

      &:nth-child(2n) {
        float: right;
      }
    }
  }
}
