@mixin svg-common {
  background: url("sprite-17fa5705.svg") no-repeat;
}

%svg-common {
  background: url("sprite-17fa5705.svg") no-repeat;
}


  @mixin svg-arrow-left {
    background-position: 0 0;
    width: 20px;
    height: 14px;
  }

  .svg-arrow-left {
    @extend %svg-common;
    background-position: 0 0;
    width: 20px;
    height: 14px;
  }
  @mixin svg-arrow-left-white-large {
    background-position: 0 2.5044722719141324%;
    width: 14px;
    height: 27px;
  }

  .svg-arrow-left-white-large {
    @extend %svg-common;
    background-position: 0 2.5044722719141324%;
    width: 14px;
    height: 27px;
  }
  @mixin svg-arrow-right-black {
    background-position: 0 7.192982456140351%;
    width: 9px;
    height: 16px;
  }

  .svg-arrow-right-black {
    @extend %svg-common;
    background-position: 0 7.192982456140351%;
    width: 9px;
    height: 16px;
  }
  @mixin svg-arrow-right-grey {
    background-position: 0 10%;
    width: 9px;
    height: 16px;
  }

  .svg-arrow-right-grey {
    @extend %svg-common;
    background-position: 0 10%;
    width: 9px;
    height: 16px;
  }
  @mixin svg-arrow-right-white {
    background-position: 0 12.807017543859649%;
    width: 9px;
    height: 16px;
  }

  .svg-arrow-right-white {
    @extend %svg-common;
    background-position: 0 12.807017543859649%;
    width: 9px;
    height: 16px;
  }
  @mixin svg-arrow-right-white-large {
    background-position: 0 15.921288014311271%;
    width: 14px;
    height: 27px;
  }

  .svg-arrow-right-white-large {
    @extend %svg-common;
    background-position: 0 15.921288014311271%;
    width: 14px;
    height: 27px;
  }
  @mixin svg-chevron-right {
    background-position: 0 20.49469964664311%;
    width: 10px;
    height: 20px;
  }

  .svg-chevron-right {
    @extend %svg-common;
    background-position: 0 20.49469964664311%;
    width: 10px;
    height: 20px;
  }
  @mixin svg-close {
    background-position: 0 24.02826855123675%;
    width: 20px;
    height: 20px;
  }

  .svg-close {
    @extend %svg-common;
    background-position: 0 24.02826855123675%;
    width: 20px;
    height: 20px;
  }
  @mixin svg-eye {
    background-position: 0 27.177700348432055%;
    width: 17px;
    height: 12px;
  }

  .svg-eye {
    @extend %svg-common;
    background-position: 0 27.177700348432055%;
    width: 17px;
    height: 12px;
  }
  @mixin svg-facebook-rounded {
    background-position: 0 30.76923076923077%;
    width: 40px;
    height: 40px;
  }

  .svg-facebook-rounded {
    @extend %svg-common;
    background-position: 0 30.76923076923077%;
    width: 40px;
    height: 40px;
  }
  @mixin svg-instagram-rounded {
    background-position: 0 38.095238095238095%;
    width: 40px;
    height: 40px;
  }

  .svg-instagram-rounded {
    @extend %svg-common;
    background-position: 0 38.095238095238095%;
    width: 40px;
    height: 40px;
  }
  @mixin svg-location {
    background-position: 0 43.50877192982456%;
    width: 10px;
    height: 16px;
  }

  .svg-location {
    @extend %svg-common;
    background-position: 0 43.50877192982456%;
    width: 10px;
    height: 16px;
  }
  @mixin svg-location-xsmall {
    background-position: 0 45.674740484429066%;
    width: 5px;
    height: 8px;
  }

  .svg-location-xsmall {
    @extend %svg-common;
    background-position: 0 45.674740484429066%;
    width: 5px;
    height: 8px;
  }
  @mixin svg-logo-black {
    background-position: 0 51.031894934333955%;
    width: 145px;
    height: 53px;
  }

  .svg-logo-black {
    @extend %svg-common;
    background-position: 0 51.031894934333955%;
    width: 145px;
    height: 53px;
  }
  @mixin svg-logo-black-small {
    background-position: 0 58.035714285714285%;
    width: 73px;
    height: 26px;
  }

  .svg-logo-black-small {
    @extend %svg-common;
    background-position: 0 58.035714285714285%;
    width: 73px;
    height: 26px;
  }
  @mixin svg-logo-white {
    background-position: 0 63.016157989228006%;
    width: 76px;
    height: 29px;
  }

  .svg-logo-white {
    @extend %svg-common;
    background-position: 0 63.016157989228006%;
    width: 76px;
    height: 29px;
  }
  @mixin svg-logo-white-small {
    background-position: 0 66.78383128295255%;
    width: 47px;
    height: 17px;
  }

  .svg-logo-white-small {
    @extend %svg-common;
    background-position: 0 66.78383128295255%;
    width: 47px;
    height: 17px;
  }
  @mixin svg-photo {
    background-position: 0 70.01763668430335%;
    width: 19px;
    height: 19px;
  }

  .svg-photo {
    @extend %svg-common;
    background-position: 0 70.01763668430335%;
    width: 19px;
    height: 19px;
  }
  @mixin svg-photo-xsmall {
    background-position: 0 72.22222222222223%;
    width: 9px;
    height: 10px;
  }

  .svg-photo-xsmall {
    @extend %svg-common;
    background-position: 0 72.22222222222223%;
    width: 9px;
    height: 10px;
  }
  @mixin svg-search {
    background-position: 0 75.53191489361703%;
    width: 22px;
    height: 22px;
  }

  .svg-search {
    @extend %svg-common;
    background-position: 0 75.53191489361703%;
    width: 22px;
    height: 22px;
  }
  @mixin svg-star {
    background-position: 0 78.04878048780488%;
    width: 12px;
    height: 12px;
  }

  .svg-star {
    @extend %svg-common;
    background-position: 0 78.04878048780488%;
    width: 12px;
    height: 12px;
  }
  @mixin svg-thumb-up {
    background-position: 0 80.41958041958041%;
    width: 13px;
    height: 14px;
  }

  .svg-thumb-up {
    @extend %svg-common;
    background-position: 0 80.41958041958041%;
    width: 13px;
    height: 14px;
  }
  @mixin svg-ticket {
    background-position: 0 84.34163701067615%;
    width: 24px;
    height: 24px;
  }

  .svg-ticket {
    @extend %svg-common;
    background-position: 0 84.34163701067615%;
    width: 24px;
    height: 24px;
  }
  @mixin svg-video {
    background-position: 0 87.98586572438163%;
    width: 20px;
    height: 20px;
  }

  .svg-video {
    @extend %svg-common;
    background-position: 0 87.98586572438163%;
    width: 20px;
    height: 20px;
  }
  @mixin svg-video-xsmall {
    background-position: 0 89.77469670710572%;
    width: 10px;
    height: 9px;
  }

  .svg-video-xsmall {
    @extend %svg-common;
    background-position: 0 89.77469670710572%;
    width: 10px;
    height: 9px;
  }
  @mixin svg-views {
    background-position: 0 91.81184668989548%;
    width: 19px;
    height: 12px;
  }

  .svg-views {
    @extend %svg-common;
    background-position: 0 91.81184668989548%;
    width: 19px;
    height: 12px;
  }
  @mixin svg-views-xsmall {
    background-position: 0 93.09153713298791%;
    width: 10px;
    height: 7px;
  }

  .svg-views-xsmall {
    @extend %svg-common;
    background-position: 0 93.09153713298791%;
    width: 10px;
    height: 7px;
  }
  @mixin svg-vkontakte-rounded {
    background-position: 0 100%;
    width: 40px;
    height: 40px;
  }

  .svg-vkontakte-rounded {
    @extend %svg-common;
    background-position: 0 100%;
    width: 40px;
    height: 40px;
  }
