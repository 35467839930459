section.main.packets.show {
  //Верхний блок
  .top-block {
    background-position: center center;
    background-size: cover;
    color: $white;
    margin: 0 0 1em 0;

    //На мобайле у нас анимация при смене УРЛ в доскроле
    &.__animated {
      @extend .animated;
      @extend .zoomInUp;
    }

    .shadow {
      background: rgba($black, .5);
      padding-top: 5em;
      padding-bottom: .5em;
    }

    .container {
      position: relative;
    }

    .ct-fi-open {
      right: 15px;
      bottom: 15px;
    }

    .name {
      font-size: 1.5em;
      margin: 0 40px .7em 0;
    }

    .meta {
      margin: 0 40px 0 0;
      color: $white;
      @extend .clearfix;

      > * {
        float: left;
        min-height: 1.5rem;
        margin: 0 1.4rem .5rem 0;
        box-sizing: border-box;
        display: inline-block;
      }

      .date, .author, .small-text {
        opacity: .8;
      }

      .small-text {
        float: none;
        font-size: .75em;

        span {
          display: inline-block;
          margin: 0 .7em 0 0;
        }
      }

    }

    @media #{$large} {
      margin: 0 0 2.5em 0;

      //На десктопе при смене УРЛ в доскроле анимации нет
      &.__animated {
        animation-name: pluck;
      }

      .shadow {
        padding-top: 230px;
        padding-bottom: 2em;

        .name {
          font-size: 3em;
          margin-right: 60px;
        }

        .meta {
          width: 500px;
          font-size: 1.3em;

          > * {
            min-height: 2.5rem;
            margin-right: 2rem;
          }

          .small-text {
            font-size: .8em;
            padding-top: 1em;
          }
        }
      }
    }
  }

  //Колонки
  .cols {
    .leftcol {
      margin: 0 0 1.5em 0;
    }

    .rightcol {
      //Разделитель между автором и элементами
      .divider-line {
        border-top: 1px solid #d4d4d4;
        margin: 0 0 1em 0;
      }
    }

    @media #{$large-up} {
      .leftcol {
        float: left;
        width: 60%;
      }

      .rightcol {
        float: right;
        width: 300px;
      }
    }
  }

  //Автор
  .author-block {
    @extend .clearfix;
    margin: 0 0 1em 0;

    .avatar {
      float: left;
      width: 55px;
      height: 55px;
      background-size: cover;
      background-position: center center;
      background-image: image-url("svg-icons/ava-default.svg");
      border-radius: 50%;
    }

    .text {
      margin-left: 70px;

      small {
        margin: .7em 0 0 0;
        color: #c9c9c9;
        display: block;
        font-size: .85em;
      }

      .name {
        color: $black;
        font-size: 1.2em;
      }
    }
  }

  //Текстовый блок
  .text-block {
    margin: 0 0 1em 0;

    .title {
      font-size: 2.2rem;
      margin: 0 0 1em 0;
      color: $tuatara;
    }

    p {
      font-size: 1.25em;
      color: #434343;
    }

    @media #{$large-up} {
      p {
        font-size: 1.5em;
      }
    }
  }

  //Врезка. Есть 5 вариантов врезки для события, заведения, новости, фотоальбома и видео
  .inset-block {
    //Событие, заведение
    &.event, &.place {
      background-size: cover;
      background-position: center;
      margin: 0 -15px 1em -15px;

      .shadow {
        background: linear-gradient(to top, rgba($black, .5), rgba($black, .25));
        padding: 5em 30px .5em 30px;
        position: relative;
      }

      .like-recommends {
        position: absolute;
        right: 0;
        top: 0;
      }

      .for-children {
        @include svg-image("for-children-transparent", 14, 17);
        position: absolute;
        right: 10px;
        top: 4px;
      }

      .like-recommends + .for-children {
        right: 102px;
        top: 3px;
      }

      .type-and-date {
        @extend .clearfix;
        font-size: .85em;
        opacity: .7;
        color: $white;
        margin: 0 0 1.3em 0;

        a {
          display: inline-block;
          color: inherit;
        }

        .type {
          float: left;
          margin: 0 2em 0 0;
          @extend .clearfix;

          .icon {
            float: left;
            margin: 0 .3em 0 0;
            width: 15px;
            height: 15px;
          }

          .text {
            float: left;
          }
        }

        .date {
          float: left;
        }
      }

      .name {
        color: $white;
        font-size: 1.3em;
        margin: 0 0 .6em 0;

        a {
          color: inherit;
          display: inline-block;
        }
      }

      .meta {
        @extend .clearfix;

        > * {
          min-height: 1.4rem;
          margin: 0 1rem 1rem 0;
          float: left;
          box-sizing: border-box;
        }

        .tags {
          padding-top: .2em;
        }
      }
    }

    //Видео
    &.video {
      background-size: cover;
      margin: 0 -15px 1.5em -15px;
      color: $white;
      background-position: center center;

      .shadow {
        background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, .17) 100%);
        padding: 2em 2em 2em 2em;

        .left {
          float: left;
          width: 75%;
          box-sizing: border-box;
          padding: .3em 0 0 0;

          .text {
            margin: 0 0 .3em 0;

            a {
              display: inline-block;
              color: inherit;
            }
          }
        }

        .play {
          float: right;
          width: 25%;
          text-align: right;

          .icon {
            cursor: pointer;
            @include sprite-image("play-small", 50, 50);
          }
        }
      }
    }

    //Фото
    &.photo {
      margin: 0 0 2em 0;

      .photo-title {
        text-transform: uppercase;
        font-size: 1.1em;
        margin: 0 0 .8em 0;

        a {
          display: inline-block;
          color: inherit;
        }
      }
    }

    //Новость
    &.article {
      margin: 0 0 1.5em 0;
      width: 270px;
      @extend .clearfix;

      //Левая часть
      a.image {
        display: inline-block;
        position: relative;
        width: 100%;

        img {
          margin: 0;
          width: 100%;
        }

        .icons {
          display: inline-block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          box-sizing: border-box;
          padding: 15px 10px 10px 10px;
          @extend .clearfix;
          background: linear-gradient(to top, rgba($black, .6), rgba($black, 0));

          * {
            float: left;
            display: inline-block;
            margin: 0 12px 0 0;

            &.video {
              @include svg-image("news-video", 18, 18);
            }

            &.user {
              @include svg-image("news-user", 19, 19);
            }

            &.comment {
              margin-top: 3px;
              @include svg-image("news-comment", 19, 15);
            }

            &.photo {
              @include svg-image("news-photo", 19, 19);
            }
          }
        }
      }

      //Правая часть
      .text {
        padding: .5em 0 .5em 0;
        box-sizing: border-box;

        a {
          color: inherit;
        }

        a.title {
          color: $black;
          font-size: 1.1em;
          display: inline-block;
          margin: 0 0 .7em 0;
        }

        .date {
          color: #4A4A4A;
          text-transform: uppercase;
          font-size: .8em;
        }
      }
    }

    @media #{$medium-up} {
      //Новость
      &.article {
        width: auto;

        a.image {
          float: left;
          width: 50%;
          max-width: 270px;
        }

        .text {
          float: left;
          width: 50%;
          padding-left: 1em;
        }
      }
    }

    @media #{$large-up} {
      //Событие, заведение
      &.event, &.place {
        margin-left: 0;
        margin-right: 0;

        .shadow {
          padding-top: 7em;
          padding-bottom: 1em;

          .for-children {
            @include svg-image("for-children-transparent", 18, 22);
            right: 10px;
            top: 10px;
          }

          .like-recommends + .for-children {
            right: 140px;
            top: 5px;
          }

          .type-and-date {
            font-size: 1em;

            .type {
              .icon {
                width: 18px;
                height: 18px;
              }
            }

            .date {
              font-size: 1.2em;
            }
          }

          .name {
            font-size: 2.2em;
          }

          .r-v-l {
            margin-bottom: 1em;
          }
          .meta {
            .location-component {
              .ct-text {
                font-size: 1.25em;
              }
            }
          }
        }
      }

      //Видео
      &.video {
        margin-left: 0;
        margin-right: 0;

        .shadow {
          padding-left: 3em;
          padding-right: 3em;

          .left {
            .text {
              font-size: 1.6em;
              margin-top: 1em;
              margin-bottom: .7em;
            }
          }

          .play {
            .icon {
              @include sprite-image("play-big", 117, 117);
            }
          }
        }
      }

      //Фото
      &.photo {
        .photo-title {
          font-size: 1.6em;
        }
      }

      //Новость
      &.article {
        a.image {
          width: 40%;
        }

        .text {
          width: 60%;
          font-size: 1.2em;

          a.title {
            font-size: 1.2em;
          }

          .date {
            font-size: .7em;
          }
        }
      }
    }
  }

  //Элементы обзора
  .items-block {
    //Позиция
    article {
      position: relative;
      margin: 0 0 1em 0;

      .name {
        font-size: 1.4em;
        margin: 0 0 .4em 0;

        a {
          display: inline-block;
          color: inherit;
        }
      }

      .meta {
        @extend .clearfix;
        color: #666;

        > * {
          min-height: 1.4rem;
          margin: 0 1.1rem .5rem 0;
          float: left;
          box-sizing: border-box;
        }

        .views {
          @extend .clearfix;

          .icon {
            float: left;
            @include svg-image("views-grey", 19, 12);
            margin: .2em .5em 0 0;
          }

          .num {
            float: left;
          }
        }

        .tags {
          .tag {
            display: inline-block;
            margin: 0 .2em 0 0;
            color: inherit;

            &::before {
              content: "#";
            }
          }
        }
      }

      //Событие с иконкой
      &.event-with-icon {
        padding-left: 35px;
        min-height: 40px;

        .event-icon {
          position: absolute;
          left: 0;
          top: 3px;
        }
      }

      //Заведение
      &.place {
        .place-type {
          @extend .clearfix;
          margin: 0 0 .5em 0;

          .place-type-icon {
            float: left;
            margin: 0 .4em 0 0;
            width: 18px;
            height: 18px;
          }

          .place-type-text {
            float: left;

            a {
              color: inherit;
            }
          }
        }
      }

      //Видео альбом
      &.movie {
        padding-left: 40px;
        min-height: 40px;

        .play-icon {
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 3px;
          @include svg-image("play-button", 32, 32);
        }
      }
    }

    @media #{$xlarge-up} {
      article {
        &.event-with-icon {
          padding-left: 45px;
          min-height: 45px;

          .event-icon {
            width: 35px;
            height: 35px;
          }
        }

        &.movie {
          padding-left: 55px;
          min-height: 50px;

          .play-icon {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
