section.main.events.show {
  //Основное изображение
  .event-image {
    color: $white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 0 1rem 0;
    font-size: 1rem;

    //На мобайле у нас анимация при смене УРЛ в доскроле
    &.__animated {
      @extend .animated;
      @extend .zoomInUp;
    }

    .shadow {
      background: linear-gradient(to top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .3) 50%);
      padding-top: 2em;
    }

    .container {
      position: relative;
    }

    .ct-fi-open {
      right: 15px;
      bottom: 15px;
    }

    .date {
      text-transform: uppercase;
      font-size: .85em;
      margin: 0 0 .5em 0;
      color: $_font-color-transparent;

      a {
        color: inherit;
      }
    }

    .title {
      display: inline-block;
      margin: 0 0 .5em 0;
      font-size: 1.4em;
      color: $white;
    }

    .meta {
      font-size: .85rem;
      margin: 0 0 2em 0;

      .rating {
        margin: 0 1em 0 0;
        float: left;
      }

      .views {
        float: left;
        margin: 0 1em 0 0;
      }

      .go {
        float: left;
        color: $_font-color-transparent;
        margin: -.2em 0 0 0;
      }
    }
  }

  //Розыгрыш
  .lottery {
    margin-top: -1rem;
    margin-bottom: 1rem;
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    @extend .clearfix;
    padding: .5em 0 1em 0;

    .text {
      margin-left: 1em;
      float: left;
      width: 50%;
      box-sizing: border-box;
      padding-right: 1.5em;

      .title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.1em;
      }

      .description {
        font-size: .85em;
      }

    }

    .button {
      float: right;
      width: 33%;
      box-sizing: border-box;
      padding-right: 1em;
      padding-top: .7em;
      text-align: right;

      a {
        display: inline-block;
        color: $white;
        background: $black;
        padding: .6em .8em;
        font-size: .8em;
      }
    }
  }

  //Левая колонка
  .leftcol {
    margin: 0 0 1em 0;

    .main-text {
      color: $tuatara;

      .description {
        font-size: 1.3rem;
        margin: 0 0 1em 0;
      }

      .wysiwyg-component {
        font-size: 1.2rem;
        margin: 0 0 1em 0;
        position: relative;

        p, ul, li {
          font-size: 1.2rem;
          color: $tuatara;
        }
      }
    }

    .video-report {
      background-size: cover;
      margin: 0 0 1.5em 0;
      color: $white;
      background-position: center center;

      .shadow {
        background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, .17) 100%);
        padding: 2em 2em 2em 2em;

        .left {
          float: left;
          width: 75%;
          box-sizing: border-box;
          padding: .3em 0 0 0;

          .text {
            margin: 0 0 .3em 0;
          }
        }

        .play {
          float: right;
          width: 25%;
          text-align: right;

          .icon {
            cursor: pointer;
            @include sprite-image("play-small", 50, 50);
          }
        }
      }
    }

    .photo-report {
      margin: 0 0 1.5em 0;
    }

    .social {
      margin: 0 0 1.5em 0;
    }
  }

  //Правая колонка
  .rightcol {
    margin: 0 0 3em 0;

    //Заведение
    .place {
      background: $jet;
      padding: 1em;
      color: $_font-color-transparent;
      margin: 0 0 1em 0;

      a.name {
        color: $_font-color-transparent;
        font-size: 1.4em;
        display: inline-block;
        margin: 0 0 .3em 0;
      }

      .type {
        margin: 0 0 .5em 0;
        position: relative;

        .icon {
          position: absolute;
          left: 0;
          top: 3px;
        }

        a {
          color: inherit;
          display: inherit;
        }

        .icon + a {
          margin-left: 20px;
        }
      }

      .location {
        margin: 0 0 .2em 0;
        @extend .clearfix;

        .icon {
          float: left;
          @include svg-image("location-transparent", 9, 15);
          margin: 0 11px 0 0;
        }

        .text {
          float: left;
          display: inline-block;

          a {
            color: inherit;
          }
        }
      }

      .address {
        margin: 0 0 .2em 20px;
      }

      .meta {
        margin: 1em 0 0 0;
      }
    }

    //Оценить
    .rate {
      .title {
        color: #a4a4a4;
        font-size: 1.2em;
        margin: 0 0 .5em 0;
      }

      margin: 0 0 2em 0;
    }

    .map {
      background-size: cover;
      height: 120px;
      margin: 0 0 1em 0;
    }
  }

  .events-tile {
    margin: 0 0 2em 0;

    a.more {
      display: inline-block;
      text-transform: uppercase;
      color: $_font-color-transparent-black;
      margin: 1em 0 .5em 15px;
    }
  }

  @media #{$medium-up} {
    .lottery {
      font-size: 1.4em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
  }

  @media #{$large-up} {
    .event-image {
      margin: 0 0 2em 0;
      font-size: 1.6em;

      //На десктопе при смене УРЛ в доскроле анимации нет
      &.__animated {
        animation-name: pluck;
      }

      .shadow {
        padding-top: 14em;
      }

      .ct-fi-open {
        top: -20px;
        bottom: auto;
      }

      .rating2-component {
        position: absolute;
        right: 10px;
        bottom: 30px;
      }

      .opinion-button-component {
        position: absolute;
        right: 10px;
        bottom: -20px;
      }

      $margin-right: 230px;

      .date {
        font-size: .65em;
        margin-right: $margin-right;
      }

      .title {
        margin-right: $margin-right;
      }

      .meta {
        margin-right: $margin-right;

        .go {
          font-size: 1.2em;
          margin-top: 0;
        }
      }
    }

    .lottery {
      font-size: 2em;
      margin-top: 0;
      padding-top: 1em;

      .text {
        .title {
          font-size: 1.5em;
        }
      }
    }

    .leftcol {
      float: left;
      width: 60%;
      margin-bottom: 4em;

      .main-text {
        .description {
          font-size: 2.2em;
        }

        .wysiwyg-component {
          font-size: 1.4rem;

          p, ul, li {
            font-size: 1.4rem;
          }
        }
      }

      .video-report {
        .shadow {
          padding-left: 3em;
          padding-right: 3em;

          .left {
            .text {
              font-size: 1.6em;
              margin-top: 1em;
              margin-bottom: .7em;
            }
          }

          .play {
            .icon {
              @include sprite-image("play-big", 117, 117);
            }
          }
        }
      }

      .social {
        margin-bottom: 3em;

        &::before {
          content: "Поделиться:";
          display: inline-block;
          float: left;
          text-transform: uppercase;
          color: $base;
          margin-right: 1em;
          font-size: 1.3rem;
          padding-top: .1em;
        }
      }

      .opinions-title {
        font-size: 1.54em;
        text-transform: uppercase;
      }

      .opinions-link {
        display: inline-block;
        margin-top: 1em;
        color: black;
        font-weight: bold;
        font-size: 1.23em;
        text-transform: uppercase;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -16px;
          width: 11px;
          height: 17px;
          background: image-url('svg-icons/arrow-right-thick-no-fill.svg') center center / cover;

        }
      }
    }

    .rightcol {
      float: right;
      width: 300px;

      .place {
        font-size: 1.2em;

        .type {
          .icon {
            top: 4px;
          }

          .icon + a {
            margin-left: 24px;
          }
        }

        .location {
          .icon {
            margin: 3px 13px 0 2px;
          }
        }

        .address {
          margin-left: 24px;
        }
      }

      .map {
        height: 300px;
        margin-bottom: 2em;
      }
    }

    .events-tile {
      margin-bottom: 4em;

      .container.no-pad {
        padding-left: 15px;
        padding-right: 15px;
      }

      a.more {
        font-size: 1.3em;
        margin-left: 5px;
      }
    }
  }

  @media #{$xlarge-up} {
    .event-image {
      $margin-right: 320px;

      .data, .title, .meta {
        margin-right: 320px;
      }

      .opinion-button-component {
        right: 60px;
      }
    }

    .rightcol {
      .place {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}

.modal-events-show-lottery {
  max-width: 700px;

  .modal-body {
    color: $black;
    background: $white;
    padding: 1.2em 1.4em 1.5em 1.4em;

    .step-1, .not-auth {
      .title {
        font-size: 1.3em;
        text-transform: uppercase;
        margin: 0 0 .8em 0;
      }

      p {
        font-size: 1.2em;
        margin-bottom: .7em;

        &.error {
          padding: .7em .5em;
          border: 1px solid red;
          display: none;
        }
      }
    }

    .not-auth {
      .auth-links {
        @extend .clearfix;
        margin: 0 0 .7em 0;

        a {
          float: left;
          margin-right: 10px;

          &.vk {
            @include svg-image("vk-color", 40, 28);
          }

          &.fb {
            @include svg-image("fb-color", 40, 28);
          }
        }
      }
    }

    .step-1 {
      .share {
        margin: .5em 0 1em 0;
      }

      .loader {
        display: none;
      }
    }

    .step-2 {
      text-align: center;
      margin-bottom: 1em;
      display: none;

      .icon-ok {
       @include svg-image("ok", 120, 120);
        margin: 0 0 1em 0;
      }

      .title {
        font-size: 1.3em;
        margin: 0 0 .7em 0;
      }

      .text-grey {
        color: #848484;
        margin: 0 0 1em 0;
        font-size: 1.2em;
      }

      .to-profile {
        font-size: 1.3rem;
        display: inline-block;
        text-transform: uppercase;
        padding-right: 20px;
        color: #848484;
        background-image: image-url("svg-icons/arrow-right-grey.svg");
        background-position: 100% 3px;
        background-repeat: no-repeat;
        background-size: 9px 18px;
      }
    }
  }

  @media #{$large-up} {
    .modal-body {
      .step-1, .not-auth {
        padding: 2em;
        font-size: 1.2em;

        .title {
          font-size: 1.5em;
        }
      }

      .step-1 {
        .share {
          margin-top: 1em;
        }
      }

      .step-2 {
        font-size: 1.2em;

        .icon-ok {
          @include svg-image("ok", 150, 150);
        }

        .title {
          font-size: 1.6em;
        }
      }
    }
  }
}
