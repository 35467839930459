.people-cards-component {
  .ct-pc {
    &-list {
      margin-left: -30px;
    }
    &-item {
      position: relative;
      margin-left: 30px;
      margin-bottom: 30px;
      padding-left: 144px;
      width: calc(100% / 2 - 30px);
      height: 120px;
      overflow: hidden;
      color: #000;
      float: left;

      @media #{$xlarge-up} {
        width: calc(100% / 3 - 30px);
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 144px;
        right: 0;
        height: 10px;
        // 241, 241, 241 - это $body-bg
        // rgba(241, 241, 241, 0.0001) в такой форме мы вынуждены указывать из-за бага в сафари
        // https://stackoverflow.com/questions/38391457/linear-gradient-to-transparent-bug-in-latest-safari#46165475
        background: linear-gradient(180deg, rgba(241, 241, 241, 0.0001), $body-bg);
      }

      &.__person-of-day {
        padding: 0 24px 30px;
        height: 270px;
        color: #fff;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        &:after {
          content: none;
        }

        .ct-pc-item-avatar {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          &:after {
            content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.5);
          }
        }
        .ct-pc-item-title {
          font-size: 21px;
          line-height: 25px;
          > span {
            margin-bottom: 12px;
          }
        }
      }
      &-label {
        position: absolute;
        top: 0;
        left: 24px;
        padding: 6px 14px 5px;
        background-color: #fff;
        text-align: center;
        color: #000;
        box-shadow: 0 3px 0 0 #D6D6D6;
        text-transform: uppercase;
      }

      &-avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        height: 120px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &-title {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.69px;
        color: inherit;
        > span {
          display: block;
          margin-bottom: 8px;
        }

        &:hover {
          color: inherit;
          text-decoration: none;

          > span {
            opacity: 0.8;
          }
        }
      }
      &-bio {
        font-weight: 200;
        font-size: 12px;
        color: inherit;
        letter-spacing: 0.64px;
        // @media #{$xxlarge-up} {
        //   font-size: 15px;
        //   line-height: 21px;
        // }
      }
    }
  }
}
