//Оценить
.rate-component {
  @include components-mixin;

  @mixin star-bordered {
    @include sprite-image("rate-component-star-contur-white-small", 17, 16);

    @media #{$large} {
      @include sprite-image("rate-component-star-contur-white-big", 23, 21);
    }
  }

  @mixin star-grey {
    background: image-url("components/rate/star-grey.svg") no-repeat;
    width: 23px;
    height: 23px;
  }

  .ct-stars {
    margin: 0 .3em 0 0;
    float: left;

    .ct-star {
      float: left;
      @include sprite-image("rate-component-star-white-small", 17, 16);
      margin: 0 4px 0 0;
    }

    &.__1 {
      .ct-star:nth-child(n + 2) {
        @include star-bordered;
      }
    }

    &.__2 {
      .ct-star:nth-child(n + 3) {
        @include star-bordered;
      }
    }

    &.__3 {
      .ct-star:nth-child(n + 4) {
        @include star-bordered;
      }
    }

    &.__4 {
      .ct-star:nth-child(n + 5) {
        @include star-bordered;
      }
    }
  }

  .ct-num {
    float: left;
    font-size: .85em;
    color: $white;
  }

  button[type=submit] {
    @include sprite-image("rate-component-submit-icon-small", 18, 18);
    background-color: transparent;
    border: none;
    float: left;
    cursor: pointer;
    margin-left: 3px;
    display: none;
    outline: none;
  }

  &.__transparent {
    .ct-stars, .ct-num {
      opacity: .8;
    }
  }

  &.__black {
    .ct-stars {
      .ct-star {
        width: 23px;
        height: 23px;
        margin-right: 10px;
        background: image-url("components/rate/star-black.svg") no-repeat;
        background-size: contain;
      }

      &.__1 {
        .ct-star:nth-child(n + 2) {
          @include star-grey;
        }
      }

      &.__2 {
        .ct-star:nth-child(n + 3) {
          @include star-grey;
        }
      }

      &.__3 {
        .ct-star:nth-child(n + 4) {
          @include star-grey;
        }
      }

      &.__4 {
        .ct-star:nth-child(n + 5) {
          @include star-grey;
        }
      }
    }

    .ct-num {
      color: #A5A5A5;
      font-size: 1em;
    }
  }

  //Если у компонента есть класс active, то пользователь может проголосовать
  //иначе просто отображается текущий рейтинг
  &.__active {
    .ct-stars {
      .ct-star {
        cursor: pointer;
      }
    }
  }

  @media #{$large-up} {
    .ct-stars {
      .ct-star {
        @include sprite-image("rate-component-star-white-big", 23, 21);
      }
    }

    button[type=submit] {
      @include sprite-image("rate-component-submit-icon-big", 25, 25);
      display: none;
    }
  }
}