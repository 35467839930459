//Селект-табы
.select-tabs-component {
  @include components-mixin;
  position: relative;
  text-transform: uppercase;
  margin: 0 0 .7em 0;
  @extend .clearfix;

  .ct-tabs-container {
    position: relative;
    @extend .clearfix;
  }

  .ct-title {
    font-size: 1.1rem;
    margin: 0 0 .5em 0;
  }

  .ct-item {
    cursor: pointer;

    a {
      color: inherit;
    }

    &.__active {
      display: inline-block;
      padding: .2em .7em .1em .7em;
      border: 1px solid $black;
      color: $black;
      position: relative;
      height: 2em;
      float: left;

      .ct-caret {
        position: absolute;
        top: .5em;
        right: -22px;
        display: inline-block;
        margin: 0 0 0 .4em;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top-color: $black;
      }
    }

    &.__all {
      display: block;
      float: right;
      margin: .3em 15px 0 0;
      font-size: .8em;
      color: $_font-color-transparent_black;
      padding-right: 14px;
      background-image: image-url("svg-icons/arrow-right-grey-light.svg");
      background-position: 100% 2px;
      background-repeat: no-repeat;
      background-size: 6px 11px;
    }

    &.__abs {
      display: none;
      position: absolute;
      left: 0;
      width: 13em;
      padding: .5em .5em 0 .5em;
      box-sizing: border-box;
      z-index: 2;
      background: $white;
      height: 2.5em;
      color: $black;

      &.__1 {
        top: 2em;
      }

      &.__2 {
        top: 4.5em;
      }

      &.__3 {
        top: 7em;
      }

      &.__4 {
        top: 9.5em;
      }
    }
  }

  //С отступом слева
  &.__left-pad {
    margin-left: 15px;
  }

  &.__opened {
    .ct-item {
      &.__abs {
        display: block;
      }
    }
  }

  &.__black {
    color: $white;

    .ct-item {
      &.__active {
        border-color: $white;
        color: $white;

        .ct-caret {
          border-top-color: $white;
        }
      }

      &.__all {
        color: $_font-color-transparent;
      }

      &.__abs {
        background: $black;
        color: $white;
      }
    }
  }

  @media #{$large-up} {
    margin-bottom: 1.2em;

    .ct-tabs-container {
      float: left;
    }

    .ct-title {
      display: block;
      font-size: 2rem;
      float: left;
      margin: 0 1.5em 0 0;
    }

    .ct-item {
      float: left !important;
      display: inline-block !important;
      border: none !important;
      border-bottom: 3px solid transparent !important;
      padding: 0 0 .2em 0 !important;
      height: auto !important;
      margin: .2em 1.2em 0 .5em !important;
      position: static !important;
      width: auto !important;
      font-size: 1.4rem !important;
      color: #999 !important;
      background-color: transparent !important;

      &.__all {
        background-position: 100% 2px;
        background-size: 9px 18px;
        padding-right: 20px !important;
      }

      a {
        text-decoration: none;
      }

      &:hover, &.__active {
        border-bottom-color: #999 !important;
      }

      .ct-caret {
        display: none !important;
      }
    }

    &.__text-center {
      text-align: center;

      .ct-tabs-container {
        float: none;
      }

      .ct-item {
        float: none !important;
        margin-left: .7em !important;
        margin-right: .7em !important;
      }
    }

    &.__black {
      .ct-item {
        color: $white !important;

        &:hover, &.__active {
          border-bottom-color: $white !important;
        }
      }
    }
  }

  @media #{$xlarge-up} {
    .ct-title {
      font-size: 2.6rem;
    }

    .ct-item {
      margin-top: .4em !important;
    }
  }
}