//Стилизуем модальные окна reveal foundation
.reveal-modal {
  color: $white;
  padding-top: 50px;
  outline: none;
  max-width: 900px;

  &.reveal-fixed {
    //У reveal изначально окно позиционируется абсолютно, но мы решили позиционировать
    //относительно окна, а не относительно документа
    position: fixed;
    top: 0px !important;
  }


  .close-reveal-modal {
    @include sprite-image("modal-close-small", 25, 25);
  }

  @media #{$large-up} {
    padding-top: 80px;

    &.reveal-fixed {
      top: 30px !important;
    }

    .close-reveal-modal {
      @include sprite-image("modal-close-big", 50, 50);
    }
  }
}

//Окно с базовой формой
.reveal-modal-base-form {
  .error-response, .success-response {
    display: none;
    max-width: 300px;
    margin: 0 auto 0 auto;
  }

  .base-form {
    margin: 0 auto 0 auto;
    max-width: 300px;

    .main-title {
      text-transform: uppercase;
      font-size: 2rem;
      margin: 0 0 1em 0;
    }

    .subtitle {
      font-size: 1.3em;
    }

    ul.list-items {
      li {
        margin: 0 0 .7em 0;

        a {
          color: $white;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      &.big {
        font-size: 1.3em;
      }
    }

    .form-group {
      margin: 0 0 2em 0;
      @extend .clearfix;
      position: relative;

      .col {
        width: 48.5%;
        float: left;

        &.last {
          float: right;
        }
      }

      ul.dropdown {
        position: absolute;
        list-style: none;
        background: $white;
        left: 0;
        width: 100%;
        color: $black;
        margin: 0;
        padding: 0;
        z-index: 3;
        margin-top: -.4em;
        box-shadow: 0 0 2px 2px rgba($black, .7);
        max-height: 250px;
        overflow: auto;
        display: none;

        &.visible {
          display: block;
        }

        li {
          margin: 0;
          padding: .5em 1em;
          font-size: 1.1em;

          &:hover {
            cursor: pointer;
            background: #d4d4d4;
          }
        }
      }

      .form-control {
        display: block;
        box-sizing: border-box;
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid $white;
        outline: none;
        font-family: $font-family-sans-serif;
        font-size: 1.1rem;
        padding: .3em 0;
        margin: 0 0 .4em 0;
        border-radius: 0;

        &.like-select {
          background: transparent image-url("svg-icons/caret-down-white.svg") no-repeat 97% .7em;
          background-size: 14px 7px;
          border: 1px solid $white;
          padding: .5em 1em;
          cursor: pointer;
        }
      }

      .field-title {
        text-transform: uppercase;
        margin: 0 0 .3em 0;
        font-size: 1em;
      }

      select.form-control {
        -moz-appearance: none;
        -webkit-appearance: none;
        background: transparent image-url("svg-icons/caret-down-white.svg") no-repeat 97% .7em;
        background-size: 14px 7px;
        border: 1px solid $white;
        padding: .5em 1em;
        cursor: pointer;

        &::-ms-expand {
          display: none;
        }

        option {
          color: $black;
        }
      }

      textarea.form-control {
        height: 6em;
        padding: .5em .8em;
        border: 1px solid $white;
      }

      .help-block {
        font-size: 1rem;
        text-transform: uppercase;
      }

      label.file-label {
        display: block;
        background: $white;
        padding: .6em .8em .6em .8em;
        cursor: pointer;

        em {
          color: $black;
          font-style: normal;
          font-size: 1rem;
          text-transform: uppercase;
          @include text-ellipsis;
        }

        input[type=file] {
          display: none;
        }
      }

      small.error {
        display: none;
        font-size: .9em;
        margin: 0 0 .3em 0;
        color: red;
      }

      &.error {
       small.error {
         display: block;

       }
      }
    }

    button[type=submit] {
      display: inline-block;
      color: $black;
      font-family: $font-family-sans-serif;
      text-transform: uppercase;
      background: #FFE25F;
      padding: .5em 2em;
      font-size: 1.3rem;
      margin: 1em 0 0 0;
      border: none;
      outline: none;

      &:hover {
        text-decoration: none;
        background: darken(#FFE25F, 20%);
      }

      &:disabled {
        opacity: .7;

        &:hover {
          background: #FFE25F;
        }
      }

      &.full-size {
        width: 100%;
        display: block;
        text-align: center;
      }
    }

    //Форма авторизации
    &.auth-form {
      .social-heading {
        text-transform: uppercase;
        font-size: 2em;
        text-align: center;
        margin: 2em 0 .7em 0;
      }

      .social {
        margin: 0 auto 3em auto;
        width: 250px;

        a {
          display: block;
          margin: 0 0 .7em 0;
          background-repeat: no-repeat;
          color: $white;
          text-transform: uppercase;
          padding: .8em 0 .8em 60px;
          font-size: 1.3em;
          border-radius: 2px;


          &.fb {
            background-image: image-url("svg-icons/fb-white.svg");
            background-size: 15px 29px;
            background-position: 25px 9px;
            background-color: #46649F;
          }

          &.vk {
            background-image: image-url("svg-icons/vk-white.svg");
            background-size: 30px 18px;
            background-position: 17px 16px;
            background-color: #577A9E;
          }

          &.od {
            background-image: image-url("svg-icons/od-white.svg");
            background-size: 18px 30px;
            background-position: 22px 10px;
            background-color: #f48221;
          }
        }
      }
    }
  }
}

//Окно с выбором города
#modal-choose-city {
  padding-top: 10px;

  .dropdown-container {
    max-width: 320px;
    margin: 0 auto 0 auto;

    .title {
      text-transform: uppercase;
      font-size: 1.7rem;
      margin: 0 0 1em 0;
    }

    form.dropdown {
      position: relative;

      .trigger {
        position: relative;
        padding: .3em 15px .3em 30px;
        border: 1px solid $white;
        cursor: pointer;
        font-size: 1.2rem;

        .icon {
          position: absolute;
          left: .6em;
          top: .45em;
          @include sprite-image("location-big", 10, 16);
        }

        .caret {
          position: absolute;
          right: .8em;
          top: .8em;
          width: 0;
          height: 0;
          border: 6px solid transparent;
          border-top-color: $white;
        }
      }

      ul.dropdown-list {
        display: none;
        position: absolute;
        list-style: none;
        background: #5A5A5A;
        left: 0;
        width: 100%;
        padding: 1em 0 1em 0;
        margin: 0;
        max-height: 120px;
        overflow-y: scroll;

        li {
          margin: 0;
          padding: .5em 1em;
          color: $white;
          font-size: 1.2rem;

          &:hover {
            cursor: pointer;
            background: #333;
          }
        }
      }

      &.opened {
        .trigger {
          .caret {
            border-top-color: transparent;
            border-bottom-color: $white;
            top: .4em;
          }
        }

        ul {
          display: block;
        }
      }

      button[type=submit] {
        display: inline-block;
        color: $black;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        background: #FFE25F;
        padding: .5em 1.4em;
        font-size: 1.2rem;
        margin: 1em 0 0 0;
        border: none;
        outline: none;

        &:hover {
          text-decoration: none;
          background: darken(#FFE25F, 20%);
        }
      }
    }
  }

  @media #{$large-up} {
    padding-top: 50px;

    .dropdown-container {
      form.dropdown {
        ul.dropdown-list {
          max-height: 300px;
        }
      }
    }
  }
}

// Модаьное окно с текстом
.reveal-modal-info {
  padding-top: 25px;
  font-size: .8em;
  .create-descr-info {
    margin: 0 auto;
    max-width: 750px;
    text-align: center;
    .title {
      text-transform: uppercase;
      font-size: 1.5em;
      margin-bottom: 10px;
    }
    .name-object {
      font-size: 2.7em;
      margin-bottom: 50px;
    }
    .text {
      text-align: left;
      font-size: 1.7em;
      margin-bottom: 50px;
    }
    a.all {
      display: inline-block;
      position: relative;
      color: white;
      font-size: 1.5em;
      text-transform: uppercase;
      padding: 10px 60px;
      border-style: solid;
      border-width: 1px 0;
      &:after {
        content: '';
        @include svg-image("arrow-right-white", 15, 22);
        position: absolute;
        top: 14px;
        right: 10px;
      }
      &:hover {
        text-decoration: none;
      }
      .logo {
        @include svg-image("logo-white", 67, 24);
        position: relative;
        top: 4px;
        left: 4px;
      }
    }
  }
  @media #{$large-up} {
    font-size: 1em;
  }

}

#modal-profession-participate {
  .title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 1em;
  }
  .base-form {
    textarea {
      background-color: transparent;
      border: none;
      resize: none;
      outline: 0;
      border-bottom: 1px solid white;
      width: 100%;
      min-height: 100px;
      font-size: 1.2em;
    }
  }
}

.ui-datepicker {
  z-index: 9999 !important;
}
