section.main.films.index {
  //Основной фильм
  .main-movie {
    background: linear-gradient(to right, #AD302B, #49435C);
    margin: 0 0 1.3em 0;

    .trailer-button {
      display: inline-block;
      color: $black !important;
      background: $white;
      padding: .8em .6em;
      box-sizing: border-box;
      text-align: center;
      font-size: .7em;
      text-transform: uppercase;

      .text {
        display: inline-block;
        position: relative;

        .caret {
          position: absolute;
          left: -10px;
          top: 0;
          @include caret($black, 5, right);
        }
      }
    }

    .movie-description {
      margin: 1px 0 0 0;
      display: inline-block;
      color: $black !important;
      background: rgba($white, .6);
      padding: .8em .6em;
      box-sizing: border-box;
      text-align: center;
      font-size: .7em;
      text-transform: uppercase;
    }

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    .leftcol {
      float: left;
      width: 25%;

      .img {
        display: block;

        .poster {
          width: 100%;
        }

        .default {
          display: block;
          background-size: cover;
          background-image: image-url("svg-icons/films-default-bg.svg");
          background-position: center center;

          img {
            width: 100%;
          }
        }
      }

      .trailer-button {
        display: block;
      }

      .movie-description {
        display: block;
      }
    }

    .rightcol {
      position: relative;
      float: left;
      width: 75%;
      box-sizing: border-box;
      padding: 18px 15px 10px 15px;
      color: $white;

      a {
        color: $white;
      }

      .like-recommends {
        position: absolute;
        right: 0;
        top: 0;
      }

      .cinema {
        text-transform: uppercase;
        font-size: .9em;
      }

      .location {
        opacity: .7;
        margin: 0 0 1em 0;
        font-size: .8em;
      }

      .name {
        font-size: 1.2em;
        margin: 0 0 .7em 0;

        a {
          display: inline-block;
        }
      }

      .meta {
        @extend .clearfix;
        margin: 0 0 .8em 0;
        font-size: .9em;

        em {
          opacity: .7;
          font-style: normal;
          display: block;
          float: left;
          margin: 0 1em .3em 0;
        }

        .genre {
          opacity: .7;
          @include text-ellipsis;

        }
      }

      .sessions {
        @extend .clearfix;

        .item {
          float: left;
          margin: 0 1em 1em 0;
          font-size: .7em;

          .time {
            display: inline-block;
            padding: .3em .5em;
            border: 1px solid $white;
            margin: 0 0 .2em 0;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }

          .price {
            font-size: .9em;
          }
        }
      }
    }
  }

  //Заголовок
  .title {
    text-transform: uppercase;
    font-size: 1.5em;
    margin: 0 0 .8em 0;

    a {
      display: inline-block;
      font-size: .8em;
      margin: 0 0 0 1em;
      color: #9f9f9f;
    }
  }

  @media #{$large-up} {
    //Основной фильм
    .main-movie {
      margin-bottom: 6em;
      padding-top: 90px;

      .leftcol {
        width: 35%;

        .img {
          padding: 20px 0 0 20px;

          .poster, .default {
            box-shadow: 0 0 10px 10px rgba($black, .2);
            position: relative;
            top: 20px;
          }
        }
      }

      .rightcol {
        width: 65%;
        padding: 2em 2em 2em 2em;
        font-size: 1.5em;

        .like-recommends {
          position: static;
          margin: 0 0 1.5em 0;
        }

        .cinema {
          float: left;
          margin-right: 1.5em;
        }

        .location {
          float: left;
          font-size: .7em;
          opacity: .4;
        }

        .name {
          font-size: 1.7em;
        }

        .buttons {
          margin: 0 1.5em .5em 0;
          float: left;

          .trailer-button {
            float: left;
            width: 9em;
            font-size: .6em;
            margin-right: 1px;

            .text {
              .caret {
                top: 2px;
              }
            }
          }

          .movie-description {
            font-size: .6em;
            width: 9em;
            float: left;
            margin-top: 0;
          }
        }

        .meta {
          float: left;
          opacity: .4;
          margin: .3em 0 1.5em 0;

          em {
            font-size: .85em;
            margin-right: 2em;
            margin-bottom: 1em;
          }

          .genre {
            font-size: .85em;
          }
        }

        .sessions {
          clear: both;
          font-size: 1.1em;

          .item {
            .time {
              font-size: 1.1em;
              padding-left: 1.2em;
              padding-right: 1.2em;
            }
          }
        }
      }
    }

    .title {
      font-size: 2.4em;
    }
  }

  @media #{$xlarge-up} {
    //Основной фильм
    .main-movie {
      .leftcol {
        width: 27%;
      }

      .rightcol {
        width: 73%;
      }
    }
  }
}