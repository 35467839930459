//Табы - классы исключительно для показа/сокрытия табов, без стилизации
.tabs-component-content {
  .ct-tab-content {
    display: none;

    //При первоначальной загрузке страницы показан первый таб
    //дальнейший показ/скрытие осуществляет js
    &:first-of-type {
      display: block;
    }
  }
}