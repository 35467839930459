section.main.all.index {
  margin: 2em 0 2em 0;
  transition: opacity .5s;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  //Позиции
  .items {
    border-top: 1px solid #9F9F9F;
    padding: .7em 0 0 0;
    //Пока первый запрос ушел и ответа еще нет сделаем распорку из блока
    min-height: 200px;

    article {
      border-bottom: 1px solid #9F9F9F;
      padding: 0 0 .7em 0;
      margin: 0 0 .7em 0;
      color: #808080;

      a {
        color: #808080;
      }

      .meta-top, .meta-bottom {
        @extend .clearfix;

        > * {
          display: block;
          float: left;
          min-height: 1.8rem;
          box-sizing: border-box;
          margin: 0 .9rem .4rem 0;
        }
      }

      .name {
        color: $black;
        font-size: 1.3em;
        margin: .2em 0 .7em 0;

        a {
          display: inline-block;
          color: $black;
        }

        &.with-play-button {
          position: relative;
          padding-right: 35px;
          min-height: 35px;

          .play-button {
            position: absolute;
            right: 0;
            top: 2px;
            cursor: pointer;
            @include svg-image("play-button", 25, 25);
          }
        }
      }

      .meta-top {
        .type {
          @extend .clearfix;

          .type-icon {
            float: left;
            width: 12px;
            height: 12px;
            opacity: .6;
            margin: .2em .3em 0 0;

            &.benefit-kupon, &.benefit-gift, &.benefit-discount {
              background-position: center center;
              background-size: contain;
            }

            &.benefit-kupon {
              background-image: image-url("svg-icons/benefit-kupon-grey.svg");
            }

            &.benefit-gift {
              background-image: image-url("svg-icons/benefit-gift-grey.svg");
            }

            &.benefit-discount {
              background-image: image-url("svg-icons/benefit-discount-grey.svg");
            }
          }

          .type-name {
            float: left;
          }
        }

        .date {
          color: $_font-color-transparent-black;
        }
      }

      .meta-bottom {
        .location {
          @extend .clearfix;

          .icon {
            float: left;
            @include svg-image("location-grey", 9, 13);
            margin: .1em .3em 0 0;
          }

          .text {
            float: left;
          }
        }

        .r-v-l {
          @extend .clearfix;

          .rating {
            @extend .clearfix;
            float: left;
            margin: 0 .5em 0 0;

            .star {
              float: left;
              margin: .3em .1em 0 0;
              @include svg-image("star-grey", 10, 10);
            }
          }

          .views {
            @extend .clearfix;
            float: left;
            margin: 0 .5em 0 0;

            .icon {
              margin: .2em .2em 0 0;
              float: left;
              @include svg-image("views-grey", 16, 10);
            }

            .num {
              float: left;
            }
          }

          .likes {
            @extend .clearfix;
            float: left;

            .icon {
              float: left;
              margin: .2em .2em 0 0;
              @include svg-image("likes-grey", 10, 10);
            }

            .num {
              float: left;
            }
          }
        }

        .tags {
          @extend .clearfix;

          .tag {
            float: left;
            display: inline-block;
            margin: 0 .4em 0 0;

            &::before{
              content: "#";
            }
          }
        }
      }
    }
  }

  //Показать еще
  .show-more {
    margin: .6em 0 0 0;
    color: $white;
    text-transform: uppercase;
    text-align: center;
    padding: .7em 1em;
    background: #6DCD4C;
    display: none;

    &.active {
      display: block;
    }
  }

  @media #{$large-up} {
    //Позиции
    .items {
      margin-left: auto;
      margin-right: auto;
      width: 550px;
      min-height: 350px;

      article {
        font-size: 1.3em;

        .name {
          &.with-play-button {
            min-height: 40px;

            .play-button {
              @include svg-image("play-button", 35, 35);
            }
          }
        }

        .meta-top, .meta-bottom {
          > * {
            margin-right: 1.2rem;
          }
        }

        .meta-top {
          .type {
            .type-icon {
              width: 16px;
              height: 16px;
            }
          }
        }

        .meta-bottom {
          .location {
            .icon {
              @include svg-image("location-grey", 11, 16);

            }
          }

          .r-v-l {
            .rating {
              .star {
                @include svg-image("star-grey", 14, 14);
              }
            }

            .views {
              .icon {
                margin-top: .3em;
                @include svg-image("views-grey", 19, 12);
              }
            }

            .likes {
              .icon {
                margin-top: .3em;
                @include svg-image("likes-grey", 13, 13);
              }
            }
          }
        }
      }
    }

    .show-more {
      margin-left: auto;
      margin-right: auto;
      width: 550px;
    }
  }
}