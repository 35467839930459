//Сайдбар - связанные новостия, заведения, персоны, баннер, тизерный блок
.sidebar-component {
  .ct-item {
    margin: 0 0 1em 0;

    //События, заведение
    &.ct-event, &.ct-place {
      color: $black;
      font-size: 1.1em;
      padding: 0 0 1em 0;
      border-bottom: 1px solid #d4d4d4;

      a {
        color: inherit;
        display: inline-block;
      }

      .ct-date {
        margin: 0 0 .6em 0;
      }

      .ct-name {
        font-size: 1.5em;
        margin: 0 0 .2em 0;
      }

      .ct-category {
        margin: 0 0 .5em 0;
        position: relative;
        padding-left: 22px;

        .ct-icon {
          position: absolute;
          left: 0;
          top: 0;
          width: 17px;
          height: 17px;
        }
      }

      .ct-location {
        position: relative;
        padding-left: 22px;
        margin: 0 0 .5em 0;

        .ct-icon {
          position: absolute;
          left: 3px;
          top: 2px;
          @include svg-image("location-black", 11, 17);
        }
      }

      .ct-meta {
        @extend .clearfix;

        > * {
          float: left;
          margin: 0 1em .1em 0;
          min-height: 1.5rem;
          box-sizing: border-box;
          display: inline-block;
        }

        .ct-rating {
          .ct-star {
            @include svg-image("star-black", 15, 15);
          }
        }

        .ct-views {
          position: relative;
          padding-left: 24px;

          .ct-icon {
            position: absolute;
            left: 0;
            top: .3em;
            @include svg-image("views-black", 19, 12);
          }
        }
      }
    }

    //Персона
    &.ct-person {
      @extend .clearfix;
      margin: 0 0 1em 0;

      .ct-avatar {
        float: left;
        width: 55px;
        height: 55px;
        background-size: cover;
        background-position: center center;
        background-image: image-url("svg-icons/ava-default.svg");
        border-radius: 50%;
      }

      .ct-text {
        margin-left: 70px;
        padding-top: .5em;

        .ct-name {
          color: $black;
          font-size: 1.2em;
          text-transform: uppercase;
        }
      }
    }

    &.ct-teaser {
      margin: 2em 0;
      .ct-teaser-item {
        position: relative;
        padding: 11px 13px 19px 127px;
        min-height: 90px;
        margin: 2em 0;
        .ct-link {
          &:hover, &:focus {
            text-decoration: none;
          }
        }
        .ct-text {
          color: $white;
          font-size: 1.2em;
          height: 100%;
          overflow: hidden;
        }
        .ct-image {
          position: absolute;
          left: 0;
          top: -10px;
          width: 110px;
          height: 110px;
          background-size: cover;
          box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.33);
        }
        &:hover {
          .ct-image {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
          }
        }
        .ct-label {
          background-color: $white;
          color: $black;
          font-size: 0.8em;
          position: absolute;
          left: 0;
          top: 0;
          text-transform: uppercase;
          padding: 3px 5px;
        }
      }
    }
  }

  @media #{$large-up} {
    float: right;
    width: 300px;
  }

}
