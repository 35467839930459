.url-control-component {
  $color-green: #6dcd4c;

  .ct-uc-top {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    background: white;
    box-shadow: 0 0 5px rgba(black, .3);

    span {
      display: block;
      padding: .5em 1em;
      box-sizing: border-box;
      max-width: 100%;
      font-weight: bold;
      @include text-elipsis;
    }

    em {
      position: absolute;
      top: 100%;
      left: 0;
      height: 3px;
      background: $color-green;
      width: 0;
    }
  }

  .ct-uc-item {
    max-height: 3000px;
    overflow: hidden;
    position: relative;

    .ct-uc-read-more {
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(white, 1) 0%, rgba(white, 1) 70%, rgba(white, .0));
      padding: 7em 0 3em 0;
      width: 100%;
      z-index: 5;

      span {
        text-align: center;
        cursor: pointer;
        display: block;
        background: $color-green;
        color: white;
        text-transform: uppercase;
        padding: 1.2em 1em;
      }
    }

    //&.__opened {
    //Пока Читать полностью отключили - сразу материал польнотьсю выводим
    max-height: none;

    .ct-uc-read-more {
      display: none;
    }
    //}
  }

  @media #{$large-up} {
    .ct-uc-top {
      font-size: 1.4em;
      box-shadow: 0 0 8px rgba(black, .3);

      em {
        height: 4px;
      }
    }

    .ct-uc-item {
      //max-height: 3800px;

      .ct-uc-read-more {
        padding: 14em 0 5em 0;

        span {
          float: left;
          width: 60%;
          transition: background-color .4s;

          &:hover {
            background: darken($color-green, 8%);
          }
        }
      }
    }
  }
}