//Фото
.photos-component {
  @include components-mixin;
  position: relative;
  padding-left: 15px;

  .ct-icon {
    position: absolute;
    left: 0;
    @include svg-image("news-photo", 14, 14);
  }

  .ct-num {
    color: $white;
    font-size: .7em;
  }

  &.__transparent {
    .ct-icon, .ct-num {
      opacity: .8;
    }
  }

  @mixin large() {
    padding-left: 24px;

    .ct-icon {
      @include svg-image("news-photo", 19, 19);
    }

    .ct-num {
      font-size: 1em;
    }
  }

  //Одно размера на всех экранах
  &.__one-size {
    @include large();
  }

  @media #{$large-up} {
    @include large();
  }
}