//Блок с видео
.video-component {
  @include components-mixin;

  .ct-info {
    .ct-text {
      margin: 0 0 .9em 0;
      color: $_font-color-transparent;

      .ct-name {
        text-transform: uppercase;
        display: inline-block;
        margin-right: 1em;
      }
    }

    .ct-meta {
      .ct-likes {
        float: left;
        margin: 0 1em 0 0;
      }

      .ct-social {
        padding-top: .2em;
        float: left;
      }
    }
  }

  @media #{$large-up} {
    .ct-info {
      .ct-text {
        font-size: 1.2em;
      }

      .ct-meta {
        .ct-social {
          padding-top: 0;
        }
      }
    }
  }
}