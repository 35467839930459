//Наша собственная расшаривалка
.share-component:not(.__unstyled) {
  margin: 0 0 1.5em 0;

  .ct-link {
    float: left;
    margin: 0 10px 0 0;
    display: inline-block;
    width: 38px;
    height: 25px;
    background-size: 100% 100%;

    &.__vk {
      background-image: image-url("components/share/vk-color.svg");
    }

    &.__fb {
      background-image: image-url("components/share/fb-color.svg");
    }
  }

  @media #{$large-up} {
    margin-bottom: 3em;
  }
}

//Баннер-приглашение специально вынесен отдельно.
//У нас компонент может быть в составе других компонентов
//Для этого приходится деражть баннер в html отдельно (это делает JS)
.share-component-invite {
  background: $white;
  position: fixed;
  left: 0;
  top: 50%;
  padding: 0;
  width: 0;
  height: 220px;
  box-sizing: border-box;
  margin: -110px 0 0 0;
  z-index: 10;
  transition: all .5s;
  overflow: hidden;

  &.__visible {
    width: 240px;
    padding: 20px;
    box-shadow: 0 0 10px 10px rgba($black, .1);
  }

  .ct-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
    background-size: 100% 100%;
    background-image: image-url("components/share/cross-black.svg");
    cursor: pointer;
  }

  .ct-text {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    width: 200px;
  }

  .ct-group-link {
    width: 200px;
    color: $white;
    font-size: 1.3em;
    text-align: center;
    padding: .4em 1em;
    display: block;

    &:hover {
      text-decoration: none;
    }

    &.__vk {
      background: #567c9d;

      .ct-icon {
        display: inline-block;
        width: 19px;
        height: 11px;
        position: relative;
        left: -3px;
        top: -1px;
        background-size: 100% 100%;
        background-image: image-url("components/share/vk-white.svg");
      }
    }

    &.__fb {
      background: #3d5b93;
    }
  }
}