.opinion-component {
  @include components-mixin;
  $component-padding: 20px;
  background: white;
  padding: $component-padding;
  margin: 0 auto 1em auto;
  max-width: 540px;
  border: 1px solid #c5c5c5;
  &.__not-centered {
    margin-left: 0;
    margin-right: 0; }
  &.__all {
    height: 150px;
    position: relative;
    display: block;
    span {
      color: black;
      display: block;
      width: 290px;
      height: 3em;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.38em;
      font-weight: bold;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -1em 0 0 -145px;
      i {
        font-style: normal; }
      svg {
        width: 11px;
        height: 18px;
        position: relative;
        left: .5em;
        top: .1em; } } }
  &.__disabled {
    .ct-on-author,
    .ct-on-text {
      opacity: .4; } }
  .ct-on-item {
    display: block;
    background: #CDCDCD;
    position: relative;
    padding: .9em 3.1em .9em 3.1em;
    margin: -($component-padding);
    margin-bottom: .7em;
    color: black;
    .ct-on-item-cat {
      position: absolute;
      left: .9em;
      top: .7em;
      width: 1.38em;
      height: 1.38em; }
    .ct-on-item-arrow {
      position: absolute;
      right: .9em;
      top: .7em;
      width: .85em;
      height: 1.38em; }
    .ct-on-item-name {
      font-size: 1.08em;
      font-weight: bold; } }
  .ct-on-author {
    @extend .clearfix;
    margin-bottom: 1em;
    display: block;
    color: inherit;
    .ct-on-author-avatar {
      float: left;
      width: 2.7em;
      height: 2.7em;
      background: top center / cover image-url('main/ava-default.svg');
      border-radius: 50%;
      display: block; }
    .ct-on-author-stars {
      float: right;
      @extend .clearfix;
      small {
        display: inline-block;
        float: left;
        margin-right: .3em;
        font-size: 1.08em;
        font-weight: bold; }
      img {
        float: left;
        width: 10px;
        height: 10px; } }
    .ct-on-author-name {
      margin: .2em 1.5rem 0 3.5em;
      font-size: 1.08em;
      font-weight: bold;
      display: block;
      color: inherit; }
    time {
      display: block;
      margin-left: 3.8rem;
      margin-right: 1.5rem;
      color: grey;
      font-size: .77em;
      font-weight: bold; } }
  .ct-on-text {
    margin-bottom: 2em;
    color: inherit;
    display: block;
    font-size: 1.23em; }
  .ct-on-media {
    margin-bottom: .7em;
    iframe, video, img {
      max-width: 100%;
      margin-bottom: .6em; }
    .ct-on-media-all-photo {
      text-align: center;
      color: black;
      text-transform: uppercase;
      margin-bottom: .7em;
      cursor: pointer; } }
  .ct-on-controls {
    margin-left: -($component-padding);
    margin-right: -($component-padding);
    padding: 10px $component-padding;
    border-top: 1px solid #EDEDED;
    @extend .clearfix;
    .ct-on-controls-badge {
      float: left;
      background: #85F093;
      border-radius: 1.5em;
      padding: .4em .6em .4em 20px;
      position: relative;
      top: .2em;
      .ct-on-badge-icon {
        position: absolute;
        left: 5px;
        top: 3px;
        display: inline-block;
        width: 12px;
        height: 12px; }
      .ct-on-badge-text {
        text-transform: uppercase;
        font-size: .63em; } }
    .ct-on-controls-item {
      cursor: pointer;
      float: left;
      margin-right: 1.5em;
      @extend .clearfix;
      &.__clicked {
        filter: brightness(-30%); }
      .ct-on-controls-icon {
        width: 23px;
        height: 19px;
        float: left;
        margin: 0 .5em 0 0;
        &.__dislike {
          fill: grey;
          position: relative;
          top: .2em; } }
      .ct-on-controls-text {
        float: left;
        font-size: 1.08em;
        font-weight: bold;
        color: grey;
        position: relative;
        top: .3em; }
      &.__share {
        float: right;
        margin-right: 0;
        margin-left: 1em;
        .ct-on-controls-icon {
          width: 20px;
          height: 22px;
          &.__grey {
            display: block; }
          &.__black {
            display: none; } }
        &.__active {
          .ct-on-controls-icon {
            &.__grey {
              display: none; }
            &.__black {
              display: block; } } } } } }
  .share-component {
    border-top: 1px solid #EDEDED;
    text-align: center;
    padding-top: 1em;
    margin-left: -($component-padding);
    margin-right: -($component-padding);
    display: none;
    .ct-link {
      display: inline-block;
      margin: 0 1em;
      cursor: pointer; } } }
