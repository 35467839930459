section.main.films.movie {
  //Основной фильм
  .main-movie {
    background: linear-gradient(135deg, #C27329, #7E20A8);

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    .trailer-button {
      display: inline-block;
      color: $black !important;
      background: $white;
      padding: .8em .6em;
      box-sizing: border-box;
      text-align: center;
      font-size: .7em;
      text-transform: uppercase;

      .text {
        display: inline-block;
        position: relative;

        .caret {
          position: absolute;
          left: -10px;
          top: 0;
          @include caret($black, 5, right);
        }
      }
    }

    .movie-description {
      margin: 1px 0 0 0;
      display: block;
      color: $black !important;
      background: rgba($white, .6);
      padding: .8em .6em;
      box-sizing: border-box;
      text-align: center;
      font-size: .7em;
      text-transform: uppercase;
    }

    .leftcol {
      float: left;
      width: 25%;

      .img {
        display: block;

        .poster {
          width: 100%;
        }

        .default {
          display: block;
          background-size: cover;
          background-image: image-url("svg-icons/films-default-bg.svg");
          background-position: center center;

          img {
            width: 100%;
          }
        }
      }

      .trailer-button {
        display: block;
      }
    }

    .rightcol {
      position: relative;
      float: left;
      width: 75%;
      box-sizing: border-box;
      padding: 0 15px 10px 15px;
      color: $white;

      a {
        color: $white;
      }

      .options {
        @extend .clearfix;
        margin: 0 -15px 1em 0;

        .like-recommends {
          float: right;
          height: 20px;
        }

        .item {
          display: inline-block;
          float: right;

          &.text {
            color: $black;
            background: $white;
            padding: .3em .8em;
            height: 20px;
            font-size: .8em;
          }

          &.for-children {
            @include svg-image("for-children-transparent", 11, 14);
            margin: 5px 10px 0 0;
          }
        }
      }

      .name {
        font-size: 1.2em;
        margin: 0 0 .7em 0;

        a {
          display: inline-block;
        }
      }

      .meta {
        @extend .clearfix;
        margin: 0 0 .8em 0;
        font-size: .9em;

        em {
          opacity: .7;
          font-style: normal;
          display: inline-block;
          float: left;
          margin: 0 .8em .5em 0;

          small {
            display: none;
          }
        }

        .genre {
          clear: both;
          opacity: .7;
          margin: 0 0 .5em 0;
        }
      }

      .rate {
        margin: 0 0 1em 0;

        .rate-title {
          display: none;
        }
      }

      .team {
        .team-title {
          @extend .clearfix;
          cursor: pointer;

          .text {
            float: left;
            text-transform: uppercase;
            font-size: .85em;
          }

          .caret {
            float: left;
            @include svg-image("arrow-right-white", 10, 10);
            margin: 3px 0 0 5px;
          }
        }

        .cols {
          display: none;
          padding: .5em 0 0 0;

          .col {
            float: left;
            width: 50%;
            font-size: .85em;
            margin: 0 0 .7em 0;

            .col-title {
              text-transform: uppercase;
              margin: 0 0 .4em 0;
            }

            .col-item {
              margin: 0 0 .4em 0;
            }

            .more {
              .more-trigger {
                @extend .clearfix;
                border-top: 1px solid $white;
                text-transform: uppercase;
                cursor: pointer;
                margin: .7em 0 .5em 0;
                font-size: .9em;
                padding: 3px 0 0 0;
                max-width: 120px;

                .text {
                  float: left;
                }

                .caret {
                  @include caret($white, 6, down);
                  float: right;
                }
              }

              .more-content {
                display: none;
              }

              &.active {
                .more-trigger {
                  .caret {
                    @include caret($white, 6, up);
                    position: relative;
                    top: -4px;
                  }
                }

                .more-content {
                  display: block;
                }
              }
            }
          }
        }

        &.active {
          .team-title {
            .caret {
              transform: rotate(90deg);
            }
          }

          .cols {
            display: block;
          }
        }
      }
    }
  }

  //Кинотеатры
  .cinemas {
    margin: 3em 0 2em 0;

    article {
      margin: 0 0 1.5em 0;
      position: relative;

      .like-recommends {
        position: absolute;
        right: 0;
        bottom: 100%;
        margin: 0 0 1px 0;
      }

      .name {
        display: block;
        font-size: 1.5em;
        color: $white;
        background: $black;
        padding: .2em 20px .3em 10px;
        margin: 0 0 .5em 0;

        .caret {
          position: absolute;
          top: 10px;
          right: 10px;
          @include svg-image("arrow-right-white", 10, 18);
        }
      }

      .location {
        margin: 0 0 .8em 0;

        .ct-text {
          em {
            display: inline-block;
            font-style: normal;
            margin: 0 .5em 0 0;
          }
        }
      }

      .sessions {
        @extend .clearfix;
        padding: 1.5em 0 0 0;

        .item {
          float: left;
          margin: 0 1em 1em 0;

          .time {
            display: inline-block;
            padding: .3em .9em;
            border: 1px solid $black;
            margin: 0 0 .2em 0;
            color: $black;
            font-size: 1.2em;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .movies-tile-title {
    text-transform: uppercase;
    font-size: 1.5em;
    margin: 0 0 .8em 0;
  }

  @media #{$large-up} {
    //Основной фильм
    .main-movie {
      padding-top: 140px;
      padding-bottom: 50px;

      .container {
        padding-left: 15px;
        padding-right: 15px;
      }

      .rightcol {
        .options {
          .like-recommends {
            float: left;
            height: 30px;
          }

          .item {
            float: left;

            &.text {
              height: 30px;
              font-size: 1.2em;
            }

            &.for-children {
              @include svg-image("for-children-transparent", 18, 22);
              margin: 5px 0 0 15px;
            }
          }
        }

        .name {
          font-size: 2em;
          margin-bottom: 1.3em;
        }

        .buttons {
          margin: 0 0 3em 0;

          .trailer-button {
            float: left;
            width: 9em;
            font-size: .85em;
            margin-right: 1px;

            .text {
              .caret {
                top: 2px;
              }
            }
          }

          .movie-description {
            font-size: .85em;
            width: 9em;
            float: left;
            margin-top: 0;
          }
        }

        .meta {
          float: left;
          margin-right: 1.5em;
          font-size: 1.1em;

          em {
            margin-bottom: .2em;

            &.country {
              position: relative;
              top: -15px;

              small {
                height: 15px;
                display: block;
                font-size: .7em;
              }
            }
          }

          .genre {
            margin-bottom: 1em;
          }
        }

        .rate {
          float: left;
          position: relative;
          top: -15px;

          .rate-title {
            display: block;
            opacity: .7;
            height: 20px;
          }
        }

        .team {
          clear: both;
          width: 90%;

          .team-title {
            display: none;
          }

          .cols {
            display: block;

            .col {
              width: 25%;
              font-size: 1em;

              .more {
                .more-trigger {
                  .caret {
                    position: relative;
                    top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }

    //Кинотеатры
    .cinemas {
      margin-top: 5em;

      .container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
      }

      article {
        flex-basis: 30%;
        margin-right: 5%;
        margin-bottom: 2.5em;
        box-sizing: border-box;

        &.last {
          margin-right: 0;
        }

        &:first-of-type {
          //Чтобы в сафари на новую строчку не падал правый блок
          //баг с флексами в сафари
          margin-left: -1px;
        }

        &.last {
          margin-right: 0;
        }

        .name {
          padding-left: 15px;
        }
      }
    }

    .movies-tile-title {
      font-size: 2.4em;
    }
  }

  @media #{$xlarge-up} {
    //Основной фильм
    .main-movie {
      .rightcol {
        padding-left: 30px;

        .meta {
          em {
            margin-right: 1.5em;
          }
        }

        .team {
          width: 75%;
        }
      }
    }
  }
}