//Лайк рекомендует
.like-recommends-component {
  @include components-mixin;

  font-size: .65rem;
  background: #FF4B4B;
  color: $white !important;
  padding: .5em 1em;
  display: inline-block;

  &.__big {
    font-size: .85rem;
  }

  @media #{$large-up} {
    font-size: .9rem;

    &.__big {
      font-size: 1.5rem;
    }
  }
}