.landing-page {
  font-size: 1em;
  overflow: hidden;

  section {
    margin-bottom: 57px;
  }

  .container {
    position: relative;
  }

  .text {
    color: $black;
    font-size: 1.2em;
    margin-bottom: 30px;
  }

  .intro {
    position: relative;
    padding-top: 35px;
    background-image: image-url("landing/bg-intro-small.png");
    background-size: cover;
    background-position: 100% 100%;
    min-height: 492px;
    img {
      width: 100%;
    }

    .logo {
      @include svg-image('logo_landing', 133, 48);
      margin-bottom: 15px;
    }

    .text {
      position: relative;
      .scroll-down {
        cursor: pointer;
        @include svg-image('arrow-down-black', 26, 15);
        position: absolute;
        left: 5px;
        bottom: -30px;
      }
    }
    .macbook-fly {
      background-image: image-url("landing/macbook.png");
      width: 300px;
      height: 300px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 75%;
    }
  }

  .preview-films {
    position: relative;
    .posters-img {
      img {
        width: 100%;
        position: relative;
        z-index: 100;
      }
    }
    .bg-rect {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: image-url("landing/films-rect.png");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 10;
    }
  }

  .find-info {
    .tags-img {
      background-image: image-url("landing/tags.png");
      background-size: cover;
      background-position: center center;
      img {
        width: 100%;
      }
    }
  }

  .preview-articles {
    background-image: image-url("landing/bg-articles-small.png");
    background-size: cover;
    background-position: center center;
    .gallery {
      text-align: center;
      position: relative;
      padding: 0 15px;
      .gallery-list {
        padding-bottom: 30px;
      }
      img {
        width: 100%;
      }
      .cycle-pager {
        margin-top: 50px;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        span {
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #D8D8D8;
          opacity: 0.5;
          border-radius: 50%;
          margin-right: 5px;
          cursor: pointer;
          &:hover,
          &.cycle-pager-active {
            opacity: 1;
          }
        }
      }
      .prev,
      .next {
        position: absolute;
        cursor: pointer;
        top: 50%;
        margin-top: -15px;
      }
      .prev {
        left: -22px;
        .arrow {
          @include svg-image("arrow-left-black", 17, 26);
        }
      }
      .next {
        right: -22px;
        .arrow {
          @include svg-image("arrow-right-black", 17, 26);
        }
      }
    }
  }

  .mobile-app {
    margin-bottom: 0;
    .apps {
      text-align: center;
      margin-bottom: 30px;
      .apple, .google {
        display: inline-block;
        width: 135px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .apple {
        margin-right: 10px;
        background-image: image-url("svg-icons/app-store.svg");
      }
      .google {
        background-image: image-url("svg-icons/google-play.svg");
      }
    }
  }

  .invitation {
    text-align: center;
    background-image: image-url("landing/bg-invitation.png");
    background-size: 200% 100%;
    min-height: 492px;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 120px;
    margin-bottom: 0;
    .text {
      color: $white;
      font-size: 1.8em;
      padding: 0 15px;
    }
    .go-btn {
      display: inline-block;
      text-transform: uppercase;
      border: 2px solid $white;
      border-radius: 100px;
      font-size: 1.7em;
      color: $white;
      padding: 15px 40px;
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media #{$medium-up} {
    .text {
      text-align: center;
    }
    .intro {
      background-image: image-url("landing/bg-intro-large.png");
      min-height: 492px;
      padding-top: 100px;
      .text {
        width: 50%;
        text-align: left;
      }
      .macbook-fly {
        right: 0;
        top: -30px;
        width: 400px;
        height: 428px;
      }
    }
    .preview-films {
      margin-top: -165px
    }
    .preview-articles {
      margin-bottom: 114px;
      background-image: image-url("landing/bg-articles-large.png");
      .gallery {
        .gallery-list {
          padding-bottom: 50px;
        }
        .cycle-pager {
          span {
            width: 10px;
            height: 10px;
            margin-right: 10px;
          }
        }
      }
    }
    .invitation {
      background-size: cover;
      background-position: center;
      margin-top: -100px;
      padding-top: 155px;
    }
  }

  @media #{$large-up} {
    font-size: 1.5em;
    .text {
      margin-bottom: 45px;
    }
    .intro {
      .logo {
        width: 300px;
        height: 108px;
      }
      .macbook-fly {
        width: 700px;
        height: 750px;
      }
      .text {
        .scroll-down {
          width: 46px;
          height: 27px;
          bottom: -50px;
          left: 10px;
        }
      }
    }
    .preview-films {
      margin-top: -240px;
      margin-bottom: 114px;
    }
    .mobile-app {
      .apps {
        margin-bottom: 45px;
      }
    }
    .preview-articles {
      .gallery {
        .gallery-list {
          padding-bottom: 70px;
        }
        .cycle-pager {
          span {
            width: 14px;
            height: 14px;
          }
        }
        .prev .arrow,
        .next .arrow {
          width: 26px;
          height: 40px;
        }
        .prev {
          left: -30px;
        }
        .next {
          right: -30px;
        }
      }
    }
    .invitation {
      min-height: 680px;
      margin-top: -135px;
      padding-top: 230px;
      .text {
        font-size: 1.6em;
      }
      .go-btn {
        padding: 15px 50px;
      }
    }
  }

  @media #{$xlarge-up} {
    .text {
      margin-bottom: 60px;
    }
    .intro {
      padding-top: 200px;
      .logo {
        width: 455px;
        height: 164px;
      }
      .macbook-fly {
        width: 990px;
        height: 1070px;
      }
    }
    .preview-articles {
      .gallery {
        .cycle-pager {
          span {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    .mobile-app {
      .apps {
        margin-bottom: 60px;
        .apple, .google {
          width: 173px;
          height: 51px;
        }
        .apple {
          margin-right: 20px;
        }
      }
    }
    .invitation {
      min-height: 950px;
      margin-top: -170px;
    }
  }

  @media #{$xxlarge-up} {
    font-size: 2.5em;
    .container {
      max-width: 1300px;
    }
    .preview-articles {
      .gallery {
        .prev .arrow,
        .next .arrow {
          width: 27px;
          height: 46px;
        }
        .prev {
          left: -55px;
        }
        .next {
          right: -55px;
        }
      }
    }
    .mobile-app {
      .apps {
        .apple, .google {
          width: 277px;
          height: 82px;
        }
        .apple {
          margin-right: 40px;
        }
      }
    }
    .invitation {
      min-height: 1251px;
      background-size: cover;
      margin-top: -200px;
      padding-top: 400px;
      .go-btn {
        padding: 30px 110px;
      }
    }
  }
}
