//Сайт
.website-component {
  @include components-mixin;
  display: inline-block;
  position: relative;
  padding-left: 15px;

  .ct-icon {
    position: absolute;
    left: 0;
    top: .4em;
    @include sprite-image("website-component-icon-white-small", 10, 8);
  }

  .ct-text {
    color: $white;
    font-size: .85em;
  }

  &:hover {
    .ct-text {
      text-decoration: underline;
    }
  }

  &.__transparent {
    .ct-icon, .ct-text {
      opacity: .8;
    }
  }

  @media #{$large} {
    padding-left: 23px;

    .ct-icon {
      top: .5em;
      @include sprite-image("website-component-icon-white-big", 18, 14);
    }

    .ct-text {
      font-size: 1.3em;
    }
  }
}