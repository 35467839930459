section.main.profile {
  margin: 2em 0 0 0;

  //Левая колонка
  .leftcol {
    text-align: center;
    margin: 0 0 1.5em 0;

    .avatar {
      display: inline-block;
      width: 100px;
      height: 100px;
      background: center center no-repeat;
      background-size: cover;
      border-radius: 50%;
      position: relative;
      background-image: image-url("svg-icons/ava-default.svg");

      &[data-reveal-id] {
        cursor: pointer;
      }

      .icon {
        position: absolute;

        &.cameraman {
          @include svg-image("user-cameraman", 20, 20);
          bottom: 0px;
          left: 63px;
        }

        &.expert {
          @include svg-image("user-expert", 20, 20);
          bottom: -10px;
          left: 40px;
        }
      }
    }
  }

  //Правая колонка
  .rightcol {
    .page-title {
      text-transform: uppercase;
      font-size: 1.2em;
    }

    .user-name {
      text-transform: uppercase;
      color: #999999;
      font-size: .9em;
      margin: 0 0 .6em 0;
    }

    .menu {
      text-transform: uppercase;
      font-size: .9em;
      margin: 1.2em 0 1.5em 0;

      a {
        display: inline-block;
        color: $black;
        margin: 0 1em 0 0;

        &.active {
          border-bottom: 3px solid $black;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    //Комментарии
    .comments-items {
      .item {
        margin: 0 0 1.5em 0;
        padding: 0 0 .8em 0;
        border-bottom: 2px solid #cecece;

        .date {
          color: #9D9D9D;
          font-size: .9em;
          margin: 0 0 .2em 0;
        }

        .text {
          color: #434343;
          font-size: 1.2em;
          margin: 0 0 .7em 0;

          a {
            color: #434343;
            display: inline-block;
          }
        }

        .meta {
          .type {
            color: #818192;
            display: inline-block;
            padding: .3em .7em;
            text-transform: uppercase;
            font-size: .7em;
            background: #FFCFCF;
            margin: 0 .5em 0 0;

            &.blue {
              background: #D7CFFF;
            }

            &.green {
              background: #D6FFCF;
            }
          }

          .name {
            font-size: .9em;
            color: #9D9D9D;
          }
        }
      }
    }
  }

  @media #{$large-up} {
    //Левая колонка
    .leftcol {
      float: left;
      width: 150px;
      margin-right: 40px;

      .avatar {
        width: 150px;
        height: 150px;

        .icon {
          &.cameraman {
            @include svg-image("user-cameraman", 45, 45);
            bottom: 8px;
            left: 110px;
          }

          &.expert {
            @include svg-image("user-expert", 45, 45);
            bottom: -20px;
            left: 55px;
          }
        }
      }
    }

    //Правая колонка
    .rightcol {
      float: left;
      width: 50%;

      &.wide {
        width: 70%;
      }

      .page-title {
        color: #717171;
        font-size: 2em;
        margin-bottom: .7em;
      }

      .user-name {
        color: $black;
        font-size: 1.2em;
      }

      .menu {
        font-size: 1.1em;

        a {
          color: #717171;
          margin-right: 2em;

          &.active {
            border-bottom-color: #717171;
          }
        }
      }

      //Комментарии
      .comments-items {
        .item {
          font-size: 1.2em;

          .meta {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}

//Модальное окно с большой авой
#modal-profile-avatar {
  .avatar {
    margin: 0 auto 0 auto;
    width: 280px;
    height: 280px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
  }

  @media #{$large-up} {
    .avatar {
      width: 350px;
      height: 350px;
    }
  }
}