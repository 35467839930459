section.main.static-page {
  font-size: 1rem;
  margin: 1em 0 0 0;

  .text {
    font-size: 1.2em;
    p, ol, ul {
      font-size: 1em;
    }
  }

  @media #{$medium-up} {
    .text {
      font-size: 1.5em;
    }
  }

  @media #{$large-up} {

  }
}
