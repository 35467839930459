//Заголовок
.title-component {
  @include components-mixin;
  margin: 0 0 .5em 0;

  .ct-title {
    text-transform: uppercase;
    font-size: 1.1em;
    display: inline-block;
    margin: 0 1.5em 0 0;

    a {
      color: inherit;
    }
  }

  .ct-more {
    color: rgba(0, 0, 0, .4) !important;
    text-transform: uppercase;
    display: inline-block;
    font-size: .85em;
    padding-right: 14px;
    background-image: image-url("svg-icons/arrow-right-grey-light.svg");
    background-position: 100% 3px;
    background-repeat: no-repeat;
    background-size: 6px 11px;
    float: right;

    &.__no-float {
      float: none;
    }

    .ct-for-large-up {
      display: none;
    }
  }

  //C отступом слева
  &.__left-pad {
    padding-left: 15px;
  }

  //Дл темного фона - белый текст
  &.__white-text {
    .ct-title {
      color: $white;
    }

    .ct-more {
      color: $_font-color-transparent !important;
      background-image: image-url("svg-icons/arrow-right-grey-light.svg");
    }
  }

  @media #{$large-up} {
    font-size: 2rem;

    .ct-more {
      float: none;
      background-position: 100% 5px;
      background-size: 9px 18px;
      padding-right: 20px !important;

      .ct-for-large-up {
        display: inline;
      }
    }

    &.__small-text {
      font-size: 1.4em;
      .ct-more {
        background-position: 100% 0;
      }
    }
  }

  @media #{$xlarge-up} {
    font-size: 2.6rem;

    .ct-more {
      font-size: .65em;
    }

    &.__small-text {
      .ct-more {
        font-size: .85em;
      }
    }
  }
}