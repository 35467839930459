@mixin text-elipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@mixin caret ($color, $size, $direction) {
  display: inline-block;
  width: 0;
  height: 0;
  border: #{$size}px solid transparent;

  @if $direction == "up" {
    border-bottom-color: $color;
  } @else if $direction == "left" {
    border-right-color: $color;
  } @else if $direction == "right" {
    border-left-color: $color;
  } @else  {
    border-top-color: $color;
  }
}

//Кнопка
.c-button {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  color: $black !important;
  display: inline-block;
  text-align: center;
  padding: .4em 1.3em;
  background: #FFE25F;
  border-radius: 3px;
  text-transform: uppercase;
  text-decoration: none;
  border: none;

  &:hover, &:visited, &:active {
    text-decoration: none;
    outline: none;
  }

  @media #{$medium-up} {
    font-size: 1.3rem;
  }
}

.c-opacity-50 {
  opacity: .5;
}

.c-pointer-events-none {
  pointer-events: none;
}