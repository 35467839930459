//У этого файла специально 2 подчеркивания в имени - он должен подключаться первым при сборке

.clear {
  clear: both;
}

//Вспомогательные стили
.height-3 {
  height: 3px;
  padding: 0;
  margin: 0;
}

.height-5 {
  height: 5px;
  padding: 0;
  margin: 0;
}

.height-7 {
  height: 7px;
  padding: 0;
  margin: 0;
}

.height-10 {
  height: 10px;
  padding: 0;
  margin: 0;
}

.height-15 {
  height: 15px;
  padding: 0;
  margin: 0;
}

.height-20 {
  height: 20px;
  padding: 0;
  margin: 0;
}

.height-30 {
  height: 30px;
  padding: 0;
  margin: 0;
}

.height-40 {
  height: 40px;
  padding: 0;
  margin: 0;
}

.height-50 {
  height: 50px;
  padding: 0;
  margin: 0;
}

.height-60 {
  height: 60px;
  padding: 0;
  margin: 0;
}

.height-70 {
  height: 70px;
  padding: 0;
  margin: 0;
}

.height-80 {
  height: 80px;
  padding: 0;
  margin: 0;
}

.height-90 {
  height: 90px;
  padding: 0;
  margin: 0;
}

.position-relative {
  position: relative;
}

@mixin text-ellipsis {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}