.social-modal-component-bg {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

.social-modal-component {
  display: none;
  width: 245px;
  background: white;
  box-sizing: border-box;
  padding: 20px 10px 20px 10px;
  position: fixed;
  left: 50%;
  margin: 0 0 0 -122px;
  top: 30px;
  z-index: 1010;
  box-shadow: 0 20px 70px rgba(black, .2);

  .ct-close {
    position: absolute;
    right: -30px;
    top: 0;
    cursor: pointer;
    @include svg-image("cross-black-thick", 16, 16);
  }

  .ct-arrow {
    position: absolute;
    left: -22px;
    top: 0;
    @include svg-image("arrow-green", 85, 107);
  }

  .ct-text {
    margin: 0 0 25px 60px;
    font-size: 1.7em;
    line-height: 1.2;
    font-weight: bold;
  }

  .ct-divider {
    height: 10px;
  }

  .ct-dont-show {
    text-align: center;
    font-size: .9em;

    a {
      color: $aluminum;
    }
  }

  @media #{$large-up} {
    width: 420px;
    margin-left: -210px;
    top: 50px;
    padding-left: 20px;

    .ct-close {
      width: 27px;
      height: 27px;
      right: -50px;
    }

    .ct-arrow {
      width: 147px;
      height: 185px;
      left: -37px;
    }

    .ct-text {
      font-size: 2.8em;
      margin-left: 100px;
      margin-bottom: 55px;
    }

    .ct-divider {
      height: 20px;
    }
  }
}
