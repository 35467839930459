//TODO This mixin outputs a lot of CSS every time it is called. Check the size of the result CSS file at the end of development - it is not too big
@mixin sprite-image($name, $width, $height) {
  display: inline-block;
  background-image: image-url("icons/#{$name}.png");
  width: #{$width}px;
  height: #{$height}px;
  background-size: 100% 100%;

  @media #{$retina} {
    background-image: image-url("icons-retina/#{$name}.png");
  }
}