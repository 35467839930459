//Небольшая доработка специально для сафари
//В сафари глюк у первого элемента - он смещен в право на 1 пиксель
//из-за этого первый ряд разваливается
html.safari {
  .places-tile-component {
    .ct-item {
      &:first-of-type {
        -webkit-margin-start: -1px;
      }
    }
  }
}

//Плитка заведений
.places-tile-component {
  display: flex;
  flex-wrap: wrap;

  .ct-item {
    cursor: pointer;
    width: 50%;
    font-size: 1rem;
    position: relative;
    background-size: cover;
    color: $white;
    display: flex;
    box-sizing: border-box;
    min-height: 110px;

    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }

    .ct-gradient {
      width: 100%;
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-align-content: flex-end;
      align-content: flex-end;
    }

    .ct-like-recommends {
      position: absolute;
      right: 0;
      top: 0;
    }

    .ct-for-children {
      @include sprite-image("places-tile-component-for-children-small", 10, 12);
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .ct-like-recommends + .ct-for-children {
      right: 95px;
    }

    .ct-name {
      clear: both;
      margin: 2em 0 .3em 0;
      box-sizing: border-box;
      padding: 0 10px 0 10px;
      width: 100%;

      a {
        display: inline-block;
      }
    }

    .ct-type {
      padding: 0 10px 0 23px;
      width: 100%;
      box-sizing: border-box;
      font-size: .9em;
      position: relative;
      color: $_font-color-transparent;
      margin: 0 0 .3em 0;

      .ct-icon {
        position: absolute;
        left: 7px;
        top: 2px;
      }

      a {
        color: $_font-color-transparent;
      }
    }

    .ct-location {
      margin: 0 10px .3em 10px;
      box-sizing: border-box;
    }

    .ct-meta {
      padding: 0 10px 0 10px;
      width: 100%;
      box-sizing: border-box;
    }

    .ct-tags {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px 10px 10px;
    }

    .ct-event {
      background: rgba(0, 0, 0, .75);
      color: $white;
      position: relative;
      padding: .5em 10px .5em 20px;
      width: 100%;
      box-sizing: border-box;

      .ct-event-icon {
        position: absolute;
        left: 4px;
        top: 10px;
      }

      a.ct-event-name {
        display: block;
        @include text-elipsis;
      }

      .ct-event-date {
        font-size: .9em;
        color: $_font-color-transparent;

        a {
          color: $_font-color-transparent;
        }
      }
    }

    &.__plug {
      background-image: linear-gradient(135deg, #C0FFF5, #9878CD);
      display: block;
      color: $white;

      &.__dark {
        background-color: #1a1a1a;
        background-image: none;
      }

      &.__violet {
        background-image: linear-gradient(135deg, rgba(163, 34, 138, 1), rgba(145, 103, 31, 1));
      }

      &.__dark-violet {
        background-image: linear-gradient(135deg, rgba(173, 98, 28, 1), rgba(103, 20, 140, 1));
      }

      &.__light-blue {
        background-image: linear-gradient(135deg, rgba(34, 157, 148, 1), rgba(34, 68, 142, 1));
      }

      &.__large-up {
        display: none;
      }


      .ct-spacer {
        height: 140px;
      }

      .ct-block {
        text-align: center;
        width: 130px;
        height: 70px;
        left: 50%;
        top: 50%;
        position: absolute;
        margin-top: -35px;
        margin-left: -65px;

        .ct-icon {
          @include sprite-image("events-tile-component-magnifier", 37, 37)
        }

        .ct-text {
          text-transform: uppercase;
        }
      }
    }
  }

  @media #{$medium-up} {
    .ct-item {
      min-height: 150px;
    }
  }

  @media #{$large-up} {
    .ct-item {
      min-height: 175px;
      width: 33.3%;

      .ct-for-children {
        @include sprite-image("places-tile-component-for-children-big", 14, 17);
        right: 15px;
        top: 15px;
      }

      .ct-like-recommends + .ct-for-children {
        right: 130px;
        top: 6px;
      }

      .ct-name {
        font-size: 1.6em;
        padding-left: 20px;
        padding-right: 20px;
      }

      .ct-type {
        padding-left: 39px;
        margin-bottom: .5em;

        .ct-type-name {
          font-size: 1.2em;
        }

        .ct-icon {
          left: 18px;
        }
      }

      .ct-location {
        margin-bottom: .5em;
        margin-left: 20px;
      }

      .ct-meta {
        margin-bottom: .5em;
        padding-left: 20px;
        padding-right: 20px;
      }

      .ct-tags {
        padding-left: 20px;
        padding-right: 20px;
      }

      .ct-event {
        padding: .5em 20px .5em 45px;
        font-size: 1.3em;

        .ct-event-icon {
          left: 12px;
        }
      }

      &.__plug {
        &.__2 {
          width: 66.6%;
        }

        &.__large-up {
          display: block;
        }

        &.__medium-down {
          display: none;
        }
      }
    }
  }

  @media #{$xlarge-up} {
    .ct-item {
      min-height: 230px;
    }
  }
}