//Кнопка купить билет
.buy-ticket-component {
  display: block;
  max-width: 350px;
  height: 40px;
  box-sizing: border-box;
  padding: .5em 50px .5em 55px;
  text-transform: uppercase;
  color: $black;
  font-size: 1.3em;
  background: #FFE25F;
  position: relative;
  margin: 0 0 .5em 0;

  &:hover {
    color: $black;
    text-decoration: underline;
  }

  .ct-icon-ticket {
    position: absolute;
    left: 16px;
    top: 13px;
    @include svg-image("buy-ticket-black", 23, 12);
  }

  .ct-icon-arrow {
    position: absolute;
    right: 15px;
    top: 10px;
    @include svg-image("arrow-right-black", 11, 27);
  }

  &.__free {
    background-color: #6dcd4c;
    text-align: center;
    color: white;
  }
}