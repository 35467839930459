section.main.profile.events {
  //Правая колонка
  .rightcol {
    //События
    .items {
      margin-left: -15px;
      margin-right: -15px;

      .item {
        @extend .clearfix;
        background: linear-gradient(135deg, #d5ffdd, #ffb7b7);
        margin: 0 0 10px 0;
        position: relative;

        &:nth-child(2n) {
          background: linear-gradient(135deg, #FFF4D5, #B7E9FF);
        }

        &:nth-child(3n) {
          background: linear-gradient(135deg, #D5DDFF, #B7E9FF);
        }

        &:nth-child(4n) {
          background: linear-gradient(135deg, #FFD8D5, #E8B7FF);
        }

        &:nth-child(5n) {
          background: linear-gradient(135deg, #C8ECF4, #B7FFCB);
        }

        .recommends {
          position: absolute;
          right: 0;
          top: 0;
        }

        .result-img {
          float: right;
          width: 100px;
          background-size: cover;
          background-position: center center;

          img {
            width: 100%;
          }
        }

        .result-content {
          margin: 15px 105px 0 15px;
          position: relative;
          @extend .clearfix;

          .for-children-icon {
            position: absolute;
            right: 0px;
            top: -5px;
            @include svg-image("for-children-grey", 11 , 14);
          }

          .result-meta-top {
            color: #818181;
            font-size: .8em;
            margin-right: 33px;

            a {
              color: inherit;
            }

            .result-meta-item {
              float: left;
              margin: 0 1em 1em 0;
              min-height: 1.5em;

              .with-icon {
                .elem-icon {
                  float: left;
                  width: 15px;
                  height: 15px;
                  margin: 0 5px 0 0;
                  background-size: contain;
                  background-position: center center;
                  background-repeat: no-repeat;

                  &.e-p-types-component {
                    opacity: .7;
                  }

                  &.benefit-kupon {
                    background-image: image-url("svg-icons/benefit-kupon-grey.svg");
                  }

                  &.benefit-discount {
                    background-image: image-url("svg-icons/benefit-discount-grey.svg");
                  }

                  &.benefit-gift {
                    background-image: image-url("svg-icons/benefit-gift-grey.svg");
                  }
                }

                .elem-text {
                  float: left;
                  display: inline-block;
                }
              }
            }
          }

          .result-description {
            float: left;
            width: 100%;
            margin: 0 0 .5em 0;
            font-size: 1.2em;

            &.small {
              font-size: 1em;
            }

            a {
              color: $black;
              display: inline-block;
            }
          }

          .result-meta-bottom {
            .result-meta-item {
              float: left;
              margin: 0 1em 1em 0;
              color: #818181;
              font-size: .8em;

              a {
                color: inherit;
              }

              .result-location {
                .location-icon {
                  float: left;
                  @include svg-image("location-grey", 7, 10);
                  margin: 2px 3px 0 0;
                }

                .location-text {
                  float: left;
                }
              }

              .result-rating {
                float: left;
                margin: 0 .5em 0 0;

                .rating-star {
                  float: left;
                  margin: .5em 1px 0 0;
                  @include svg-image("star-grey", 7, 7);
                }
              }

              .result-views {
                float: left;
                margin: 0 .5em 0 0;

                .views-icon {
                  float: left;
                  margin: .5em .3em 0 0;
                  @include svg-image("views-grey", 10, 6);
                }

                .views-num {
                  float: left;
                  font-size: .9em;
                  margin: .1em 0 0 0;
                }
              }

              .result-likes {
                float: left;

                .result-icon {
                  float: left;
                  margin: .5em .3em 0 0;
                  @include svg-image("likes-grey", 6, 6);
                }

                .result-num {
                  float: left;
                  font-size: .9em;
                  margin: .1em 0 0 0;
                }
              }

              .result-tags {
                float: left;

                .result-tag {
                  display: inline-block;
                  margin: 0 .5em .5em 0;
                  float: left;

                  &::before {
                    content: "#";
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media #{$large-up} {
    //Правая колонка
    .rightcol {
      .items {
        margin-left: 0;
        margin-right: 0;

        .item {
          font-size: 1.5em;

          .result-img {
            width: 150px;
          }

          .result-content {
            margin-top: 25px;
            margin-left: 25px;
            margin-right: 160px;

            .for-children-icon {
              @include svg-image("for-children-grey", 14 , 17);
            }

            .result-meta-top {
              margin-right: 50px;

              .result-meta-item {
                .with-icon {
                  .elem-icon {
                    margin-top: 3px;
                  }
                }
              }
            }

            .result-meta-bottom {
              .result-meta-item {
                .result-location {
                  .location-icon {
                    @include svg-image("location-grey", 11, 17);
                    margin-right: 5px;
                  }
                }

                .result-rating {
                  .rating-star {
                    margin-top: .3em;
                    @include svg-image("star-grey", 15, 15);
                  }
                }

                .result-views {
                  .views-icon {
                    margin-top: .3em;
                    @include svg-image("views-grey", 19, 12);
                  }
                }

                .result-likes {
                  .result-icon {
                    margin-top: .3em;
                    @include svg-image("likes-grey", 13, 13);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}