//Активные люди
.active-people-component {
  @include components-mixin;

  .ct-item {
    float: left;
    width: 33.3%;
    line-height: 1.2;
    box-sizing: border-box;
    margin: 0 0 1.5em 0;
    padding: 0 1em 0 0;

    a.ct-photo {
      border-radius: 50%;
      display: inline-block;
      margin: 0 0 .5em 0;

      img {
        border-radius: 50%;
      }
    }

    .ct-text-block {
      .ct-name {
        text-transform: uppercase;
        font-size: .8em;

        a.ct-name-link {
          color: $black;
        }
      }

      .ct-status {
        font-size: .7em;
      }
    }
  }

  &.__white-text {
    color: $white;

    .ct-text-block {
      .ct-name {

        a.ct-name-link {
          color: $white;
        }
      }
    }
  }

  @media #{$large-up} {
    font-size: 1.5rem;

    .ct-item {
      width: 25%;
    }
  }

  @media #{$large-up} {
    font-size: 1.7rem;

    .ct-item {
      width: 20%;
    }
  }
}