section.main.people.show {

  .title-component {
    font-size: 1.3rem;

    @media #{$large-up} {
      font-size: 2rem;
    }
  }

  //Блок с круглыми фотками
  .rounded-photos-block {
    margin: 1.5em 0 0 0;

    article {
      flex-basis: 30%;
      padding-right: 0;
      text-align: center;
      margin-right: 12px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .name, .post {
        margin-left: 0;
        text-transform: none;
      }

      .post {
        opacity: 0.6;
      }

      .avatar {
        display: inline-block;
        width: 90%;
      }
    }

    @media #{$large-up} {
      article {
        margin-right: 22px;
      }
    }

    @media #{$xlarge-up} {
      article {
        margin-right: 30px;
      }
    }
  }

  //Текстовый блок с выбранной легендой
  .article {
    margin: 0 0 3em 0;
    line-height: $paragraph-line-height;
    color: $tuatara;

    .title {
      font-size: 1.3rem;
      margin: 0 0 1em 0;
    }

    //Тут основное содержание
    .text {
      font-size: 1.2rem;

      p, ul, li {
        font-size: 1.2rem;
      }

      a {
        display: inline-block;
        color: inherit;
        background: #ebebeb;
        padding: .1em .5em;
        border-radius: 1em;
        transition: all .5s;

        &:hover {
          text-decoration: none;
          background: darken(#ebebeb, 8%);
        }
      }
    }
  }

  //Видео-отчет
  .video-report {
    background-size: cover;
    background-position: center center;
    margin: 0 0 1.5em 0;
    color: $white;

    .shadow {
      background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, .17) 100%);
      padding: 2em 2em 2em 2em;

      .left {
        float: left;
        width: 75%;
        box-sizing: border-box;
        padding: .3em 0 0 0;

        .text {
          margin: 0 0 .3em 0;
        }
      }

      .play {
        float: right;
        width: 25%;
        text-align: right;

        .icon {
          cursor: pointer;
          @include sprite-image("play-small", 50, 50);
        }
      }
    }
  }

  @media #{$large-up} {
    //Блок с карнтинкой
    .article-image {
      margin-bottom: 2em;

      .container {
        padding-top: 15em;
      }

      .ct-fi-open {
        top: 100px;
      }

      .small-image {
        width: 300px;
        max-width: 300px;
      }

      .right-block {
        width: 60%;
        padding-left: 2em;

        .title {
          font-size: 3.3rem;
        }

        .meta {
          font-size: 1.3em;

          > * {
            margin-right: 2.5rem;
          }

          .small-text {
            font-size: .8em;
            padding-top: 1em;
          }
        }
      }
    }

    //Текстовый блок с выбранной легендой
    .article {
      float: left;
      width: 60%;
      margin-bottom: 5em;

      .title {
        font-size: 2.2rem;
      }

      .text {
        font-size: 1.4rem;

        p, ul, li {
          font-size: 1.5rem;
        }
      }
    }

    //Видео-отчет
    .video-report {
      .shadow {
        padding-left: 3em;
        padding-right: 3em;

        .left {
          .text {
            font-size: 1.6em;
            margin-top: 1em;
            margin-bottom: .7em;
          }
        }

        .play {
          .icon {
            @include sprite-image("play-big", 117, 117);
          }
        }
      }
    }

    //Фото-отчет
    .photo-report {
      margin-bottom: 2.5em;
    }
  }

  @media #{$xlarge-up} {
    //Блок с картинкой
    .article-image {
      .right-block {
        width: 70%;
      }
    }
  }
}
