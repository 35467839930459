//Заголовок с рамками
.sections-component {
  @include components-mixin;

  .ct-item {
    position: relative;
    padding: .6em .5em .6em .3em;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    margin: 0 0 1.5em 0;

    .ct-icon {
      position: absolute;
      left: .2em;
      top: .8em;
    }

    .ct-link {
      font-size: 1.7em;
      color: $black !important;
      @include text-elipsis;
    }

    .ct-small {
      font-size: 1em;
      opacity: .8;
    }

    &.__with-icon {
      padding-left: 35px;
    }
  }

  @media #{$large-up} {
    .ct-item {
      float: left;
      width: 30.3%;
      margin-right: 3%;
      margin-bottom: 2.5em;
    }
  }
}