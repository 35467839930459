.opinions-filter-component {
  @extend .clearfix;
  $component-height: 50px;
  height: $component-height;
  margin-bottom: 5em;
  @include components-mixin;
  ul {
    display: none;
    font-size: 1.23rem;
    position: absolute;
    top: 100%;
    margin: 2px 0 0 0;
    padding: 0;
    background: white;
    box-shadow: 0 5px 5px rgba(black, .15);
    list-style: none;
    z-index: 5;
    max-height: 500px;
    overflow: auto;
    color: #666;
    text-transform: uppercase;
    li {
      margin: 0;
      padding: .7em 1em;
      white-space: nowrap;
      i {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: center center / contain no-repeat;
        margin-right: .4em;
        opacity: .7;
        position: relative;
        top: .1em; }
      &:hover {
        cursor: pointer;
        background: #f8f8f8; }
      &.__stars {
        span {
          display: none; }
        i {
          background-image: image-url('svg-icons/star-grey.svg'); }
        &.__1 {
          i:nth-child(1) {
            background-image: image-url('svg-icons/star-yellow.svg'); } }
        &.__2 {
          i:nth-child(1), i:nth-child(2) {
            background-image: image-url('svg-icons/star-yellow.svg'); } }
        &.__3 {
          i:nth-child(1), i:nth-child(2), i:nth-child(3) {
            background-image: image-url('svg-icons/star-yellow.svg'); } }
        &.__4 {
          i:nth-child(1), i:nth-child(2), i:nth-child(3), i:nth-child(4) {
            background-image: image-url('svg-icons/star-yellow.svg'); } }
        &.__5 {
          i {
            background-image: image-url('svg-icons/star-yellow.svg'); } } } } }
  .ct-fc-name-container {
    height: $component-height;
    float: left;
    width: 25%;
    margin-right: .5%;
    position: relative;
    &.__active {
      ul {
        display: block; } }
    .ct-fc-name {
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      display: block;
      outline: none;
      border: none;
      padding: 0 .2em 0 40px;
      background: white image-url('svg-icons/magnifier-grey.svg') 10px 14px / 22px 22px no-repeat;
      font-size: 1.23em;
      color: #666; } }
  button {
    float: right;
    background: #6DCD4C;
    font-size: 1.46em;
    height: 100%;
    box-sizing: border-box;
    color: white;
    border: none;
    padding: 0 1em;
    text-transform: uppercase;
    outline: none;
    width: 18.5%; }
  .ct-fc-dd {
    float: left;
    position: relative;
    color: #666;
    font-size: 1.23em;
    text-transform: uppercase;
    width: 12%;
    box-sizing: border-box;
    &.__rating {
      width: 15%; }
    &.__period {
      width: 16.5%;
      .ct-fc-dd-trigger {
        border-right: none; } }
    .ct-fc-dd-trigger {
      cursor: pointer;
      border-right: 1px solid #D1D1D1;
      background: white;
      height: $component-height;
      box-sizing: border-box;
      position: relative;
      padding: .8em 24px 0 1em;
      span {
        @include text-elipsis; }
      .ct-fc-dd-caret {
        @include caret(#666, 7, down);
        position: absolute;
        right: .8em;
        top: 1.3em; } }
    &.__active {
      .ct-fc-dd-trigger {
        .ct-fc-dd-caret {
          @include caret(#666, 7, up);
          top: .6em; } }
      ul {
        display: block; } } }
  //Вариант с сервым фоном
  &.__grey-bg {
    .ct-fc-name-container .ct-fc-name, .ct-fc-dd .ct-fc-dd-trigger {
        background-color: #f2f2f2; } }
  &.__small-margin-bottom {
    margin-bottom: 3em; } }

