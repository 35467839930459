section.main.events.index {
  //Событие по времени
  .events-by-time {
    background: $black;
    margin: 0 0 4em 0;
    padding: 1em 0 0 0;

    @media #{$large-up} {
      margin-bottom: 6em;
    }
  }

  //Главные события
  .main-events {
    margin: 0 0 3em 0;

    //Событие с картинкой
    .main-event {
      background-size: cover;
      color: $white;
      font-size: 1rem;

      .bg-gradient {
        padding: 1em 15px 2em 15px;
        position: relative;

        .like-recommend {
          position: absolute;
          right: 0;
          bottom: 100%;
        }

        .date {
          text-transform: uppercase;
          color: $_font-color-transparent;
          font-size: .8em;
          padding-top: .1em;
          float: left;

          a {
            color: inherit;
          }
        }

        a.all {
          display: inline-block;
          float: left;
          text-transform: uppercase;
          color: $_font-color-transparent;
          font-size: .9em;
          margin: 0 0 0 3em;
        }

        a.name {
          display: inline-block;
          color: $white;
          margin: 2em 0 1em 0;
          font-size: 1.3em;
        }

        .description {
          font-size: .8em;
          margin: 0 0 2em 0;
        }

        .meta {
          .rating {
            float: left;
            margin-right: 1em;
          }

          .views {
            float: left;
            margin-right: 1em;
          }

          .go {
            float: left;
            font-size: .8em;
            margin-top: -.2em;
            color: $_font-color-transparent;
          }
        }
      }
    }

    //Остальные события
    .other-events {
      background: $black;

      article {
        color: $white;
        padding: 1em 15px 1em 15px;
        border-bottom: 1px solid #424242;

        .date {
          color: $_font-color-transparent;
          font-size: .9em;
          text-transform: uppercase;

          a {
            color: $_font-color-transparent;
          }
        }

        a.name {
          display: inline-block;
          color: $white;
          font-size: 1.2em;
          margin: 1em 0 1em 0;
        }

        .meta {
          .rating {
            float: left;
            margin-right: 1em;
          }

          .views {
            float: left;
            margin-right: 1em;
          }

          .go {
            float: left;
            font-size: .8em;
            margin-top: -.2em;
            color: $_font-color-transparent;
          }
        }
      }
    }

    @media #{$large-up} {
      margin-bottom: 5em;

      .main-event {
        font-size: 1.8rem;

        .bg-gradient {
          padding-left: 35px;
          padding-right: 35px;

          .like-recommend {
            bottom: auto;
            top: 0;
          }

          .date {
            padding-top: .3em;
            font-size: .6em;
          }

          .meta {
            font-size: .7em;

            .go {
              margin-top: .1em;
            }
          }
        }
      }

      .other-events {
        article {
          float: left;
          width: 33.3%;
          font-size: 1.1em;
          padding-left: 35px;
          padding-right: 35px;

          .meta {
            .go {
              margin-top: .1em;
            }
          }
        }
      }
    }
  }

  .event-types {
    margin: 0 0 2em 0;
  }

  //Для детей
  .events-for-children {
    padding: 2em 0 0 0;
    background: image-url("events/children-bg-small.jpg");
    margin: 0 0 3em 0;

    @media #{$large-up} {
      background: image-url("events/children-bg-large.jpg");
      margin-bottom: 5em;
    }
  }
}