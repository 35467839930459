//Полноэкранное изображение
.fullscreen-image-component {
  position: relative;

  .ct-fi-open {
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    background-size: 100% 100%;
    background-image: image-url("components/fullscreen-image/open.svg");
  }
}

//Эти элементы выносятся в конец body
.fullscreen-image-component-ct-fi-bg {
  position: fixed;
  left: 0;
  top: 0;
  background: $black;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: none;
}

.fullscreen-image-component-ct-fi-modal {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50px;
  z-index: 1001;

  .ct-fi-close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-size: 100% 100%;
    background-image: image-url("components/fullscreen-image/close.svg");
  }

  img {
    display: block;
    margin: 0 auto 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
}

@media #{$large-up} {
  .fullscreen-image-component {
    .ct-fi-open {
      width: 50px;
      height: 50px;
    }
  }

  .fullscreen-image-component-ct-fi-modal {
    .ct-fi-close {
      width: 50px;
      height: 50px;
      right: 25px;
      top: 25px;
    }
  }
}