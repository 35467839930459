.header-section .main {
  $active-color: #6DCD4C;
  > .container {
    display: flex;
    align-items: center;
    height: 66px;
    position: relative;
    z-index: 2;
  }
  ul.menu {
    clear: both;
    list-style: none;
    margin: 0 -15px 0 -15px;
    text-transform: uppercase;
    height: 0;
    overflow: hidden;
    transition: .5s height, .5s padding-top;

    a {
      color: $white;
      border-bottom: 1px solid transparent;

      &:hover {
        color: $active-color;
        text-decoration: none;
      }
    }

    li {
      padding: 8px 20px 8px 30px;

      &.current {
        > a {
          color: $active-color;
        }
      }

      &.submenu-trigger {
        a {
          display: inline-block;

          .text {
            display: inline-block;
            float: left;
          }

          .caret {
            display: inline-block;
            float: left;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-top-color: $white;
            position: relative;
            top: 4px;
            left: 10px;
          }
        }

        &.active {
          color: $active-color;
          a {
            .caret {
              border-top-color: transparent;
              border-bottom-color: $white;
              top: -2px;
            }
          }
        }

        &.change-city {
          margin-top: 1em;
          background: $black;
        }
      }
    }

    ul.submenu {
      list-style: none;
      margin: 0 0 .5em 0;
      display: none;

      li {
        position: relative;
        margin-left: 20px;
        margin-bottom: .1em;
        margin-top: .1em;
        @include text-ellipsis;

        .icon {
          position: absolute;
          left: 4px;
          top: 8px;
          width: 17px !important;
          height: 17px !important;

          &.all-events {
            background: image-url("svg-icons/all-events-white.svg") no-repeat center center;
            background-size: contain;
          }

          &.all-places {
            background: image-url("svg-icons/all-places-white.svg") no-repeat center center;
            background-size: contain;
          }
        }

      }

      &.cities {
        padding: 1em 0 0 0;

        li {
          margin: 0;

          &.active {
            a {
              border: 1px solid $white;
              padding: 3px 10px 3px 10px;
              position: relative;
              left: -10px;
            }
          }
        }
      }
    }

    .opened & {
      overflow: auto;
      padding-top: 1em;
      height: 330px;
    }

    @media #{$large-up} {
      & {
        margin: 0;
        padding: 0 !important;
        height: auto !important;
        float: left;
        font-size: 1em;
        clear: none;
        background: none;

        li {
          float: left;
          padding: 0 1.2em 0 0;

          //На размере экрана large показывается разделитель в меню
          &.divider {
            float: none;
            clear: both;
            height: .4em;
          }

          &.submenu-trigger {
            a {
              .caret {
                display: none;
              }
            }
          }

          &.change-city {
            display: none;
          }
        }

        ul.submenu {
          position: absolute;
          z-index: 3;
          left: 0;
          top: 100%;
          width: 100%;
          background: #303030;
          padding-top: 1em;
          padding-bottom: 1em;

          .container {
            padding-left: 130px;
            padding-right: 20px;
          }

          li {
            float: left;
            width: 22%;
            margin: 0 3% 1.3em 0;
            padding: 0 0 0 25px;

            .icon {
              left: 0;
              top: 0;
            }
          }

          &.cities {
            padding-bottom: .5em;

            .container {
              padding: 0;
            }

            li {
              width: auto;
              margin-bottom: .6em;

              &.active {
                a {
                  border: none;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }

    @media #{$xlarge-up} {
      & {
        // margin-left: 1em;

        li {
          padding-right: 1.5em;
          &.__last {
            padding-right: 0;
          }
        }

        ul.cities {
          font-size: 1.2rem;
        }
      }
    }
  }
}
