.editors-choice-component {
  background: linear-gradient(135deg, #29C2B7, #1F4BA9);
  padding: 1.2em 0 3em 0;
  margin: 0 0 2em 0;
  overflow: hidden;

  .ct-ec-title {
    @extend .clearfix;
    margin: 0 0 1.2em 10px;

    span {
      display: inline-block;
      float: left;
      font-size: 1.38em;
      text-transform: uppercase;
      color: white;
      margin: 0 1.2em 0 0;
    }

    i {
      display: inline-block;
      @extend .svg-logo-white-small;
      margin: 4px 0 0 0;
    }
  }

  .ct-root {
    .ct-list {
      .ct-item {
        @extend .clearfix;

        .ct-rect {
          height: 160px;
          box-sizing: border-box;
          background: white;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          margin: 0 0 15px 0;

          .meta-info-component {
            position: absolute;
            top: 10px;
            left: 10px;
            width: calc(100% - 20px);
            box-sizing: border-box;
          }

          .ct-ec-rect-title {
            margin: 30px;
            font-weight: bold;
            font-size: 1.38em;
            line-height: 1.28;
            text-align: center;
            width: 100%;
            display: block;
            color: black;

            &:hover {
              text-decoration: none;
            }
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            height: 5px;
            width: calc(100% - 10px);
            left: 5px;
            bottom: -5px;
            background: rgba(white, .3);
          }
        }
      }
    }
  }

  @media #{$large-up} {
    padding-top: 3em;
    padding-bottom: 4.5em;

    .ct-ec-title {
      margin-left: 0;
      margin-bottom: 3.5em;

      span {
        font-size: 2.62em;
        margin-right: .9em;
      }

      i {
        @include svg-logo-white;
        margin-top: 10px;
      }
    }

    .ct-control-left, .ct-control-right {
      position: absolute;
      top: 54%;
      cursor: pointer;
    }

    .ct-control-left {
      left: -15px;
      @include svg-common;
      @include svg-arrow-left-white-large;
    }

    .ct-control-right {
      right: -15px;
      @include svg-common;
      @include svg-arrow-right-white-large;
    }

    .ct-root {
      .ct-list {
        .ct-item {
          padding-right: 2px;

          .ct-rect {
            height: 287px;
            float: left;
            width: 50%;

            .meta-info-component {
              top: 15px;
              left: 15px;
              width: calc(100% - 25px);
            }

            .ct-ec-rect-title {
              font-size: 2.62em;
              line-height: 1;
            }

            &:first-of-type {
              margin-right: 2px;
              width: calc(50% - 2px);
            }

            &::after {
              height: 7px;
              width: calc(100% - 14px);
              left: 7px;
              bottom: -7px;
            }
          }
        }
      }
    }
  }
}