//Небольшая доработка специально для сафари
//В сафари глюк у первого элемента - он смещен в право на 1 пиксель
//из-за этого первый ряд разваливается
html.safari {
  .overviews-tile-component {
    .ct-items {
      .ct-item {
        &:first-of-type {
          -webkit-margin-start: -1px;
        }
      }
    }
  }
}

//Обзоры
.overviews-tile-component {
  margin: 0 0 2em 0;

  .ct-items {
    @extend .clearfix;
    display: flex;
    flex-wrap: wrap;

    .ct-item {
      cursor: pointer;
      flex-basis: 50%;
      position: relative;
      background-position: center center;
      background-size: cover;
      overflow: hidden;

      .ct-spacer {
        width: 100%;
      }

      .ct-gradient {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(145deg, rgba(#7729C2, .8), rgba(#20A887, .8));
        padding: .8em .5em;
        min-height: 100px;

        .ct-name {
          color: $white;
          margin: 0 0 .4em 0;
          min-height: 3em;

          a {
            color: inherit;
            display: inline-block;
            &:hover {
              text-decoration: none;
            }
          }
        }

        .ct-r-v {
          @extend .clearfix;
          margin: 0 0 .2em 0;

          .ct-rating {
            float: left;
            margin: 0 1em 0 0;
          }

          .ct-views {
            float: left;
          }
        }
      }

      &:nth-child(4n -2) {
        .ct-gradient {
          //background: linear-gradient(145deg, rgba(#29C23A, .8), rgba(#A89820, .8));
        }
      }

      &:nth-child(4n -1) {
        .ct-gradient {
          //background: linear-gradient(145deg, rgba(#7729C2, .8), rgba(#20A887, .8));
        }
      }

      &:nth-child(4n) {
        .ct-gradient {
          //background: linear-gradient(145deg, rgba(#2943C2, .8), rgba(#7820A8, .8));
        }
      }

      //Ссылка-заглушка
      &.__plug {
        background-image: linear-gradient(135deg, #C0FFF5, #9878CD);
        display: block;
        color: $white;

        .ct-spacer {
          height: 140px;
        }

        .ct-block {
          text-align: center;
          width: 130px;
          height: 70px;
          left: 50%;
          top: 50%;
          position: absolute;
          margin-top: -35px;
          margin-left: -65px;

          .ct-icon {
            @include sprite-image("events-tile-component-magnifier", 37, 37)
          }

          .ct-text {
            text-transform: uppercase;
          }
        }

        //Заглушку с таким классом на небольших
        //экранах не показываем - она висит внизу одна некрасиво
        &.__2 {
          display: none;
        }
      }
    }
  }

  @media #{$large-up} {
    margin-bottom: 4em;

    .ct-items {
      .ct-item {
        flex-basis: 25%;

        .ct-gradient {
          padding-left: 1em;
          padding-right: 1em;

          .ct-name {
            font-size: 1.3em;
          }
        }

        &.__plug {
          //в 2 и 3 ячейки
          &.__2 {
            flex-basis: 50%;
            display: block;
          }

          &.__3 {
            flex-basis: 75%;
          }
        }
      }
    }
  }
}