//Градиент
.gradient-component {
  background-image: linear-gradient(135deg, rgba(39, 180, 54, .74), rgba(168, 152, 32, .74));

  a {
    text-decoration: none;
  }

  &.__dark {
    background: rgba(0, 0, 0, .5);
  }

  &.__very-dark {
    background: rgba(0, 0, 0, .6);
  }

  &.__light-green {
    background-image: linear-gradient(135deg, rgba(39, 180, 54, .74), rgba(168, 152, 32, .74));

    &.__hover-effect {
      background-color: rgba(168, 152, 32, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(168, 152, 32, .9);
      }
    }
  }

  &.__green {
    background-image: linear-gradient(135deg, rgba(38, 162, 52, .74), rgba(26, 142, 114, .74));

    &.__hover-effect {
      background-color: rgba(26, 142, 114, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(26, 142, 114, .9);
      }
    }
  }

  &.__dark-green {
    background-image: linear-gradient(135deg, rgba(48, 173, 90, .74), rgba(158, 30, 34, .74));

    &.__hover-effect {
      background-color: rgba(158, 30, 34, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(158, 30, 34, .9);
      }
    }
  }

  &.__dark-violet {
    background-image: linear-gradient(135deg, rgba(173, 98, 28, .74), rgba(103, 20, 140, .74));

    &.__hover-effect {
      background-color: rgba(103, 20, 140, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(103, 20, 140, .9);
      }
    }
  }

  &.__red {
    background-image: linear-gradient(135deg, rgba(172, 27, 28, .74), rgba(157, 166, 42, .74));

    &.__hover-effect {
      background-color: rgba(157, 166, 42, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(157, 166, 42, .9);
      }
    }
  }

  &.__violet {
    background-image: linear-gradient(135deg, rgba(163, 34, 138, .74), rgba(145, 103, 31, .74));

    &.__hover-effect {
      background-color: rgba(145, 103, 31, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(145, 103, 31, .9);
      }
    }
  }

  &.__blue {
    background-image: linear-gradient(135deg, rgba(98, 40, 154, .74), rgba(34, 138, 113, .74));

    &.__hover-effect {
      background-color: rgba(34, 138, 113, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(34, 138, 113, .9);
      }
    }
  }

  &.__light-blue {
    background-image: linear-gradient(135deg, rgba(34, 157, 148, .74), rgba(34, 68, 142, .74));

    &.__hover-effect {
      background-color: rgba(34, 68, 142, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(34, 68, 142, .9);
      }
    }
  }

  &.__dark-blue {
    background-image: linear-gradient(135deg, rgba(40, 61, 161, .74), rgba(107, 37, 145, .74));

    &.__hover-effect {
      background-color: rgba(107, 37, 145, .0);
      transition: background-color .5s;

      &:hover {
        background-color: rgba(107, 37, 145, .9);
      }
    }
  }
}