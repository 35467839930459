.header-section {
  .news {
    margin-top: -66px;
    position: relative;

    &-inner {
      padding-top: 66px;
      background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.54));
    }

    &-header {
      height: 208px;
    }
    &-title {
      margin-top: 101px;
      margin-bottom: 57px;
      font-weight: 100;
      font-size: 36px;
      color: $white;
      letter-spacing: 1.29px;

      &-cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.54));
        }
      }
      &-text {
        position: relative;
        > b {
          font-weight: bold;
        }
      }
    }
  }

  .event {
    margin-bottom: 35px;
    &:first-child {
      margin-top: 87px;
    }
    & + .event {
      margin-top: 35px;
      .event-cover {
        display: none;
      }
    }
    &-cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.54));
      }
    }
    &-inner {
      position: relative;
      padding-right: 240px;
      // padding: 87px 240px 35px 0;
      // min-height: 208px;
      z-index: 1;
    }
    &-date {
      margin-bottom: 24px;
      font-size: 15px;
      color: #FFF;
      opacity: 0.85;
      letter-spacing: 0.54px;
      text-transform: uppercase;
    }
    &-title {
      margin-bottom: 10px;
      font-size: 36px;
      color: #FFF;
      letter-spacing: 1.29px;
      &:hover {
        color: #FFF;
        text-decoration: none;
      }
    }
    &-location {
      display: block;
      padding-left: 16px;
      color: #FFF;
      opacity: 0.85;
      text-decoration: none;
      font-size: 16px;
      letter-spacing: 0.64px;
      position: relative;
      z-index: 2;

      &:hover {
        color: #FFF;
        text-decoration: none;
      }

      // @media #{$xxlarge-up} {
      //   font-size: 15px;
      // }
      > svg {
        position: absolute;
        top: -1px;
        left: 0;
        width: 10px;
        height: 16px;
        fill: currentColor;
        opacity: 0.85;
      }
    }
    &-book {
      margin-bottom: 10px;
      padding: 12px 37px 12px;
      font-size: 24px;
      color: #FFF;
      letter-spacing: 0.86px;
      background-image: linear-gradient(135deg, #5CA034 0%, #C8CC00 100%);
      &:hover {
        text-decoration: none;
        color: #FFF;
        background-image: linear-gradient(135deg, #5CA034 30%, #C8CC00 100%);
      }
    }
    &-aside {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-hint {
        opacity: 0.85;
        font-size: 15px;
        color: #FFF;
        letter-spacing: 0.54px;
      }
    }
  }
}
