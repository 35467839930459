section.main.photo_video.index {
  //Автивные фотографы
  .cameramen {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 0 2em 0;

    article {
      flex-basis: 33.3%;
      margin-bottom: 1em;
      box-sizing: border-box;
      padding-right: 1em;

      .img {
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        margin: 0 0 .5em 0;

        img {
          display: inline-block;
          width: 100%;
        }
      }

      .name {
        text-transform: uppercase;
        text-align: center;
        font-size: .85em;

        a {
          color: $black;
          display: inline-block;
        }
      }
    }

    @media #{$medium-up} {
      article {
        flex-basis: 25%;
      }
    }

    @media #{$large-up} {
      margin-bottom: 5em;

      article {
        padding-right: 3em;
        flex-basis: 20%;
        margin-bottom: 2em;

        .name {
          font-size: 1.1em;
        }
      }
    }
  }
}