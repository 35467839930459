section.main.photo_video {
  //Основной заголовок
  .main-title {
    text-transform: uppercase;
    margin: 0 0 1em 0;

    a {
      color: #a1a1a1;
      display: inline-block;
      margin: 0 0 0 1em;
      font-size: .85em;

      span {
        display: none;
      }
    }

    @media #{$large-up} {
      font-size: 2em;
    }
  }

  //Список видео
  .videos-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -15px 1.5em -15px;

    article {
      flex-basis: 50%;
      background: $black;
      position: relative;
      color: $white;

      a {
        color: $white;
      }

      .img {
        background-size: cover;
        background-position: center center;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          display: block;
        }

        .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          @include svg-image("play-button", 50, 50);
          cursor: pointer;
          margin-left: -25px;
          margin-top: -25px;
        }
      }

      .meta {
        padding: .3em 30px .3em .5em;
        position: relative;
        @extend .clearfix;

        .arrow {
          position: absolute;
          right: 5px;
          top: 10px;
          @include svg-image("arrow-right-white", 10, 18);
        }

        .name {
          margin: 0 0 .1em 0;
        }

        .views {
          float: left;
          margin: 0 .7em 0 0;
          @extend .clearfix;

          .icon {
            float: left;
            @include svg-image("views-white", 11, 7);
            margin: 4px 3px 0 0;
          }

          .num {
            float: left;
            font-size: .85em;
          }
        }

        .tags {
          float: left;
        }
      }
    }

    @media #{$medium-up} {
      article {
        flex-basis: 33.3%;
        min-height: 60px;
      }
    }

    @media #{$large-up} {
      margin-bottom: 5em;

      article {
        .meta {
          padding: .5em 40px 1em 2em;

          .arrow {
            right: 10px;
            top: 25px;
            @include svg-image("arrow-right-white", 15, 27);
          }

          .name {
            font-size: 1.5em;
            margin-bottom: .5em;

          }

          .views {
            margin-right: 1.2em;

            .icon {
              @include svg-image("views-white", 19, 12);
            }

            .num {
              font-size: 1em;
            }
          }
        }
      }
    }
  }

  //Информация о фото/видео
  .item-info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba($black, .7);
    box-sizing: border-box;
    padding: .3em 0 0 0;
    color: $white;

    a {
      color: $white;
    }

    .name {
      font-size: 1.2em;
      margin: 0 0 .2em 0;
    }

    .meta {
      @extend .clearfix;

      > * {
        //Всех непосредственных потомков делаем одной высоты с одинаковыми отступами
        //снизу и сверху, чтобы при длинных словах все падало корретно ниже
        min-height: 1.7rem;
        box-sizing: border-box;
        margin-bottom: .5rem;
        margin-right: 1rem;
        float: left;
      }

      .views {
        padding-top: .15em;
        @extend .clearfix;

        .icon {
          float: left;
          @include svg-image("views-transparent", 15, 15);
          margin: 4px 3px 0 0;
        }

        .num {
          float: left;
          font-size: .85em;
          opacity: .8;
        }
      }

      .social {
        @extend .clearfix;

        a {
          display: inline-block;
          float: left;
          margin: 3px 8px 0 0;

          &.fb {
            @include svg-image("fb2-transparent", 15, 15);
          }

          &.inst {
            @include svg-image("inst-transparent", 15, 15);
          }

          &.twitter {
            @include svg-image("twitter-transparent", 15, 15);
          }
        }
      }

      .tags {
        padding-top: .1em;

        a {
          margin-right: .7em;
        }
      }
    }

    .place {
      background: #252525;
      border-top: 1px solid $white;
      padding: .2em 15px .2em 15px;
      margin-left: -15px;
      margin-right: -15px;
      @extend .clearfix;

      > * {
        //Всех непосредственных потомков делаем одной высоты с одинаковыми отступами
        //снизу и сверху, чтобы при длинных словах все падало корретно ниже
        min-height: 2rem;
        box-sizing: border-box;
        margin-bottom: .7rem;
        margin-right: .8rem;
        float: left;
      }

      .place-name {
        font-size: 1.1em;
        opacity: .8;
      }

      .place-type {
        opacity: .8;
        font-size: .85em;
        padding-top: .2em;
      }

      //Рейтинг, просмотры, лайки - исключительный блок. Он не флоатится
      .place-r-v-l {
        float: none;
        clear: both;
        min-height: 0;
        margin-bottom: 0;
        //padding-top: .35em;
      }
    }

    //Статическое положение блока
    &.static {
      position: static;
      width: auto;
    }

    //Серей бек у блока
    &.grey-bg {
      background: #4c4c4c;

      .place {
        background: #4c4c4c;
      }
    }

    //Минимальная высоту у блока с заведением
    &.with-place-min-height {
      .place {
        min-height: 70px;
      }
    }

    @media #{$large-up} {
      padding-top: 1em;

      .name {
        font-size: 2em;
      }

      .meta {
        > * {
          margin-right: 2em;
        }

        .views {
          .icon {
            @include svg-image("views-transparent", 19, 12);
          }

          .num {
            font-size: 1em;
          }
        }
      }

      .place {
        background: transparent;
        border-top-color: rgba($white, .3);
        padding: 0;
        margin: 0;
        font-size: 1.2em;
        min-height: 0 !important;
        padding-top: .5em;

        > * {
          min-height: 2.7rem;
          margin-right: 1.5rem;
        }

        .place-type {
          padding-top: .3em;
        }

        .place-location {
          padding-top: .3em;

        }

        //Теперь этот блок флоатится со всеми
        .place-r-v-l {
          clear: none;
          float: left;
          padding-top: .5em;
        }
      }
    }
  }

  //Автор
  .author-section {
    @extend .clearfix;
    margin: 0 0 2em 0;

    .avatar {
      float: left;
      width: 55px;
      height: 55px;
      background-size: cover;
      background-position: center center;
      background-image: image-url("svg-icons/ava-default.svg");
      border-radius: 50%;
    }

    .text {
      margin-left: 70px;

      small {
        margin: .7em 0 0 0;
        color: #c9c9c9;
        display: block;
        font-size: .85em;
      }

      .name {
        color: $black;
        font-size: 1.2em;
      }
    }
  }

  //Основной текст
  .main-text-section {
    color: #434343;
    font-size: 1.2em;
    margin: 0 0 2em 0;

    @media #{$large-up} {
      font-size: 1.5em;
    }
  }

  //Ссылки сверху
  .top-links {
    position: absolute;
    top: 1em;
    left: 15px;

    a {
      display: inline-block;
      color: $black;
      background-color: rgba(255, 255, 255, .5);
      padding: .5em 1em .5em 1em;
      font-size: .7em;
      border-radius: 1.8em;
      transition: background-color .3s;

      &:hover {
        text-decoration: none;
        background-color: $white;
      }
    }

    @media #{$large-up} {
      width: 100%;
      top: 110px;
      left: 0;

      .container {
        padding-left: 15px;
        padding-right: 15px;
      }

      a {
        font-size: 1.1em;
        margin-right: 1em;
      }
    }
  }

  //Галерея. Используем не стилизованный компонент галерери
  //стилизуем его сами, т.к. тут галерея по внешнему виду
  //отличается от классической
  .photo-gallery.gallery-component {
    margin: 0 0 1em 0;
    background: $black;

    .ct-wrap {
      position: relative;

      .ct-root {
        overflow: hidden;
        visibility: hidden;
        position: relative;

        .ct-list {
          overflow: hidden;
          position: relative;
        }
      }
    }

    //У инициализованной галерери все изображения видны
    &.__initialized {
      .ct-gallery-item {
        display: block;
      }
    }

    //Элемент галереи
    .ct-gallery-item {
      cursor: pointer;
      float: left;
      width: 100%;
      position: relative;
      display: none;

      //У не инициализованной галереи видно только первое изображение
      //иначе она растянется вертикально
      &:first-of-type {
        display: block;
      }

      .ct-main-img {
        background-position: center center;
        background-size: 30px 30px;
        background-image: image-url("main/preloader.gif");
        background-repeat: no-repeat;

        &[data-interchange] {
          background-size: cover;
          background-image: none;
        }

        img.main {
          display: inline-block;
          width: 100%;

          &.rectangle {
            display: none;
          }
        }
      }
    }

    //Миниатюры
    .minis-container {
      position: relative;

      .ct-minis {
        position: relative;
        overflow: hidden;
        margin-left: -15px;
        margin-right: -15px;

        .ct-list {
          @extend .clearfix;
          width: 20000em;
          position: relative;

          a {
            display: block;
            float: left;
            width: 70px;
            height: 70px;
            opacity: .6;

            &.__active {
              opacity: 1;
            }

            .ct-mini-bg {
              background-size: 20px 20px;
              background-image: image-url("main/preloader.gif");
              background-repeat: no-repeat;
              background-position: center center;
              cursor: pointer;
              height: 100%;

              &[data-interchange] {
                background-size: cover;
                background-image: none;
              }

              img {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .ct-control {
        position: absolute;
        top: 20px;
        cursor: pointer;

        &.__left {
          @include svg-image("arrow-left-white-thick", 11, 19);
          left: 10px;
        }

        &.__right {
          @include svg-image("arrow-right-white-thick", 11, 19);
          right: 10px;
        }
      }
    }

    @media #{$medium-up} {
      .ct-gallery-item {
        .ct-main-img {
          img.main {
            &.rectangle {
              display: inline-block;
            }

            &.square {
              display: none;
            }
          }
        }
      }
    }

    @media #{$large-up} {
      .minis-container {
        .ct-minis {
          margin-left: 35px;
          margin-right: 35px;

          .ct-list {
            a {
              width: 110px;
              height: 110px;
            }
          }
        }

        .ct-control {
          top: 30px;

          &.__left {
            @include svg-image("arrow-left-white-thick", 27, 46);
          }

          &.__right {
            @include svg-image("arrow-right-white-thick", 27, 46);
          }
        }
      }
    }
  }

  //Блок с видео
  .video-block {
    margin: 0 0 1.5em 0;

    .main-video {
      position: relative;
      background: $black;

      .container {
        padding-left: 0;
        padding-right: 0;
      }

      .flex-video {
        margin-bottom: 0;
      }
    }

    @media #{$large-up} {
      .main-video {
        .container {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}