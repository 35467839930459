.films-cards-component {
  @include components-mixin;

  margin-bottom: 53px;
  .ct-fc {
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -2px;
    }
    &-item {
      margin-left: 2px;
      margin-bottom: 2px;
      width: calc(100% / 3 - 2px);
      flex: 0 0 calc(100% / 3 - 2px);
      height: 482px;
      color: #fff;

      @media #{$xlarge-up} {
        width: calc(100% / 5 - 2px);
        flex: 0 0 calc(100% / 5 - 2px);
      }

      &-main {
        display: block;
        position: relative;
        margin-bottom: 2px;
        height: 390px;
        background-color: #D8D8D8;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: inherit;
        text-decoration: none;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          background-image: linear-gradient(180deg, rgba(0,0,0,0.15) 4%, rgba(0,0,0,0.65) 69%);
          transition: background .2s ease-in-out;
        }
        &-inner {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0 12px 16px;
          height: 100%;

          // @media #{$xxlarge-up} {
          //   padding: 0 24px 32px;
          // }
        }
        &:hover {
          &:before {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.15) 4%, rgba(0,0,0,0.65) 40%);
          }
        }
      }
      &-footer {
        position: relative;
        display: block;
        height: 90px;
        background-color: #000;
        color: inherit;
        text-decoration: none;
        &:hover {
          .ct-fc-item-book {
            opacity: 1;
            z-index: 1;
          }
        }
        &-content {
          padding: 18px 24px 0 20px;
        }
        &-icon-ticket {
          position: absolute;
          top: 0;
          right: 12px;
          padding-top: 8px;
          padding-left: 6px;
          width: 36px;
          height: 40px;
          background-color: #7AFF49;
          color: #000;
          > svg {
            width: 24px;
            height: 24px;
            color: currentColor;
          }
        }
      }
      &-rates {
        display: inline-block;
        margin-bottom: 12px;
        padding: 6px 14px 3px;
        color: #000;
        background-color: #fff;
        font-size: 12px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        box-shadow: 0 3px 0 0 #D6D6D6;
        > svg {
          width: 12px;
          height: 12px;
          fill: currentColor;
        }
        // @media #{$xxlarge-up} {
        //   font-size: 14px;
        // }
      }
      &-title {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.9px;
        line-height: 26px;
        // @media #{$xxlarge-up} {
        //   font-size: 21px;
        // }
      }
      &-duration {
        font-size: 12px;
        letter-spacing: 0.64px;
        opacity: 0.7;
        // @media #{$xxlarge-up} {
        //   font-size: 15px;
        // }
      }
      &-starttime {
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 1.03px;
        // @media #{$xxlarge-up} {
        //   font-size: 24px;
        // }
      }
      &-price {
        opacity: 0.7;
        font-size: 12px;
        letter-spacing: 0.64px;
        // @media #{$xxlarge-up} {
        //   font-size: 15px;
        // }
        &:before {
          content: '/';
          padding-right: 4px;
        }
      }
      &-place {
        opacity: 0.7;
        font-size: 12px;
        letter-spacing: 0.64px;
        // @media #{$xxlarge-up} {
        //   font-size: 15px;
        // }
      }
      &-book {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #7AFF49;
        font-size: 14px;
        color: #000;
        letter-spacing: 0.73px;
        transition: opacity 0.2s ease-in;
        // @media #{$xxlarge-up} {
        //   font-size: 17px;
        // }
      }
    }
  }
}
