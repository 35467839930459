section.main.people.experts {
  margin: 1.5em 0 0 0;

  //Основной заголовок
  .page-title {
    @extend .clearfix;
    font-size: 1.2em;
    margin: 0 0 1em 0;

    .text {
      @extend .clearfix;
      margin: 0 0 .8em 0;

      .icon {
        float: left;
        width: 25px;
        height: 25px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        margin: 0 .4em 0 0;

        &.authors {
          background-image: image-url("svg-icons/people-author-black.svg");
        }

        &.experts {
          background-image: image-url("svg-icons/people-expert-black.svg");
        }

        &.editors {
          background-image: image-url("svg-icons/people-editor-black.svg");
        }

        &.specialists {
          background-image: image-url("svg-icons/people-specialist-black.svg");
        }
      }

      em {
        font-style: normal;
        float: left;
        display: inline-block;
        margin: .2em 0 0 0;
        text-transform: uppercase;
      }
    }

    .button {
      display: inline-block;
      background: #ffe25f;
      color: $black;
      text-transform: uppercase;
      padding: .3em .7em;
    }
  }

  @media #{$large-up} {
    margin-top: 10em;

    //Основной заголовок
    .page-title {
      font-size: 1.5em;
      margin-bottom: 2em;

      .text {
        float: left;
        font-size: 1.2em;

        .icon {
          width: 35px;
          height: 35px;
        }

        em {
          margin-top: 0;
        }
      }

      .button {
        float: right;
      }
    }
  }

  @media #{$xlarge-up} {
    //Основной заголовок
    .page-title {
      .text {
        font-size: 1.4em;

        .icon {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}