//Сквозная форма
.main-search-form-container {
  position: relative;
  z-index: 200;

  //Форма поверх контента
  &.on-top {
    form.main-search-form {
      .outer-container {
        background: rgba(0, 0, 0, .5);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
  }

  form.main-search-form {
    .outer-container {
      padding: 10px 0 10px 0;
      display: none;
    }

    .container {
      position: relative;
    }

    .query-and-filters {
      .query-container {
        position: relative;

        .tags-and-input-container {
          border: 1px solid #ccc;
          background: #f2f2f2;
          padding-right: 30px;
          @extend .clearfix;

          .query {
            border: none;
            background: transparent;
            font-family: $font-family-sans-serif;
            font-size: 1.2rem;
            color: $black;
            height: 28px;
            width: 100%;
            padding: .3em 1em .3em .3em;
            outline: none;
            border-radius: 0;

            &::-moz-placeholder {
              font-size: 1rem;
            }
            &:-ms-input-placeholder {
              font-size: 1rem;
            }
            &::-webkit-input-placeholder {
              font-size: 1rem;
            }
          }

          .selected {
            display: inline-block;
            float: left;
            margin: 3px .5em 3px .4em;
            min-height: 22px;
            box-sizing: border-box;
            background: #FFA0A0;
            padding: .3em 1em;
            border-radius: 1.5em;
            font-size: .75em;
            text-transform: uppercase;

            .remove {
              @include svg-image("cross-grey", 7, 7);
              margin: 0 0 0 .5em;
              cursor: pointer;
            }

            &.red {
              background: #FFA0A0;
            }

            &.green {
              background: #88EC62;
            }

            &.grey {
              background: #e4e4e4;
            }

            &.color {
              color: #F875C6;
              border: 1px solid #F875C6;
              background: $white;
            }
          }
        }

        .autocomplete {
          display: none;
          position: absolute;
          left: 0;
          top: 100%;
          margin: 1px 0 0 0;
          width: 100%;
          max-height: 200px;
          overflow-y: auto;
          background: rgba(#E3E3E3, .95);
          z-index: 2;

          .elem {
            cursor: pointer;
            padding: .5em 1em;
            font-size: 1.1em;

            &:hover {
              background: darken(#e3e3e3, 7%);
            }
          }

          &.active {
            display: block;
          }
        }
      }

      .filters-container {
        .filter {
          float: left;
          width: 25%;
          box-sizing: border-box;
          padding: 0 .5em 0 .8em;
          background: #f2f2f2;
          position: relative;
          border-right: 1px solid #D1D1D1;
          height: 30px;

          //Триггер дропдауна
          .trigger {
            text-transform: uppercase;
            color: #666;
            position: relative;
            font-size: .7em;
            cursor: pointer;
            box-sizing: border-box;
            padding-top: .8em;
            height: 100%;

            .text {
              display: inline-block;
              position: relative;

              .caret {
                position: absolute;
                right: -12px;
                top: 3px;
                @include caret(#666, 5, down);
              }
            }

            .number {
              display: none;
              position: absolute;
              left: -1.3em;
              top: .7em;
              line-height: 1.2em;
              font-size: 1.1em;
              border-radius: 50%;
              width: 1.2em;
              height: 1.2em;
              text-align: center;
              background: #ffa0a0;
              color: $black;

              &.red {
                background: #ffa0a0;
              }

              &.green {
                background: #88ec62;
              }

              &.active {
                display: inline-block;
              }
            }
          }

          //Дропдаун
          .dropdown {
            @extend .clearfix;
            position: absolute;
            width: 130px;
            background: #f2f2f2;
            top: 100%;
            margin-top: 2px;
            left: 0;
            z-index: 10;
            display: none;
            max-height: 280px;
            overflow-y: scroll;

            .col {
              padding: 1em 0;

              &.col-2 {
                float: left;
                width: 50%;
                border-right: 1px solid #CDCDCD;

                &:last-child {
                  border-right: none;
                }
              }
            }

            .title {
              background: $white;
              padding: .5em;
              text-transform: uppercase;
            }

            .item {
              cursor: pointer;
              padding: .5em 20px .5em .5em;
              position: relative;
              word-break: break-all;

              .item-deselect {
                position: absolute;
                @include svg-image("cross-black", 10, 10);
                cursor: pointer;
                right: 6px;
                top: 10px;
                display: none;
              }

              &:hover {
                background: darken(#f2f2f2, 10);
                cursor: pointer;
              }

              &.active {
                background: darken(#f2f2f2, 10);

                .item-deselect {
                  display: block;
                }
              }
            }

            .divider {
              margin: .7em 0;
              border-top: 1px solid #CDCDCD;
            }

            .date {
              padding: .5em;
              @extend .clearfix;

              .date-title {
                margin: 0 0 .7em 0;
              }

              .date-select {
                background: transparent image-url("svg-icons/caret-down-black.svg") no-repeat 95% .7em;
                background-size: 11px 7px;
                border-radius: 0;
                padding: .3em 20px .3em .3em;
                border: 1px solid $black;
                cursor: pointer;
                outline: none;
                font-size: 1em;
                width: 80%;
                float: left;
                margin: 0 0 1em 0;

                &::-moz-placeholder {
                  color: $black;
                  opacity: 1;
                }

                &:-ms-input-placeholder {
                  color: $black;
                }

                &::-webkit-input-placeholder {
                  color: $black;
                }

                &:disabled {
                  cursor: default;
                  opacity: .5;
                }
              }

              em {
                display: inline-block;
                font-style: normal;
                width: 18%;
                float: left;
                margin: .4em 2% 0 0;
              }
            }

            .rating {
              @extend .clearfix;

              .star {
                float: left;
                margin: 0 3px 0 0;
                width: 15px;
                height: 15px;
                background-size: contain;
                background: image-url("svg-icons/star-black.svg") no-repeat;
              }
            }

            &.one-and-half {
              width: 180px;
            }

            &.double {
              width: 260px;
            }
          }

          &.what {
            width: 20%;
          }

          &.where {
            width: 20%;

            .dropdown {
              .item {
                &.near-me {
                  background-image: image-url("svg-icons/location-black.svg");
                  background-repeat: no-repeat;
                  background-position: 7px 10px;
                  background-size: 8px auto;
                  padding-left: 22px;
                }

                &.disabled {
                  cursor: default;
                  opacity: .8;

                  &:hover {
                    background-color: inherit;
                  }
                }
              }
            }
          }

          &.when {
            width: 27%;

            .dropdown {
              left: -90px;
            }
          }

          &.how-much {
            width: 33%;
            border-right: none;

            .dropdown {
              left: auto;
              right: 0;
            }
          }

          &.active {
            background: #6DCD4C;

            .trigger {
              color: $white;

              .text {
                .caret {
                  top: -2px;
                  @include caret($white, 5, up);
                }
              }
            }

            .dropdown {
              display: block;
            }
          }
        }
      }
    }

    .buttons-container {
      position: absolute;
      right: 15px;
      top: 0;

      button[type=submit] {
        width: 65px;
        height: 30px;
        display: none;
        border: none;
        background: #6dcd4c;
        color: $white;
        font-family: $font-family-sans-serif;
        font-size: 1em;
        outline: none;

        &::before {
          content: "ИСКАТЬ";
        }
      }

      .from-scratch {
        float: right;
        width: 30px;
        height: 30px;
        border: none;
        display: inline-block;
        background-color: #6dcd4c;
        background-image: image-url("icons/search-small.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 16px 16px;
        cursor: pointer;

        @media #{$retina} {
          background-image: image-url("icons-retina/search-small.png");
        }
      }
    }

    //Аквтиное состояние формы
    &.active {
      .query-and-filters {
        .query-container {
          .tags-and-input-container {
            padding-right: 60px;
          }
        }

        .filters-container {
          margin-right: 0;
        }
      }

      .buttons-container {
        button[type=submit] {
          display: inline-block;
        }

        .from-scratch {
          background: #359B12 image-url("svg-icons/search-tuning.svg") no-repeat center center;
          background-size: 20px 20px;
          position: absolute;
          top: 100%;
          right: 0;
        }
      }
    }
  }

  @media #{$large-up} {
    form.main-search-form {
      .outer-container {
        //Форма показыватеся/скрывается с помощью jQuery slideDown/Up
        //Этот метод устанавливает свойство display. Чтобы на больших экранах
        //форма всегда была видна сделаем вот так:
        display: block !important;

        padding-top: 20px;
        padding-bottom: 20px;
      }

      .query-and-filters {
        margin-right: 130px;

        .query-container {
          float: left;
          width: 49%;
          margin-right: 1% !important;

          .tags-and-input-container {
            border: none;
            padding-left: 45px;
            padding-right: 0;
            background-image: image-url("icons/search-grey.png");
            background-position: 10px 12px;
            background-repeat: no-repeat;
            background-size: 22px 22px;

            @media #{$retina} {
              background-image: image-url("icons-retina/search-grey.png");
            }

            .query {
              font-size: 1.5rem;
              height: 50px;
              padding: 0 .4em .3em 0;

              &::-moz-placeholder {
                font-size: 1.1rem;
                padding-top: .2em;
              }
              &:-ms-input-placeholder {
                font-size: 1.1rem;
                padding-top: .2em;
              }
              &::-webkit-input-placeholder {
                font-size: 1.1rem;
                padding-top: .2em;
              }
            }

            .selected {
              font-size: .9em;
              margin-top: .8em;
              margin-bottom: 0;

              .remove {
                width: 9px;
                height: 9px;
              }
            }
          }

          .autocomplete {
            margin-top: 3px;

            .elem {
              font-size: 1.4em;
            }
          }
        }

        .filters-container {
          float: left;
          width: 50%;
          margin-right: 0;

          .filter {
            height: 50px;
            padding: 0;

            //Триггер дропдауна
            .trigger {
              padding: 1.2em .5em 0 1.6em;
              font-size: 1em;

              .text {
                .caret {
                  right: -13px;
                  top: 4px;
                }
              }

              .number {
                left: .25em;
                top: 1.4em;
                font-size: .9rem;
                width: 16px;
                height: 16px;
                line-height: 16px;
                box-sizing: border-box;
                //padding-top: 2px;
              }
            }

            //Дропдаун
            .dropdown {
              font-size: 1.2em;
              max-height: 400px;
              width: 180px;

              .title {
                padding-left: .8em;
              }

              .item {
                padding-left: .8em;
              }

              .date {
                padding-left: .8em;
                padding-right: .8em;

                .date-select {
                  background-position: 95% .8em;
                }
              }

              &.one-and-half {
                width: 220px;
              }

              &.double {
                width: 345px;
              }
            }

            &.what {
              width: 21%;
            }

            &.where {
              width: 21%;

              .dropdown {
                .item {
                  &.near-me {
                    background-position: 10px 11px;
                    background-size: 10px auto;
                    padding-left: 30px;
                  }
                }
              }
            }

            &.when {
              width: 26%;

              .dropdown {
                left: 0;
              }
            }

            &.how-much {
              width: 32%;
            }
          }
        }
      }

      .buttons-container {
        button[type=submit] {
          color: $white;
          font-family: $font-family-sans-serif;
          font-size: 1.3rem;
        }

        .from-scratch {
          position: static !important;
          background-image: none;
          color: $white;
          height: 50px;
          width: 125px;
          text-align: center;
          font-size: 1.1em;
          padding-top: 1em;

          &::before {
            content: "НАЧАТЬ ПОИСК";
          }

          &:hover {
            text-decoration: none;
          }
        }
      }

      //Активная форма
      &.active {
        .query-and-filters {
          margin-right: 150px;

          .query-container {
            .tags-and-input-container {
              padding-right: 0;
            }
          }
        }

        .buttons-container {
          button[type=submit] {
            height: 50px;
            width: 90px;
            margin-right: 5px;
          }

          .from-scratch {
            background: #359B12 image-url("svg-icons/search-tuning.svg") no-repeat center center;
            background-size: 35px 35px;
            width: 50px;

            &::before {
              content: "";
            }
          }
        }
      }
    }
  }

  @media #{$xlarge-up} {
    form.main-search-form {
      .query-and-filters {
        margin-right: 160px;

        .filters-container {
          .filter {
            .trigger {
              padding-top: .8em;
              font-size: 1.2em;

              .text {
                .caret {
                  top: 7px;
                  right: -15px;
                }
              }

              .number {
                top: 1.1em;
                width: 18px;
                height: 18px;
                line-height: 18px;
                left: .35em;
                //padding-top: 3px;
              }
            }

            &.active {
              .trigger {
                .text {
                  .caret {
                    top: 4px;
                  }
                }
              }
            }
          }
        }
      }

      .buttons-container {
        button[type=submit] {

        }

        .from-scratch {
          font-size: 1.2em;
          padding-top: .8em;
          width: 155px;
        }
      }
    }
  }
}
