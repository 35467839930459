.opinion-button-component {
  @include components-mixin;
  $color-green: #85F093;
  display: inline-block;
  background-color: $color-green;
  border-radius: 1.5em;
  color: black;
  padding: .7em 1.2em .7em 50px;
  text-transform: uppercase;
  font-size: 1.23rem;
  box-shadow: 0 .2em .5em rgba(black, .3);
  position: relative;
  z-index: 3;
  transition: background-color .3s;
  &:hover {
    color: black;
    text-decoration: none;
    background-color: lighten($color-green, 3%); }
  img {
    position: absolute;
    left: 20px;
    top: 12px;
    width: 22px;
    height: 23px; } }
