//Просмотры
.views-component {
  @include components-mixin;
  position: relative;
  padding-left: 25px;


  .ct-icon {
    position: absolute;
    top: .2em;
    left: 0;
    @include sprite-image("views-component-icon-small", 19, 12);
  }

  .ct-num {
    color: $white;
    font-size: .7em;
  }

  &.__grey {
    .ct-icon {
      @include sprite-image("views-component-icon-grey-small", 19, 12);
    }

    .ct-num {
      color: #bebebe;
    }
  }

  &.__transparent {
    .ct-icon, .ct-num {
      opacity: .8;
    }
  }

  @mixin large() {
    padding-left: 25px;

    .ct-icon {
      @include sprite-image("views-component-icon-big", 19, 12);
    }

    .ct-num {
      font-size: 1em;
    }

    &.__grey {
      .ct-icon {
        @include sprite-image("views-component-icon-grey-big", 19, 12);
      }
    }
  }

  //Одного размера на всех экранах
  &.__one-size {
    @include large();
  }

  //Большой блок
  &.__big {
    @include large();
  }

  @media #{$large-up} {
    @include large();

    &.__big {
      padding-left: 38px;

      .ct-icon {
        @include sprite-image("views-component-icon-very-big", 29, 18);
      }

      .ct-num {
        font-size: 1.3em;
      }

      &.__grey {
        .ct-icon {
          @include sprite-image("views-component-icon-grey-very-big", 29, 18);
        }
      }
    }
  }
}