//Подвал
footer.main {
  padding: 2em 0 3em 0;
  font-size: 1rem;

  .text {
    margin: 0 0 1em 0;

    .logo {
      @include svg-image("logo-footer", 52, 18);
    }

    ul {
      list-style: none;
      margin: .7em 0 1em 0;
      padding: 0;

      li {
        margin: 0 0 .6em 0;

        a {
          color: $base;
        }
      }
    }

    .copy {
      color: $base;
    }

    .age-limit {
      font-size: 1.8em;
      background-color: #efefef;
      color: #a3a3a3;
      display: inline-block;
      padding: 13px 13px;
      margin-top: 10px;
    }

    .mobile-link {
      margin: 1em 0 1.5em 0;
      display: block;
      color: $base;
    }
  }

  .counters {
    margin: 0 0 1.5em 0;
  }

  .line-small {
    border-bottom: 1px solid #aaa;
    margin: 0 -5px 1.5em -5px;
  }

  .menu {
    display: none;
  }

  .apps {
    @extend .clearfix;
    margin: 0 0 .5em 0;

    a {
      display: inline-block;
      float: left;

      &.apple {
        @include svg-image("app-store", 135, 40);
        margin-right: 10px;
      }

      &.google {
        @include sprite-image("google-play", 135, 40);
      }
    }
  }

  .orphus {
    margin: 0 0 2em 0;
    color: #999;
    font-size: .85rem;

    a {
      display: none;
    }
  }

  //Группы vk и facebook
  .social-widgets {
    @extend .clearfix;

    .vk {
      margin: 0 0 1.5em 0;
      width: 290px;
      float: left;
    }

    .fb {
      float: left;
      width: 290px;
    }

    @media #{$medium-up} {
      .vk {
        margin-right: 10px;
      }
    }

    @media #{$large-up} {
      float: right;
    }
  }

  @media #{$large-up} {
    padding-top: 5em;

    .text {
      float: left;
      width: 20%;
      box-sizing: border-box;

      ul {
        li {
          margin-bottom: .2em;
        }
      }

      .mobile-link {
        margin-bottom: 0;
      }
    }

    .menu {
      display: block;
      float: right;
      width: 80%;
      font-size: .85em;

      .col {
        float: left;
        width: 25%;
        box-sizing: border-box;
        padding-left: .5em;

        a.title {
          text-transform: uppercase;
          color: $black;
          display: inline-block;
          margin: 0 0 .7em 0;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            padding: 0;
            word-break: break-all;

            a {
              color: #A3A3A3;
            }
          }

          &.sub {
            margin: -.3em 0 .8em .6em;
          }
        }
      }
    }

    .counters {
      margin-top: 2em;
      float: left;
    }

    .line-small {
      display: none;
    }

    .line-large {
      clear: both;
      padding-top: 2em;
      border-bottom: 1px solid #aaa;
    }

    .apps {
      margin-top: 2em;
      float: right;
    }

    .orphus {
      clear: both;
      text-align: right;
    }
  }

  @media #{$xlarge-up} {
    font-size: 1.4rem;

    .text {
      width: 25%;

      .copy {
        font-size: .7em;
      }

      .mobile-link {
        font-size: .75em;
      }
    }

    .menu {
      width: 75%;
    }
  }
}
