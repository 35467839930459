//Показать больше
.show-more-component {
  @include components-mixin;

  text-align: center;
  background: #F0F0F0;
  text-transform: uppercase;
  padding: 1em 1em;
  font-size: .9rem;

  .ct-link {
    color: $_font-color-transparent-black !important;
  }

  &.__dark {
    background: #606060;

    .ct-link {
      color: $_font-color-transparent !important;
    }
  }

  &.__very-dark {
    background: rgba(0, 0, 0, .77);

    .ct-link {
      color: rgba(255, 255, 255, .4) !important;
    }
  }

  &.__transparent {
    background: rgba(240,240,240, .4);
  }

  &.__light-green {
    background: #E1E856;
  }

  &.__disabled {
    pointer-events: none;
    opacity: .5;
  }

  @media #{$large-up} {
    font-size: 1.2rem;
  }
}