section.main.errors {
  margin: 1.5em 0 6em 0;

  .text {
    font-size: 1.4em;

    &.upcase {
      text-transform: uppercase;
    }
  }

  //404
  &.not-found {
    .title {
      color: #E4E4E4;
      text-transform: uppercase;
      font-size: 2em;
      margin: 0 0 .5em 0;
    }

    .with-arrow {
      position: relative;

      .arrow {
        position: absolute;
        right: 50px;
        bottom: 100%;
        margin: 0 0 10px 0;
        @include svg-image("404-arrow", 40, 40);
      }
    }
  }

  //Город не найден
  &.city {
    .icon {
      @include svg-image("errors-city", 64, 55);
      margin: 0 0 .5em 0;
    }
  }

  @media #{$large-up} {
    margin-bottom: 15em;
    .text {
      font-size: 2.7em;
    }

    //404
    &.not-found {
      .title {
        font-size: 6em;
      }

      .with-arrow {
        margin-right: 200px;

        .arrow {
          @include svg-image("404-arrow", 100, 100);
          right: -100px;
          bottom: 1em;
          margin: 0;
        }
      }
    }

    //Город не найден
    &.city {
      .icon {
        @include svg-image("errors-city", 128, 111);
        margin-bottom: 2em;
      }
    }
  }

  @media #{$xlarge-up} {
    &.not-found {
      .with-arrow {
        margin-right: 400px;
      }
    }
  }
}