//Пойду
.go-component {
  @include components-mixin;

  font-size: .8rem;
  height: 20px;
  width: 130px;

  .ct-subscribed {
    height: 100%;
    float: left;
    width: 30%;
    box-sizing: border-box;
    background: #252525;
    position: relative;
    padding: .3em 0 0 20px;

    .ct-icon {
      position: absolute;
      left: 4px;
      top: 4px;
      display: inline-block;
      @include sprite-image("go-component-user-icon-small", 11, 10);
    }

    .ct-number {
      color: $white;
      font-size: .9em;
    }
  }

  .ct-link {
    height: 100%;
    padding: .3em 0 0 0;
    display: inline-block;
    float: left;
    width: 70%;
    text-transform: uppercase;
    color: $black !important;
    text-align: center;
    background: #FFE25F;
  }

  //Крупный вид блока
  @mixin large() {
    width: 230px;
    font-size: 1.4rem;
    height: 40px;

    .ct-subscribed {
      padding-left: 35px;
      padding-top: .4em;

      .ct-icon {
        top: 8px;
        left: 9px;
        @include sprite-image("go-component-user-icon-big", 19, 19);
      }
    }

    .ct-link {
      padding-top: .4em;
    }
  }

  &.__full-width {
    width: 100% !important;
  }

  &.__one-size {
    @include large;
  }


  @media #{$large-up} {
    @include large;
  }
}