//Небольшая доработка специально для сафари
//В сафари глюк у первого элемента - он смещен в право на 1 пиксель
//из-за этого первый ряд разваливается
html.safari {
  .events-tile-component {
    .ct-item {
      &:first-of-type {
        -webkit-margin-start: -1px;
      }
    }
  }
}

//Плитка событий
.events-tile-component {
  display: flex;
  flex-wrap: wrap;

  .ct-item {
    cursor: pointer;
    width: 50%;
    font-size: 1rem;
    position: relative;
    background-size: cover;
    color: $white;
    display: flex;
    box-sizing: border-box;
    min-height: 130px;

    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }

    .ct-gradient {
      padding: 10px;
      width: 100%;
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-align-content: flex-end;
      align-content: flex-end;
    }

    .ct-like-recommends {
      position: absolute;
      right: 0;
      top: 0;
    }

    .ct-for-children {
      @include sprite-image("places-tile-component-for-children-small", 10, 12);
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .ct-like-recommends + .ct-for-children {
      right: 90px;
    }

    .ct-type {
      padding: .1em .3em;
      font-size: .7em;
      position: absolute;
      left: 3px;
      top: 3px;
      background: #FBFF85;
      color: $black;

      a {
        color: lighten($black, 10%);
      }
    }

    .ct-date {
      position: absolute;
      left: 10px;
      top: 2.5em;
      color: $_font-color-transparent;
      text-transform: uppercase;
      font-size: .8em;

      a {
        color: $_font-color-transparent;
      }

    }

    .ct-name {
      width: 100%;
      margin: 4em 0 .3em 0;

      a {
        display: inline-block;
      }
    }

    .ct-location {
      width: 100%;
    }

    .ct-tags {
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
    }

    //Ссылка-заглушка
    &.__plug {
      background-image: linear-gradient(135deg, #C0FFF5, #9878CD);
      display: block;
      color: $white;

      .ct-spacer {
        height: 140px;
      }

      .ct-block {
        text-align: center;
        width: 130px;
        height: 70px;
        left: 50%;
        top: 50%;
        position: absolute;
        margin-top: -35px;
        margin-left: -65px;

        .ct-icon {
          @include sprite-image("events-tile-component-magnifier", 37, 37)
        }

        .ct-text {
          text-transform: uppercase;
        }
      }

      //Заглушку с таким классом на небольших
      //экранах не показываем - она висит внизу одна некрасиво
      &.__2 {
        display: none;
      }
    }
  }

  @media #{$medium-up} {
    .ct-item {
      min-height: 190px;
    }
  }

  @media #{$large-up} {
    .ct-item {
      min-height: 220px;

      .ct-gradient {
        padding: 20px;
      }

      .ct-for-children {
        @include sprite-image("places-tile-component-for-children-big", 14, 17);
        right: 15px;
        top: 15px;
      }

      .ct-like-recommends + .ct-for-children {
        right: 130px;
        top: 6px;
      }

      .ct-name {
        font-size: 1.6em;
      }

      .ct-date {
        left: 20px;
        top: 4.5em;
        font-size: 1em;
      }

      .ct-type {
        color: $_font-color-transparent-black;
        padding-left: .4em;
        padding-right: .4em;
        left: 20px;
        top: 20px;
        font-size: 1em;
      }

      .ct-tags {
        padding-left: 17px;
      }

      &.__plug {
        .ct-spacer {
          height: 200px;
        }
      }
    }
  }

  @media #{$xlarge-up} {
    .ct-item {
      -webkit-flex-basis: 25%;
      flex-basis: 25%;

      &.__plug {
        //в 2 и 3 ячейки
        &.__2 {
          -webkit-flex-basis: 50%;
          flex-basis: 50%;
          display: block;
        }

        &.__3 {
          -webkit-flex-basis: 75%;
          flex-basis: 75%;
        }
      }
    }
  }
}