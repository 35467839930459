/*
Компоненты верстаются по определенным правилам
Есть неакая аналогия с БЭМом, но лишь примерная
Каждый компонент состоит из Блока - корневого элемента и Элементов
и блок и элементы могут иметь модификаторы
класс блока заканчивается на component. Например: title-component
Класс элемента начинается с ct- (сокращенно component)
Например ct-icon. Класс модификатора начинается с __,
Например __blue. Каждый компонент должен в начале подлючить примесь components-mixin
*/

.components-test-container {
  @extend .container;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  &.black {
    background: black;
  }
}

.components-test-heading {
  font-size: 1.3rem;
  margin: 0 0 .3em 0;

  &.white {
    color: white;
  }
}

@mixin components-mixin() {
  font-size: 1rem;
}


@import "central_place",
        "e-p-types",
        "infinity_scroll",
        "news_block",
        "news_blocks_old",
        "sidebar",
        "social_modal",
        "subscribe",
        "share",
        "share_on_select",
        "photos_list",
        "news_tile",
        "overviews_tile",
        "events_tile",
        "title",
        "title_with_icon",
        "select_tabs",
        "rate",
        "fullscreen_image",
        "wysiwyg",
        "rating",
        "location",
        "views",
        "likes",
        "photos",
        "website",
        "phone",
        "tags",
        "sections",
        "section",
        "show_more",
        "go",
        "photo_tile",
        "social",
        "like_recommends",
        "pagination",
        "r_v_l",
        "active_people",
        "video",
        "gradient",
        "places_tile",
        "gallery",
        "expand",
        "tabs",
        "sticky_banner",
        "buy_ticket",
        "url_control",
        "title_and_menu",
        "editors_choise",
        "meta_info",
        "universal_gallery",
        "misclick",
        "modal_18",
        "opinion",
        "modal",
        "rating2",
        "opinion_button",
        "opinion_filter",
        "telegram_link",
        "tabs-nav",
        "films-cards",
        "places-cards",
        "people",
        "top-events";
