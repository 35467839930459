.title-and-menu-component {
  @extend .clearfix;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 0 1.5em 0;

  .ct-title {
    font-size: 1.69em;
    margin: 0 0 .4em 0;
  }

  .ct-menu {
    @extend .clearfix;

    > a {
      display: inline-block;
      color: black;
      float: left;
      margin: 0 1.2em .5em 0;
      font-size: 1.38em;

      &:hover, &.__active {
        text-decoration: none;
        border-bottom: 3px solid black;
      }
    }

    .ct-dropdown {
      float: left;
      position: relative;

      .ct-dd-trigger {
        @extend .clearfix;
        font-size: 1.38em;
        cursor: pointer;

        span {
          display: inline-block;
          float: left;
          @include text-elipsis;
          max-width: 5em;
        }

        i {
          display: inline-block;
          float: left;
          @include caret(black, 10, "down");
          position: relative;
          top: 6px;
          margin: 0 0 0 5px;
        }
      }

      .ct-dd-menu {
        position: absolute;
        top: 110%;
        right: 0;
        background: white;
        max-width: 200px;
        box-shadow: 0 0 5px rgba(black, .2);
        display: none;

        span {
          cursor: pointer;
          display: block;
          padding: .6em .7em;
          border-bottom: 1px solid #ddd;

          &:hover, &.__active {
            background: #f8f8f8;
          }
        }
      }

      &.__opened {
        .ct-dd-menu {
          display: block;
        }

        .ct-dd-trigger {
          i {
            top: -2px;
            @include caret(black, 10, "up");
          }
        }
      }
    }
  }

  @media #{$large-up} {
    .ct-title {
      font-size: 2.62em;
      float: left;
    }

    .ct-menu {
      margin-top: 5px;
      float: right;

      > a {
        margin-right: 1.7em;
      }
    }
  }
}