// For preview markup
section.main.index.index-page {
  // Новости
  .news-tile-component.test {
    margin-bottom: 2em;
    .container {
      padding: 0;
    }

    .ct-positions {
      margin: 0;
    }

    article {
      width: 50%;
      float: left;
      position: relative;
      color: $white;
      margin-bottom: 0;
      .gradient-component {
        min-height: 150px;
        padding: 10px;
      }
      a.ct-title, .ct-title {
        color: $white;
        font-size: 1.3em;
        margin-top: 10px;
        display: inline-block;
        // 
        padding: 0;
        position: relative;
      }
      .ct-icons {
        background: none;
        position: relative;
        span {
          box-sizing: content-box;
          padding-left: 25px;
          margin-right: 20px;
          width: auto;
        }
      }
      .ct-meta-top {
        margin-bottom: 10px;
        .ct-label {
          padding: 4px 6px;
          color: black;
          background-color: $white;
          font-size: 0.8em;
          margin-right: 10px;
        }
        .ct-link-all {
          color: $white;
          position: relative;
          &:after {
            content: '';
            @include svg-image("arrow-right-white-thick", 12, 18);
            position: relative;
            top: 3px;
            left: 5px;
          }
        }
        .ct-date {
          float: right;
          padding-top: 3px;
          opacity: 0.6;
        }
      }
      &:first-child {
        width: 100%;
        margin-bottom: 1px;
        .ct-image {
          img {
            min-height: 160px;
            max-height: 320px;
          }
        }
        .ct-title {
          font-size: 1.4em;
        }
      }
    }


    @media #{$medium-up} {
      article {
        .gradient-component {
          min-height: 190px;
        }
      }
    }

    @media #{$large-up} {
      margin-bottom: 4em;
      article {
        width: 25%;
        &:first-child {
          .gradient-component {
            padding: 35px 63px;
          }
          .ct-title {
            font-size: 2.1em;
            max-width: 50%;
          }
          .ct-image {
            width: 45%;
            float: right;
          }
          .ct-meta-top {
            width: 300px;
            font-size: 1.4em;
            display: inline-block;
            .ct-date {
              padding-top: 0;
            }
          }
          .ct-meta-bottom {
            display: inline-block;
          }
        }
      }
    }
  }
}
