.subscribe-component {
  background-image: linear-gradient(135deg, #E7FF43, #21AFE8);
  padding: 30px 30px 35px 30px;
  margin: 0 0 2em 0;
  @extend .clearfix;

  $mob-width: 250px;

  .ct-title {
    @extend .clearfix;
    margin: 0 auto 1.5em auto;
    width: $mob-width;

    i {
      display: inline-block;
      float: left;
      @extend .svg-logo-black-small;
    }

    b {
      display: inline-block;
      float: right;
      text-transform: uppercase;
      font-size: 1.69em;
      font-weight: bold;
    }
  }

  .ct-form {
    margin: 0 auto;
    width: $mob-width;
    box-sizing: border-box;

    .ct-email {
      display: block;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-size: 1.38em;
      padding: .4em 1em;
      box-shadow: 0 0 10px rgba(black, .21);
      border: none;
      outline: none;

      &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: black;
      }

      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: black;
        opacity:  1;
      }
    }
  }

  @media #{$large-up} {
    padding-bottom: 25px;

    .ct-title {
      float: left;
      width: 500px;
      margin-bottom: 0;

      i {
        @include svg-logo-black;
      }

      b {
        font-size: 3.54em;
      }
    }

    .ct-form {
      float: right;
      width: 250px;

      .ct-email {
        font-size: 1.23em;
        padding: 1.3em 1em;
      }
    }
  }

  @media #{$xlarge-up} {
    padding-left: 70px;
    padding-right: 70px;

    .ct-form {
      width: 365px;
    }
  }
}