//Окно 18+
.modal-18 {
  text-align: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  color: white;
  box-sizing: border-box;
  position: fixed;
  background: black;
  padding-top: 2em;
  .logo-container {
    width: 70px;
    padding: 1em;
    margin: 0 auto 1em auto;
    background: #85F093; }
  .title {
    font-size: 1.3em; }
  .agree {
    margin: .5em 0;
    font-size: 2em; }
  .buttons {
    a {
      cursor: pointer;
      background: #FFE25F;
      border: none;
      display: inline-block;
      width: 6em;
      padding: .7em 0;
      text-transform: uppercase;
      font-size: 1.3em;
      text-align: center;
      color: black;
      margin: 0 .5em;
      &:hover {
        text-decoration: none; }
      &.__green {
        background: #85F093; } } }

  @media #{$large-up} {
    padding-top: 4em;
    .container {
      max-width: 600px; }
    .logo-container {
      width: 110px;
      margin-bottom: 2em;
      img {
        width: 70px; } }
    .title {
      font-size: 2em; } } }
