//Соцсети
.social-component {
  @include components-mixin;

  .ct-item {
    float: left;
    display: inline-block;
    margin: 0 5px 0 0;

    &.__fb {
      @include sprite-image("social-component-fb-grey-small", 11, 12);
    }

    &.__inst {
      @include sprite-image("social-component-inst-grey-small", 11, 12);
    }

    &.__twitter {
      @include sprite-image("social-component-twitter-grey-small", 12, 9);
      margin-top: 2px;
    }
  }

  &.__white {
    .ct-item {
      &.__fb {
        @include sprite-image("social-component-fb-white-small", 11, 12);
      }

      &.__inst {
        @include sprite-image("social-component-inst-white-small", 11, 12);
      }

      &.__twitter {
        @include sprite-image("social-component-twitter-white-small", 12, 9);
      }
    }
  }

  &.__color {
    .ct-item {
      margin-right: 8px;

      &.__fb {
        @include sprite-image("social-component-fb-color", 26, 26);
      }

      &.__inst {
        @include sprite-image("social-component-inst-color", 26, 26);
      }

      &.__twitter {
        @include sprite-image("social-component-twitter-color", 28, 21);
      }
    }
  }

  @media #{$large-up} {
    .ct-item {
      margin-right: 10px;

      &.__fb {
        @include sprite-image("social-component-fb-grey-big", 16, 16);
      }

      &.__inst {
        @include sprite-image("social-component-inst-grey-big", 16, 16);
      }

      &.__twitter {
        @include sprite-image("social-component-twitter-grey-big", 16, 12);
      }
    }

    &.__white {
      .ct-item {
        &.__fb {
          @include sprite-image("social-component-fb-white-big", 16, 16);
        }

        &.__inst {
          @include sprite-image("social-component-inst-white-big", 16, 16);
        }

        &.__twitter {
          @include sprite-image("social-component-twitter-white-big", 16, 12);
        }
      }
    }
  }

  &.__rounded {
    .ct-item {
      width: 40px;
      height: 40px;
      color: rgba(255, 255, 255, .35);
      &:not(:last-child) {
        margin-right: 13px;
      }
      @media #{$large-up} {
        &:last-child {
          margin-right: 0;
        }
      }
      &:hover {
        color: #6DCD4C;
      }
      > svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
