section.main.profile.index {
  //Правая колонка
  .rightcol {
    .name {
      text-transform: uppercase;
      font-size: 1.2em;
      margin: 0 0 .6em 0;

      input {
        border: 1px solid #E3E3E3;
        display: block;
        width: 100%;
        box-sizing: border-box;
        border-radius: 2px;
        padding: .1em .3em;
      }
    }

    .social {
      @extend .clearfix;
      margin: 0 0 1em 0;

      a {
        float: left;
        display: inline-block;
        margin: 0 10px 0 0;

        &.vk {
          @include svg-image("vk-color", 44, 30);
        }

        &.fb {
          @include svg-image("fb-color", 44, 30);
        }
      }
    }

    //Табличка с данными
    .table-data {
      text-transform: uppercase;
      display: table;
      width: 100%;

      .row {
        display: table-row;

        .cell {
          display: table-cell;
          border-bottom: 1px solid #dadada;
          padding: .8em 0 .8em 0;

          &:first-child {
            width: 100px;
            font-size: .85em;
            color: #a7a7a7;

          }

          input, select, textarea {
            border: 1px solid #E3E3E3;
            border-radius: 2px;
            padding: .1em .3em;
            background: $white;
            display: inline-block;
            width: auto;
            outline: none;
          }

          textarea {
            display: block;
            width: 100%;
            box-sizing: border-box;
            height: 7em;
          }
        }
      }

      //Таблица - больше не таблица
      &.other {
        margin: 2em 0 2em 0;
        display: block;

        .row {
          display: block;
          margin: 0 0 .7em 0;

          .cell {
            display: block;
            border-bottom: none;
            padding: 0;
            margin: 0 0 .4em 0;
          }

          //На карте
          &.on-map {
            .on-map-text {
              display: none;
            }

            .map {
              margin-left: -15px;
              margin-right: -15px;

              .map-itself {
                height: 120px;
              }
            }
          }

          //О себе
          &.about {
            .cell {
              &.text {
                text-transform: none;
                color: #717171;
                font-size: .9em;
              }
            }
          }

          //Теги
          &.tags {
            .items {
              @extend .clearfix;

              .item {
                float: left;
                border: 1px solid #F875C6;
                border-radius: 1.5em;
                padding: .3em .7em;
                background: $white;
                color: #F875C6;
                text-transform: uppercase;
                margin: 0 .5em .8em 0;
                font-size: .9em;

                &:nth-child(2n) {
                  border-color: #F07373;
                  color: #F07373;
                }

                &:nth-child(3n) {
                  border-color: #33A4D7;
                  color: #33A4D7;
                }

                &:nth-child(4n) {
                  border-color: #5087E5;
                  color: #5087E5;
                }

                &:nth-child(5n) {
                  border-color: #50E8D2;
                  color: #50E8D2;
                }

                &:hover, &.active {
                  text-decoration: none;
                  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 1);
                }
              }
            }
          }
        }
      }
    }
  }

  @media #{$large-up} {
    //Правая колонка
    .rightcol {
      .name {
        font-size: 1.7em;
        padding-top: .4em;
      }

      //Табличка с данными
      .table-data {
        text-transform: none;
        font-size: 1.2em;

        .row {
          .cell {
            border-bottom: none;
            padding-top: .7em;
            padding-bottom: .7em;

            &:first-child {
              text-align: right;
              width: 9em;
              padding-right: 15px;
              font-size: 1em;

              &:after {
                content: ":";
                display: inline-block;
              }
            }
          }
        }

        &.other {
          margin-top: 0;
          display: table;

          .row {
            display: table-row;
            margin: 0;

            .cell {
              display: table-cell;
              padding: .7em 0 .7em 0;
              margin: 0;

              &:first-child {
                text-align: right;
                width: 9em;
                padding-right: 15px;
                font-size: 1em;
              }
            }

            &.on-map {
              .on-map-text {
                display: table-cell;
                vertical-align: top;
              }

              .map {
                margin: 0;

                .map-itself {
                  width: 250px;
                }
              }
            }

            &.about {
              .cell {
                &.text {
                  font-size: .8em;
                }
              }
            }
          }
        }
      }
    }
  }
}