//Блок Главное
.news-block-component {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 2em 0;

  //Основной строительный элемент
  .ct-nb-item {
    box-sizing: border-box;
    flex-basis: 100%;
    //Для того, чтобы блок с градиентом, который внутри занимал всю высоту
    display: flex;
    background-size: cover;
    background-position: center center;
    color: white;
    font-style: normal;
    position: relative;
    @extend .clearfix;
    overflow: hidden;

    a {
      color: white;

      &:hover {
        text-decoration: none;
      }
    }

    .ct-nb-gradient {
      padding: 10px;
      flex-basis: 100%;
      position: relative;
      @extend .clearfix;
    }

    //Основной заголовок
    .ct-nb-title {
      font-size: 1.08em;
      line-height: 1.29;
      display: block;
      font-weight: bold;
      margin: 0 0 .4em 0;

      &.__on-white {
        color: black;
      }

      &.__thin {
        font-weight: normal;
      }
    }

    //Изображение
    .ct-nb-image {
      display: block;
      width: 100%;
      max-width: 460px;
      margin: 1em 0 .8em 0;
      box-shadow: 0 0 20px rgba(black, .21);

      img {
        width: 100%;
      }
    }

    //Адрес
    .ct-nb-location {
      margin-bottom: .9em;
      position: relative;
      padding-left: 9px;
      font-size: .54rem;
      @include text-ellipsis;

      i {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 1px;
        @extend .svg-location-xsmall;
      }

      &.__transparent {
        opacity: .6;
      }
    }

    //Теги
    .ct-nb-tags {
      margin-bottom: .9em;
      opacity: .6;
      font-size: .54em;

      a, span {
        display: inline-block;
        margin: 0 .5em .5em 0;

        &::before {
          display: inline-block;
          content: "#";
        }
      }
    }

    //Описание
    .ct-nb-description {
      font-size: 1.08em;
      line-height: 1.29;
      margin: 0 0 .9em 0;
    }

    //Иконки
    .ct-nb-meta-bottom {
      opacity: .6;
      @extend .clearfix;

      > span {
        display: inline-block;
        margin: 0 10px 5px 0;
        padding-left: 13px;
        position: relative;
        float: left;
        font-size: .54em;

        &:last-of-type {
          margin-right: 0;
        }

        i {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
        }

        &.__photo {
          i {
            @extend .svg-photo-xsmall;
          }
        }

        &.__video {
          i {
            @extend .svg-video-xsmall;
          }
        }

        &.__views {
          i {
            @extend .svg-views-xsmall;
          }
        }

        em {

          font-style: normal;
        }
      }
    }

    //Общие стили у маленькой и средней
    &.__small, &.__medium {
      .ct-nb-gradient {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        //border: 2px solid blue;
      }

      .meta-info-component {
        position: absolute;
        top: 10px;
        left: 10px;
        width: calc(100% - 20px);
        box-sizing: border-box;
      }

      .ct-nb-title {
        margin-bottom: 40px;
        margin-top: 30px;
        //border: 2px solid red;
      }

      .ct-nb-meta-bottom {
        //display: none;
        //border: 2px solid green;
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: calc(100% - 20px);
        box-sizing: border-box;
      }
    }

    //Маленькая
    &.__small {
      flex-basis: 50%;
    }

    //Средняя
    &.__medium {
      flex-basis: 100%;

      .ct-nb-title {
        font-size: 1.38em;
        line-height: 1.1;
      }
    }

    //Большая
    &.__large {
      flex-basis: 100%;

      .ct-nb-gradient {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .ct-nb-title {
        font-size: 1.38em;
        line-height: 1.28;
        margin-bottom: .7em;
      }
    }

    //Белая
    &.__white {
      display: block;
      flex-basis: 100%;

      //Выравниваем текст заголовка по вертикали в блоке
      .ct-nb-gradient {
        padding-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
      }

      .meta-info-component {
        position: absolute;
        top: 10px;
        left: 10px;
        width: calc(100% - 20px);
        box-sizing: border-box;
      }

      .ct-nb-title {
        font-size: 1.23em !important;
        line-height: 1.31 !important;
        padding-bottom: 1em;
        border-bottom: 1px solid #ddd;
        margin-top: 30px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  @media #{$large-up} {
    .ct-nb-item {
      //Адрес
      .ct-nb-location {
        font-size: 1rem;
        padding-left: 17px;
        margin-bottom: 1.2em;

        i {
          top: 2px;
          @include svg-location;
        }
      }

      //Теги
      .ct-nb-tags {
        font-size: 1em;
      }

      //Если теги идут после адреса, то их нужно приподнять
      .ct-nb-location + .ct-nb-tags {
        margin-top: -.7em;
      }

      //Иконки
      .ct-nb-meta-bottom {
        > span {
          margin-right: 15px;
          padding-left: 28px;
          font-size: 1.23em;

          &.__photo {
            i {
              @include svg-photo;
            }
          }

          &.__video {
            i {
              @include svg-video;
            }
          }

          &.__views {
            padding-left: 24px;

            i {
              top: 5px;
              @include svg-views;
            }
          }
        }
      }

      //Общие у маленькой и средней
      &.__small, &.__medium {
        .ct-nb-title {
          margin-bottom: 65px;
          margin-top: 40px;
        }
      }

      //Маленькая
      &.__small {
        flex-basis: 25%;

        .ct-nb-title {
          font-size: 1.6em;
          line-height: 1.1;
        }
      }

      //Средняя
      &.__medium {
        flex-basis: 50%;

        .ct-nb-title {
          font-size: 2em;
        }
      }

      //Белая
      &.__white {
        transition: background-color .4s;

        &:hover {
          background: #FBFBFB;
        }
        .ct-nb-title {
          font-size: 1.23em;
          line-height: 1.3;
          border-bottom: none;
          margin-top: 35px;
          margin-bottom: .5em;
        }
      }

      //Большая
      &.__large {
        .ct-nb-gradient {
          padding: 25px 35px;
        }

        .ct-nb-image {
          float: right;
          width: 52%;
          margin-top: 0;
          transition: box-shadow .4s;
        }

        &:hover {
          .ct-nb-image {
            box-shadow: 0 0 5px rgba(black, .05);
          }
        }

        @mixin float-left {
          float: left;
          width: 45%;
          clear: left;
        }

        .meta-info-component {
          @include float-left;
          margin-bottom: 1.5em;

          em {
            margin-right: 1.5em;
          }
        }

        .ct-nb-title {
          @include float-left;
          font-size: 2em;
          line-height: 1;
          margin-top: .4em;
        }

        .ct-nb-description {
          @include float-left;
          font-size: 1.23em;
          line-height: 1.31;
          margin-bottom: 1.2em;
        }

        .ct-nb-meta-bottom {
          @include float-left;
        }

        .ct-nb-location {
          @include float-left;
        }

        .ct-nb-tags {
          @include float-left;
        }

        //На белом фоне
        &.__on-white {
          margin-bottom: 2em;
          margin-top: 3em;
          transition: background-color .4s;

          &:hover {
            background-color: #FBFBFB;
          }

          .ct-nb-gradient {
            padding: 0;
          }

          .ct-nb-image {
            float: left;
            width: 52%;
            box-shadow: 0 0 30px rgba(black, .15);
          }

          @mixin float-right {
            float: right;
            clear: right;
            width: 43%;
          }

          .meta-info-component {
            @include float-right;
            margin-bottom: 2.2em;
          }

          .ct-nb-title {
            @include float-right;
            font-size: 2.46em;
          }
        }
      }
    }
  }

  @media #{$xlarge-up} {
    .ct-nb-item {
      .ct-nb-gradient {
        padding: 20px;
      }

      //Общие стили
      &.__small, &.__white, &.__medium  {
        .ct-nb-gradient {
          .meta-info-component {
            top: 20px;
            left: 20px;
            width: calc(100% - 40px);
          }

          .ct-nb-meta-bottom {
            bottom: 20px;
            left: 20px;
            width: calc(100% - 40px);
          }
        }
      }

      //Маленькая и белая
      &.__small, &.__white {
        .ct-nb-title {
          font-size: 2em;
        }
      }

      //Средняя
      &.__medium {
        .ct-nb-title {
          font-size: 2.6em;
        }
      }

      //Большая
      &.__large {
        .ct-nb-title {
          font-size: 2.6em;
        }

        .meta-info-component,
        .ct-nb-title,
        .ct-nb-description,
        .ct-nb-meta-bottom {
          width: 52%;
        }

        //На белом фоне
        &.__on-white {
          .meta-info-component,
          .ct-nb-title,
          .ct-nb-description,
          .ct-nb-meta-bottom {
            width: 52%;
          }
        }
      }
    }
  }
}