.section-component {
  @include components-mixin;

  .ct-sn {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
    }
    &-title {
      font-weight: 300;
      font-size: 36px;
      color: #000;
      letter-spacing: 1.54px;
      line-height: 43px;
      text-transform: uppercase;
      &:before {
        content: "";
        display: inline-block;
        margin-right: 10px;
        width: 5px;
        height: 43px;
        background-color: #52C2BF;
        transform: rotate(22deg) translateY(8px);
      }

      .container.no-pad & {
        margin-left: 15px;
      }
    }
    &-nav {
      .tabs-nav-component {
        margin-top: 20px;
      }

      &-item {
        display: inline-flex;
        margin-top: 12px;
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
      &-link {
        font-size: 18px;
        color: #000;
        letter-spacing: 0.77px;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          opacity: 0.7;
          color: #000;
          text-decoration: none;
        }
        > .icon-arrow-right {
          margin-top: -6px;
          margin-left: 7px;
          width: 10px;
          height: 20px;
          fill: currentColor;
          opacity: 0.5;
          vertical-align: middle;
        }
      }
    }
  }
}
