@keyframes ct-mi-translate-arrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.meta-info-component {
  margin: 0 0 .8em 0;
  @extend .clearfix;
  text-transform: uppercase;

  .ct-mi-type {
    font-size: 0.61em;
    position: relative;
    color: black;
    display: inline-block;
    float: left;

    span {
      color: black;
      display: inline-block;
      padding: .5em .8em;
      background: white;
    }

    b {
      display: none;
    }

    i {
      position: absolute;
      right: -15px;
      top: 0;
      display: none;
    }
  }

  em {
    font-size: .77em;
    display: inline-block;
    float: right;
    font-style: normal;
    margin-left: 1em;
  }

  //Вариант на белом фоне
  &.__on-white {
    color: black;

    .ct-mi-type {
      color: white;

      span {
        background: black;
        color: white;
      }
    }
  }

  //Вариант для белого блока
  &.__white {
    .ct-mi-type {
      font-size: .92em;

      span {
        background: none;
        padding: 0;
        color: #9C9C9C;
      }

      i {
        display: inline-block;
        @extend .svg-arrow-right-grey;
      }
    }

    em {
      color: #9C9C9C;
    }
  }

  @media #{$large-up} {
    margin-bottom: 1.4em;

    .ct-mi-type {
      font-size: .92em;

      span {
        padding: .3em .8em;

        &:hover {
          & + i, & + b + i {
            animation: ct-mi-translate-arrow 1s;
          }
        }
      }

      i {
        @include svg-common;
        @include svg-arrow-right-white;
        display: inline-block;
        right: -17px;
        top: 3px;
      }

      b {
        font-weight: normal;
        padding-left: 12px;
        padding-right: 15px;
        color: white;
        display: inline-block;
      }

      b + i {
        right: 0;
      }
    }

    em {
      font-size: .92em;
      opacity: .6;
      padding-top: .2em;
    }

    //Вариант на белом фоне
    &.__on-white {
      .ct-mi-type {
        i {
          @include svg-arrow-right-black;
        }

        b {
          color: black;
        }
      }
    }

    //Вариант для белого блока
    &.__white {
      .ct-mi-type {
        i {
          top: 0;
        }
      }

      em {
        padding-top: 0;
      }
    }
  }
}