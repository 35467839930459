section.main.films {
  //Нету фильмов
  .no-schedule {
    width: 300px;
    margin: 2em auto 2em auto;
    @extend .clearfix;

    .icon {
      float: left;
      @include svg-image("emo-sad", 50, 50);
    }

    .text {
      margin-left: 60px;
      font-size: 1.3em;
    }

    @media #{$large-up} {
      width: 400px;
      margin-top: 3em;
      margin-bottom: 3em;

      .icon {
        @include svg-image("emo-sad", 80, 80);
      }

      .text {
        margin-top: 10px;
        margin-left: 100px;
        font-size: 1.5em;
      }
    }
  }

  //Список кинотеатров
  .cinemas-list {
    background: #F3F3F3;
    padding: 1em 0;

    .title {
      text-transform: uppercase;
      font-size: 1.5em;
      margin: 0 0 .8em 0;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 2.5em 15px 0 15px;

      article {
        flex-basis: 100%;
        margin: 0 0 2.3em;
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;
        padding: .5em 5px;
        position: relative;

        .like-recommends {
          position: absolute;
          right: 0;
          bottom: 100%;
          margin: 0 0 2px 0;
        }

        .name {
          font-size: 1.3em;
          margin: 0 0 .6em 0;

          a {
            color: $black;
          }
        }

        .rating {
          @extend .clearfix;
          margin: 0 0 .7em 0;

          .star {
            float: left;
            margin: 0 7px 0 0;
            @include svg-image("star-black", 15, 15);
          }
        }

        .location {
          position: relative;
          padding: 0 0 0 17px;
          margin: 0 0 .5em 0;
          color: #6a6a6a;

          .l-icon {
            position: absolute;
            left: 0;
            top: 1px;
            @include svg-image("location-grey", 11, 17);
          }
        }
      }
    }

    @media  #{$large-up} {
      padding-top: 3em;

      .title {
        font-size: 2.4em;
      }

      .items {
        margin-left: 0;
        margin-right: 0;
        margin-top: 5em;
        justify-content: space-between;

        article {
          flex-basis: 31%;
          margin-bottom: 3.5em;

          .name {
            font-size: 1.5em;
          }
        }
      }
    }

    @media #{$xlarge-up} {
      .items {
        article {
          flex-basis: 23%;
        }
      }
    }
  }

  //Фильтр поиска
  .search-filter {
    background: #f4f4f4;
    margin: 0 0 2em 0;

    .date-type-container {
      @extend .clearfix;
      padding: .8em 7px;
      border-bottom: 1px solid #dedede;

      .elem {
        position: relative;
        float: left;

        &:last-child {
          float: right;
        }

        .trigger {
          padding: 5px 8px;
          cursor: pointer;
          @extend .clearfix;

          .text {
            line-height: 1.2;
            float: left;

            &.upcase {
              text-transform: uppercase;
            }
          }

          .caret {
            float: left;
            @include caret($black, 6, down);
            margin: .3em 0 0 .4em;
          }

          small {
            display: block;
            clear: both;
            font-size: .8em;
            color: #A0A0A0;
          }
        }

        .dropdown {
          display: none;
          position: absolute;
          z-index: 2;
          top: 100%;
          margin: 2px 0 0 0;
          padding: 7px 0;
          min-width: 100%;
          box-sizing: border-box;
          background: darken(#f4f4f4, 5%);
          max-height: 200px;
          overflow-y: auto;

          .dropdown-elem {
            cursor: pointer;
            padding: 5px 8px;

            .text {
              white-space: nowrap;
              text-transform: uppercase;
              line-height: 1.2;
            }

            small {
              display: block;
              clear: both;
              font-size: .8em;
              color: #A0A0A0;
            }
          }
        }

        &.active {
          .trigger {
            background: #D8D8D8;

            .caret {
              @include caret($black, 6, up);
              margin-top: 0;
            }
          }

          .dropdown {
            display: block;
          }
        }
      }
    }

    .imax-three-d-container {
      @extend .clearfix;
      padding: .5em 15px;
      border-bottom: 1px solid #dedede;

      .elem {
        float: left;
        width: 33.3%;
        text-align: center;

        .trigger {
          display: inline-block;
          cursor: pointer;

          input[type=checkbox] {
            display: none;
          }

          .text {
            display: inline-block;
            padding: 5px .8em;
          }

          input[type=checkbox]:checked + .text {
            color: $white;
            background: #ff4b4b;
          }
        }
      }
    }

    .like-children-container {
      @extend .clearfix;
      padding: .8em 15px;

      .elem {
        float: left;
        @extend .clearfix;

        &:last-child {
          float: right;
        }

        .trigger {
          cursor: pointer;
          display: inline-block;

          input[type=checkbox] {
            display: none;
          }

          em {
            float: left;
            display: inline-block;
            @include svg-image("checkbox", 21, 21);
            margin: 0 .6em 0 0;
          }

          input[type=checkbox]:checked + em {
            @include svg-image("checkbox-checked", 21, 21);
          }

          .text {
            display: inline-block;
            float: left;
            margin: .1em 0 0 0;
          }
        }
      }
    }

    button[type=submit] {
      display: block;
      width: 100%;
      box-sizing: border-box;
      color: $white;
      padding: .7em;
      font-size: 1.1em;
      text-transform: uppercase;
      border: none;
      font-family: $font-family-sans-serif;
      background: #70cc55;
      cursor: pointer;
    }

    @media #{$large-up} {
      padding: 1em 0;
      font-size: 1em;

      .container {
        padding-left: 15px;
      }

      .date-type-container {
        padding: 0;
        float: left;
        width: 30%;
        border-bottom: none;
        margin-top: 5px;

        .elem {
          margin-right: .5em;

          &:last-child {
            margin-right: 0;
          }

          &:last-child {
            float: left;
          }

          .dropdown {
            max-height: 400px;
          }
        }
      }

      .imax-three-d-container {
        padding: 0;
        float: left;
        width: 22%;
        border-bottom: none;

        .elem {
          width: auto;
          margin-right: .5em;
          text-align: left;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .like-children-container {
        margin-top: 5px;
        padding: 0;
        float: left;
        width: 33%;

        .elem {
          margin-right: 1em;

          &:last-child {
            float: left;
            margin-right: 0;
          }

          .trigger {
            em {
              position: relative;
              top: -3px;
              @include svg-image("checkbox", 30, 30);
              margin-right: .3em;
            }

            input[type=checkbox]:checked + em {
              @include svg-image("checkbox-checked", 30, 30);
            }

            .text {
              margin-top: 0;
            }
          }
        }
      }

      button[type=submit] {
        float: left;
        width: 13%;
      }
    }

    @media  #{$xlarge-up} {
      font-size: 1.3em;

      .like-children-container {
        .elem {
          &:first-of-type {
            padding-left: 1em;
          }
        }
      }
    }
  }

  //Расписание
  .schedule-block {
    margin: 2.5em 0 2em 0;

    article {
      padding: 25px 0 20px 0;
      border-bottom: 1px solid #E0E0E0;
      position: relative;
      @extend .clearfix;

      .time-price-container {
        float: left;
        @extend .clearfix;

        .price {
          display: none;
        }

        .time {
          display: inline-block;
          color: $black;
          padding: .3em 1em;
          border: 1px solid $black;
          font-size: 1.3em;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .name {
        margin: 0 0 .1em 90px;
        font-size: 1.5em;

        a {
          color: $black;
          position: relative;
          display: block;
          padding-right: 20px;

          .icon {
            @include svg-image("arrow-right-grey", 15, 15);
            position: absolute;
            right: 0;
            top: 8px;
          }
        }

        .genre {
          color: #a9a9a9;
          font-size: .65em;
        }
      }


      .meta {
        padding: 1em 0 0 0;
        clear: both;
        @extend .clearfix;

        .like-recommends {
          position: absolute;
          right: 0;
          top: 0;
        }

        .item {
          color: #949494;
          float: left;
          display: inline-block;
          margin: 0 1em 0 0;
          font-size: 1.1em;

          &.for-children {
            @include svg-image("for-children-grey", 16, 20);
          }
        }
      }
    }

    @media #{$large-up} {
      article {
        border-bottom: none;

        .time-price-container {
          width: 20%;

          .time {
            float: left;
            margin-right: 1em;
          }

          .price {
            float: left;
            color: #848484;
            display: inline-block;
            margin-top: .7em;
          }
        }

        .name {
          border-left: 1px solid #e2e2e2;
          padding-left: .5em;
          float: left;
          width: 45%;
          margin-left: 0;
          margin-top: 0;
          font-size: 1.8em;

        }

        .meta {
          float: left;
          width: 35%;
          clear: none;
          padding-left: 1em;
          box-sizing: border-box;
          margin-top: -5px;

          .delim {
            float: left;
            display: inline-block;
            border-right: 1px solid #E2E2E2;
            height: 35px;
            margin: 0 10px 0 10px;
            position: relative;
            top: -4px;
          }

          .like-recommends {
            position: relative;
            top: -.2em;
            float: left;
            font-size: 1.2em;

            span {
              display: none;
            }
          }

          .item {
            margin-right: 0;
            color: $black;
            font-size: 1.5em;

            &.price {
              display: none;
            }

            &.for-children {
              @include svg-image("for-children-black", 27, 33);
            }
          }
        }
      }

      @media #{$xlarge-up} {
        article {
          .time-price-container {
            .time {
              font-size: 1.7em;
            }

            .price {
              font-size: 1.2em;
            }
          }

          .name {
            width: 40%;
          }
        }
      }
    }
  }
}

//Плитка фильмов - может использоваться в других местах сайта
.movies-tile-component {
  .container {
    padding-left: 3px;
    padding-right: 3px;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 0 1.5em 0;

    article {
      cursor: pointer;
      flex-basis: 50%;
      width: 50%;
      background: #3CA450;
      position: relative;
      padding: 0 0 3.8rem 0;
      overflow: hidden;

      &:nth-child(4n - 2) {
        background: #DBA50F;
      }

      &:nth-child(4n - 1) {
        background: #8B8B87;
      }

      &:nth-child(4n) {
        background: #C496BF;
      }

      //Заглушка - это особый элемент. Она мало где нужна для показа,
      //но в партиале присутствует. Ее вывод/скрытие определяется индивидуальными
      //стилями в тех местах, где она нужна. По-умолчанию она скрыта
      &.plug {
        background-image: linear-gradient(135deg, #C0FFF5, #9878CD);
        position: relative;
        display: none;

        .block {
          display: block;
          text-align: center;
          width: 130px;
          height: 70px;
          left: 50%;
          top: 50%;
          position: absolute;
          margin-top: -35px;
          margin-left: -65px;
          color: $white;

          .icon {
            @include sprite-image("events-tile-component-magnifier", 37, 37)
          }

          .text {
            text-transform: uppercase;
          }
        }
      }

      .img {
        background-size: cover;
        background-position: center center;
        background-image: image-url("svg-icons/films-default-bg.svg");
        position: relative;
        margin: 0 0 .5em 0;

        .options {
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          @extend .clearfix;

          .option {
            display: inline-block;
            float: right;
            background: rgba(0, 0, 0, .8);
            padding: .3em .7em;
            color: $white;
            font-size: .75em;
            text-transform: uppercase;
          }

          .like-recommends {
            float: right;
          }
        }

        .trailer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          @extend .clearfix;
          padding: .2em 5px .3em 5px;
          background: rgba($black, .7);
          color: $white;

          .trigger {
            cursor: pointer;
            display: inline-block;
            text-transform: uppercase;
            font-size: .7em;
            padding: .3em .5em .3em 17px;
            position: relative;
            color: $white;

            .caret {
              position: absolute;
              top: 3px;
              left: 5px;
              @include caret($white, 5, right);
            }
          }

        }

        img {
          width: 100%;
        }
      }

      .name {
        padding: 0 5px 0 5px;
        margin: 0 0 .5em 0;
        font-size: 1.15em;

        a {
          display: inline-block;
          color: $white;
          max-width: 100%;
          word-wrap: break-word;
        }
      }

      .duration {
        padding: 0 5px 0 5px;
        color: rgba($white, .6);
        font-size: .8em;
      }

      .genre {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: .3em 5px .5em 5px;
        box-sizing: border-box;
        color: $white;
        opacity: .5;
        max-height: 3.8rem;
        overflow: hidden;
        font-size: .8em;
      }
    }
  }

  @media #{$medium-up} {
    .items {
      article {
        width: 33.3%;
        flex-basis: 33.3%;
      }
    }
  }

  @media #{$large-up} {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }

    .items {
      margin-bottom: 4em;

      article {
        flex-basis: 25%;
        width: 25%;
        font-size: 1.3em;
        margin-bottom: .5em;

        .img {
          .trailer {
            padding: 3px 0 3px 5px;
            font-size: .9em;

            .trigger {
              padding-left: 19px;

              .caret {
                top: 5px;
                @include caret($white, 6, right);
              }
            }
          }
        }

        .name {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 1em;
        }

        .duration {
          padding: 0 10px 0 10px;
        }

        .genre {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  @media #{$xlarge-up} {
    .items {
      article {
        width: 20%;
        flex-basis: 20%;
      }
    }
  }
}

//Модальные окна с описанием фильма
#films-movie-main-movie-description,
#films-index-main-movie-description {
  .movie-description {
    font-size: 1.5rem;
  }

  @media #{$large-up} {
    .movie-description {
      color: #f2f2f2;
      font-size: 1.7rem;
      line-height: $base-line-height + .4;
    }
  }
}
