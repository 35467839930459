section.main.item-opinions {
  //Верхяя плашка
  .top-block {
    padding: 60px 0 30px 0;
    box-sizing: border-box;
    background: top center / cover linear-gradient(135deg, #C27329, #7E20A8);
    margin-bottom: 2.5em;
    .name {
      margin-right: 320px;
      position: relative;
      padding-left: 40px;
      color: white;
      span, a {
        font-weight: bold;
        font-size: 3.23em;
        line-height: 1.1;
        color: white; }
      i {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        background: center center / contain no-repeat; }
      &.__no-icon {
        padding-left: 0; } }
    .address {
      font-size: 1.54em;
      color: white;
      margin: 0 320px 0 40px; }
    .name.__no-icon + .address {
      margin-left: 0; }
    .rating2-component {
      position: absolute;
      right: 0;
      bottom: 20px; }
    .opinion-button-component {
      position: absolute;
      right: 40px;
      bottom: -50px; } }
  //Форма Оставить отзыв
  .leave-opinion {
    padding-bottom: 4em;
    //Рекапчу прячем
    .grecaptcha-badge {
      display: none; }
    .form-container {
      width: 600px;
      margin: 0 auto 2em auto; }
    @include components-mixin;
    .rating {
      padding: 1.5em 0;
      text-align: center;
      background: #F6F6F6;
      margin-bottom: 20px;
      &.__disabled {
        display: none; }
      label {
        display: inline-block;
        margin: 0 .7em;
        cursor: pointer;
        input[type=radio] {
          display: none; }
        .star {
          width: 38px;
          height: 38px;
          background: image-url('svg-icons/star-black-hollow.svg') center center / cover; } }
      &.__1 {
        label:nth-child(1) {
          .star {
            background-image: image-url('svg-icons/star-yellow.svg'); } } }
      &.__2 {
        label:nth-child(1), label:nth-child(2) {
          .star {
            background-image: image-url('svg-icons/star-yellow.svg'); } } }
      &.__3 {
        label:nth-child(1), label:nth-child(2), label:nth-child(3) {
          .star {
            background-image: image-url('svg-icons/star-yellow.svg'); } } }
      &.__4 {
        label:nth-child(1), label:nth-child(2), label:nth-child(3), label:nth-child(4) {
          .star {
            background-image: image-url('svg-icons/star-yellow.svg'); } } }
      &.__5 {
        label {
          .star {
            background-image: image-url('svg-icons/star-yellow.svg'); } } } }
    textarea, input[type=text] {
      border: 1px solid #a2a2a2;
      padding: 1.3em 3.5em;
      font-size: 1.2em;
      display: block;
      width: 100%;
      box-sizing: border-box;
      border-radius: 2px;
      outline: none; }
    .textarea-container {
      position: relative;
      textarea {
        margin-bottom: 20px;
        height: 10em;
        background: white;
        z-index: 2;
        position: relative; }
      .tooltip {
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 20px);
        padding: 180px 0 20px 0;
        background: #FFE25F;
        margin: -10px -10px 0 -10px;
        text-align: center;
        box-shadow: 0 5px 5px rgba(black, .1);
        display: none;
        span {
          display: block;
          width: 50%;
          margin: 0 auto 1em auto;
          font-size: 1.1em; }
        small {
          font-size: 1em;
          cursor: pointer; } } }
    .contact {
      margin-bottom: 35px;
      padding: .7em;
      background: #E2E2E2;
      @extend .clearfix;
      border-radius: 2px;
      input[type=text] {
        margin-bottom: .7em; }
      p {
        float: left;
        font-size: 1.23em;
        margin-left: 10px;
        padding-top: .3em; }
      a {
        margin-right: 10px;
        float: right;
        display: inline-block;
        color: black;
        border-radius: 1.2em;
        padding: .5em 1.3em;
        background: #FFE25F;
        font-size: 1.08em;
        text-transform: uppercase;
        font-weight: bold;
        box-shadow: 0 3px 5px rgba(black, .2);
        transition: background-color .4s;
        &:hover {
          text-decoration: none;
          background: darken(#FFE25F, 5%); } } }
    .files {
      display: block;
      width: 245px;
      margin: 0 auto 60px auto;
      position: relative;
      @extend .clearfix;
      cursor: pointer;
      input[type=file] {
        display: none; }
      em {
        float: left;
        text-align: center;
        color: #5F5F5F;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #5F5F5F;
        border-radius: 50%;
        font-style: normal;
        font-weight: bold;
        font-size: 2.5em;
        line-height: 1;
        padding-top: .1em; }
      span {
        margin-left: 55px;
        display: block;
        color: #5F5F5F;
        font-size: 1.08em; }
      small {
        margin-left: 55px;
        display: block;
        color: #5F5F5F;
        font-size: .8em;
        i, b {
          font-style: normal;
          font-weight: normal; } } }
    .submit {
      border-top: 1px solid #979797;
      text-align: center;
      button {
        display: inline-block;
        width: 400px;
        font-size: 1.54em;
        text-transform: uppercase;
        background: #85F093;
        border: none;
        padding: .8em 0;
        position: relative;
        top: -1.5em;
        border-radius: 1.5em;
        box-shadow: 0 5px 5px rgba(black, .2);
        transition: background .4s;
        outline: none;
        &:hover {
          background: lighten(#85F093, 3%); }
        &:disabled {
          background: lighten(#85F093, 10%);
          color: lighten(black, 30%); } } } }
  .thanx {
    text-align: center;
    font-size: 2.4em;
    display: none;
    padding-bottom: 2em; } }

.form-with-captcha .grecaptcha-badge {
  display: none; }
