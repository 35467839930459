//Лайки
.likes-component {
  @include components-mixin;
  position: relative;
  padding-left: 10px;

  .ct-icon {
    position: absolute;
    left: 0;
    top: .2em;
    @include sprite-image("likes-component-icon-small", 7, 6);
  }

  .ct-num {
    color: $white;
    font-size: .7em;
  }

  &.__grey {
    .ct-icon {
      @include sprite-image("likes-component-icon-grey-small", 7, 6);
    }

    .ct-num {
      color: #bebebe;
    }
  }

  &.__transparent {
    .ct-icon, .ct-num {
      opacity: .8;
    }
  }

  @mixin large() {
    padding-left: 17px;

    .ct-icon {
      @include sprite-image("likes-component-icon-big", 13, 13);
    }

    .ct-num {
      font-size: 1em;
    }

    &.__grey {
      .ct-icon {
        @include sprite-image("likes-component-icon-grey-big", 13, 13);
      }
    }
  }

  //Одно размера на всех экранах
  &.__one-size {
    @include large();
  }

  @media #{$large-up} {
    @include large();
  }
}