body {
  //Скоро будет рефакторинг - выключаем адаптив пока что, эта верстка сейчас - только десктопная
  min-width: 880px;
}

//Тут внутри находится весь сайт
.site-container {
  min-width: 320px;
  margin: 0 auto 0 auto;
  max-width: 700px;

  @media #{$large-up} {
    max-width: 1450px;
  }
}

.container {
  margin: 0 auto 0 auto;
  max-width: 860px;
  @extend .clearfix;
  padding: 0 $_container-padding;

  &.no-pad {
    padding-left: 0;
    padding-right: 0;
  }

  &.pad-on-large-up {
    padding-left: 0;
    padding-right: 0;
  }

  @media #{$large-up} {
    &.pad-on-large-up {
      padding-left: $_container-padding;
      padding-right: $_container-padding;
    }
  }

  @media #{$xlarge-up} {
    max-width: 1100px;
  }
}

//Главные алерты
section.main-alerts {
  background: $black;
  position: relative;
  z-index: 200;

  .alert-box {
    margin: 20px 0 20px 0;
  }
}

// Кнопка вверх страницы
.back-to-top-btn {
  display: none;
  @media #{$large-up} {
    position: fixed;
    right: 20px;
    bottom: 80px;
    @include svg-image("back-to-top", 60, 60);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s;
    z-index: 6;
    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }
}

//Основной контент
section.main {

}
