section.main.index.index-page {
  margin-top: 55px;

  //Рекомендации
  .recommends {
    margin: 2em 0 3em 0;

    .items {
      article {
        float: left;
        width: 50%;
        box-sizing: border-box;
        font-size: .85rem;
        color: $white;

        .bg-gradient {
          padding: 10px;
          width: 100%;
          height: 100%;
        }

        .icon-main {
          float: right;

          &.discount {
            @include sprite-image("discount-small", 13, 13);
          }
        }

        .date {
          color: $_font-color-transparent;
          float: left;
          margin: 0 0 .3em 0;

          a {
            color: inherit;
          }
        }

        .name {
          clear: both;
          font-size: 1.1em;
          margin: 0 0 .5em 0;
          min-height: 2em;

          a {
            color: $white;
            display: inline-block;
          }
        }

        .location {
          float: left;
          margin: .5em 0 0 0;
        }

        .special {
          float: right;
          font-size: 1.2rem;
        }
      }
    }

    @media #{$large-up} {
      margin-bottom: 5em;

      .items {
        article {
          font-size: 1.5rem;

          .bg-gradient {
            padding: 20px;
          }

          .icon-main {
            &.discount {
              @include sprite-image("discount-big", 26, 27);
            }
          }

          .location {
            margin-bottom: 1em;
          }

          .special {
            font-size: 1.6em;
          }
        }
      }
    }
  }

  //Событие по времени
  .events-by-time {
    margin: 0 0 3em 0;

    @media #{$large-up} {
      margin-bottom: 6em;
    }
  }

  //Прошедшие события
  .past-events {
    margin: 0 0 3em 0;

    .inner-title-block {
      background-color: $white;
    }

    .gallery-root {
      padding: 27px 10px 20px;
    }

    .cycle-pager {
      position: absolute;
      top: -26px;
      right: 0;
      z-index: 120;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        width: 29px;
        height: 3px;
        background-color: $white;
        opacity: 0.5;
        margin-left: 16px;
        cursor: pointer;

        &.cycle-pager-active {
          opacity: 1;
        }
      }
    }

    article {
      //У нас cycle2 будет ставить position: absolute частенько
      //поэтому ширины выставим сами, если вдруг слайд вывалится
      //хотя этого и не должно быть - cycle2 должен это контролировать
      width: 100%;
      @extend .clearfix;
      color: $white;
      display: none;

      &:first-of-type {
        display: block;
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      .image {
        display: block;
        margin: 0 0 1em 0;
        position: relative;

        .main-image {
          background-position: center center;
          background-size: cover;

          img {
            display: block;
            width: 100%;
          }
        }

        .prev, .next {
          cursor: pointer;
          position: absolute;
          top: 50%;
          margin-top: -17px;

          .shadow {
            height: 100%;
            background: rgba($black, .5);
          }
        }

        .prev {
          left: -20px;
          .arrow {
            @include svg-image("arrow-left-white-thick", 15, 24);
          }
        }

        .next {
          right: -20px;
          .arrow {
            @include svg-image("arrow-right-white-thick", 15, 24);
          }
        }
      }

      .text {
        margin: 0 0 1em 0;

        .meta-top {
          @extend .clearfix;
          text-transform: uppercase;
          font-size: .8em;
          margin: 0 0 1em 0;

          .meta-top-passed {
            color: $black;
            background: $white;
            padding: .3em .5em;
            display: inline-block;
            margin: 0 1em 0 0;
          }
        }

        .title {
          font-size: 1.3em;
          margin: 0 0 .7em 0;
        }

        .description {
          margin: 0 0 1em 0;
        }

        .meta-bottom {
          .location {
            margin: 0 0 .6em 0;
          }

          .rating, .views {
            float: left;
            margin: 0 1em .5em 0;
          }

          .rating {
            margin-top: 2px;
          }

          .tags {
            clear: both;
            @extend .clearfix;
            margin: 0 0 .8em 0;
          }
        }

        .icons {
          @extend .clearfix;

          .elem {
            float: left;
            height: 2rem;
            box-sizing: border-box;
            @extend .clearfix;
            margin: 0 15px 0 0;

            .icon {
              float: left;
              margin: 0 5px 0 0;

              &.video {
                @include svg-image("news-video", 18, 18);
              }

              &.user {
                @include svg-image("news-user", 19, 19);
              }

              &.comment {
                margin-top: 3px;
                @include svg-image("news-comment", 19, 15);
              }

              &.photo {
                @include svg-image("news-photo", 19, 19);
              }
            }

            .num {
              float: left;
              font-size: 1rem;
            }
          }
        }
      }
    }

    @media #{$medium-up} {

      article {
        .image {
          float: left;
          width: 50%;
          margin-bottom: 0;

          .prev, .next {
            display: none;
          }
        }

        .text {
          float: left;
          width: 50%;
          box-sizing: border-box;
          padding-left: 1em;
        }
      }
    }

    @media #{$large-up} {
      margin-bottom: 6em;

      .gallery-root {
        padding-bottom: 47px;
      }

      .inner-title-block {
        background-color: transparent;
        color: $white;
        padding-top: 23px;
        .title-component {
          margin: 0;
          .ct-more {
            color: $white !important;
            opacity: 0.4;
            background-image: image-url("svg-icons/arrow-right-white.svg");
            background-position: 100% 1px;
            background-repeat: no-repeat;
            background-size: 19px 30px;
            padding-right: 25px !important;
          }
        }
      }

      .cycle-pager {
        top: -55px;
        right: 0;
        width: auto;
        span {
          width: 35px;
          height: 6px;
        }
      }

      .container {
        padding-left: 30px;
        padding-right: 30px;
      }

      article {
        .image {
          width: 45%;
        }

        .text {
          width: 55%;
          font-size: 1.4em;

          .meta-top {
            font-size: .6em;
          }

          .meta-bottom {
            .location, .rating, .views {
              float: left;
              min-height: 2rem !important;
              margin-top: 0 !important;
              margin-bottom: .3rem !important;
              margin-right: 1.5rem !important;
              box-sizing: border-box !important;
            }
          }
        }
      }
    }

    @media #{$xlarge-up} {
      article {
        .image {
          width: 40%;
        }

        .text {
          width: 60%;
        }
      }
    }
  }

  // Новости
  .news-tile-component {

    .container {
      padding: 0;
    }

    .ct-positions {
      margin: 0;
    }

    article {
      width: 50%;
      float: left;
      position: relative;
      .ct-text {
        padding: .5em 0 1.5em .5em;
        .ct-date {
          position: absolute;
          bottom: 0;
        }
      }
      .ct-icons {
        background: none;
        width: auto;
      }
      .ct-image {
        .shadow {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
        }
      }

      &:first-child {
        width: 100%;
        margin-bottom: 1px;
        .ct-image {
          img {
            min-height: 160px;
            max-height: 320px;
          }
        }
        .ct-title, .ct-date {
          color: $white;

          a {
            color: inherit;
            &:hover {
              text-decoration: none;
            }
          }
        }

        .ct-title {
          position: absolute;
          font-size: 1.3em;
          width: 100%;
          bottom: 0;
          left: 0;
          padding: 0 17px 22px 17px;
        }
        .meta-top {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          padding-left: 7px;
          .ct-icons {
            position: initial;
            float: left;
          }
          .ct-date {
            text-transform: uppercase;
            padding: 15px 10px 10px 10px;
            float: right;
          }
        }
      }
    }


    @media #{$medium-up} {

    }

    @media #{$large-up} {
      article {
        width: 25%;
        .ct-text {
          padding: .5em 1em 1.5em .5em;
        }
        &:first-child {
          .ct-title {
            font-size: 2.1em;
            padding: 0 8em 45px 45px;
          }
          .meta-top {
            padding-left: 45px;
            width: auto;
            .ct-icons {
              padding: 15px 0;
            }
            .ct-date {
              position: initial;
              float: left;
            }
          }
        }
      }
    }
  }

  //Фильмы
  .films-title {
    .ct-more {
      margin-right: 1em;
    }
  }

  .movies-tile-component {
    .container {
      padding: 0;
    }

    .items {
      article {
        &.plug {
          display: block;
        }
      }
    }

    @media #{$large-up} {
      .items {
        article {
          flex-basis: 20%;

          &.plug {
            display: none;
          }
        }
      }
    }
  }

  //Выгоды
  .benefits {
    padding: 1em 0 0 0;
    margin: 0 0 2em 0;
    background: linear-gradient(to bottom, #FFE268 0%, #FFAFA8 90%, #FFACAC 100%);

    .items {
      margin: 0 0 1.2em 0;

      article {
        border: 3px solid #f5ab34;
        background-image: image-url("main/benefir-bg-small.png");
        background-position: right top;
        background-repeat: no-repeat;
        position: relative;
        margin: 0 0 1em 0;
        font-size: 1rem;

        .item-icon {
          position: absolute;
          right: 3px;
          top: 3px;

          &.kupon {
            @include sprite-image("label-small", 22, 22);
          }

          &.discount {
            @include sprite-image("discount2-small", 20, 19);
          }

          &.gift {
            @include sprite-image("gift-small", 17, 18);
          }
        }

        a.img {
          float: left;
          width: 100px;
        }

        .text {
          float: left;
          width: 60%;
          box-sizing: border-box;
          padding: 0 20px 0 10px;

          .name {
            font-size: 1.2em;
            margin: .3em 0 .5em 0;
            line-height: 1.2;

            a {
              color: $black;
            }
          }

          .meta {
            .date {
              float: left;
              font-size: .8em;
              display: inline-block;
              color: $_font-color-transparent-black;
              margin: 0 1em 0 0;

              a {
                color: inherit;
              }
            }

            a.place {
              float: left;
              display: inline-block;
              margin: .1em 0 0 0;
              color: #4A4A4A;
              font-size: .8em;
            }
          }

          .dop {
            clear: both;
            background: #4A4A4A;
            display: inline-block;
            color: $white;
            font-size: .9em;
            margin: .3em 0 0 0;
            padding: .2em .4em;
          }
        }
      }
    }

    .show-more-component {
      margin-left: -15px;
      margin-right: -15px;
    }

    @media #{$large-up} {
      margin-bottom: 6em;

      .items {
        article {
          .text {
            .meta {
              .date {
                font-size: 1em;
              }

              a.place {
                font-size: 1em;
              }
            }
          }
        }
      }

      .show-more-component {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media #{$xlarge-up} {
      padding: 5em 0 3em 0;
      .items {
        article {
          float: left;
          width: 30%;
          margin-right: 5%;

          &:nth-of-type(3n) {
            margin-right: 0;
          }

          .text {
            .name {
              font-size: 1em;
            }
          }
        }
      }
    }
  }

  //Мнения экспертов
  .opinions {
    margin: 0 0 2em 0;

    .items {
      margin: 1.5em 0 0 0;

      article {
        margin: 0 0 2em 0;
        font-size: 1rem;

        .text {
          line-height: 1.2;
          float: left;
          width: 73%;
          box-sizing: border-box;
          padding: 0 10px 0 0;
          position: relative;

          .name {
            font-size: 1.1em;
            margin: 0 0 .6em 0;
          }

          .description {
            margin: 0 0 .5em 0;
          }
        }

        .author {
          width: 75px;
          float: left;

          line-height: 1.2;

          a.photo {
            border-radius: 50%;
            display: inline-block;
            margin: 0 0 .5em 0;
          }

          .name {
            text-transform: uppercase;
            font-size: .8em;

            a {
              color: $black;
            }
          }

          .status {
            font-size: .7em;
          }
        }

        .meta {
          clear: both;
          font-size: 1em;

          .date {
            float: left;
            margin: 0 1em 0 0;
            color: #bebebe;

            a {
              color: inherit;
            }
          }

          .likes {
            float: left;
            margin: 0 .5em 0 0;
          }

          .social {
            margin: .2em 0 0 0;
          }

          .tags {
            margin: .5em 0 0 0;
          }
        }
      }
    }

    .show-more-component {
      margin-left: -15px;
      margin-right: -15px;
    }

    @media #{$large-up} {
      margin-bottom: 6em;

      .items {
        margin-bottom: 5em;

        article {
          margin-bottom: 2.5em;

          .text {
            width: 65%;
            font-size: 1.2em;
          }

          .author {
            width: 250px;

            a.photo {
              float: left;
              width: 100px;

              img {
                width: 100%;
              }
            }

            .text-block {
              margin-top: .5em;
              float: left;
              width: 130px;
              margin-left: 20px;

              .name {
                font-size: 1.1em;
                margin-bottom: .3em;
              }

              .status {
                font-size: .9em;
              }
            }
          }

          .meta {
            .date {
              font-size: 1.2em;
              margin-right: 3em;
            }

            .likes {
              margin-right: 4em;
            }
          }
        }
      }

      .show-more-component {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media #{$xlarge-up} {
      .items {
        article {
          .author {
            width: 350px;

            a.photo {
              width: 130px;
            }

            .text-block {
              margin-top: .5em;
              width: 170px;
              margin-left: 30px;
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }

  //Опрос
  .poll {
    margin: 0 0 2em 0;
    font-size: 1rem;

    .title {
      font-size: 1.5em;
      line-height: 1;
      margin: .7em 0 .8em 0;
    }

    .description {
      font-size: 1.1em;
      margin: 0 0 1.3em 0;
    }

    .poll-form {
      .form-group {
        margin: 0 0 1.4em 0;

        label {
          cursor: pointer;
          display: inline-block;
          position: relative;
          padding: .3em 0 .3em 50px;

          input {
            display: none;
          }

          .icon {
            position: absolute;
            left: 0;
            top: 0;
            @include sprite-image("checkbox", 32, 32);
          }

          input:checked + .icon {
            @include sprite-image("checkbox-checked", 32, 32);
          }

          .text {
            font-size: 1.4em;
          }
        }

        &.submit {
          margin-top: 2em;

          .c-button {
            font-size: 1.8em;
            margin-left: .5em;
            padding-left: 1.6em;
            padding-right: 1.6em;
            margin-bottom: .5em;
          }

          .stat {
            margin-left: 2.5em;
            color: $base;
            font-size: 1.4em;
          }
        }
      }
    }

    @media #{$large-up} {
      font-size: 1.4rem;
      margin-bottom: 4em;

      .poll-form {
        .form-group {
          margin-bottom: 1em;

          label {
            padding-top: 0;
          }

          &.submit {
            button {
              float: left;
              font-size: 1.3em !important;
              margin: 0 !important;
            }

            .stat {
              float: left;
              font-size: 1.2em;
              margin: .4em 0 0 2em;
            }
          }
        }
      }
    }
  }

  //Фото-видео
  .photo-video {
    padding: 1.2em 0 0 0;
    margin: 0 0 0 0;
    font-size: 1rem;

    .menu {
      margin: 0 0 2em 0;
    }

    .select-tabs-component {
      .ct-item {
        color: $black !important;
      }
    }

    // Фото
    .photos-list {
      article {
        width: 100%;
        float: left;
        position: relative;
        margin-bottom: 21px;
        a {
          color: inherit;
          &:hover {
            text-decoration: none;
          }
        }
        .photo-item {
          display: inline-block;
          position: relative;
          width: 100%;
          background-position: center center;
          background-size: cover;
          img {
            width: 100%;
          }
          .shadow {
            background-image: linear-gradient(-180deg, rgba(0,0,0,0.14) 0%, rgba(0,0,0,0.64) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            &:before,
            &:after {
              position: absolute;
              content: '';
              height: 7px;
            }
            &:before {
              bottom: -7px;
              left: 10px;
              right: 10px;
              background-color: #D5D5D5;
            }
            &:after {
              bottom: -14px;
              left: 20px;
              right: 20px;
              background-color: #F1F1F1;
            }
          }
        }
        .recommends-label {
          position: absolute;
          top: 0;
          right: 0;
        }
        .meta-top {
          position: absolute;
          top: 0;
          left: 20px;
          text-transform: uppercase;
          padding: 20px 10px 10px 10px;
          color: $white;
        }
        .photo-content {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 0 1em 1em 20px;
          color: $white;
          .photo-title {
            font-size: 1.4em;
            margin-bottom: 10px;
          }
          .meta-bottom {
            .rating,
            .views,
            .likes,
            .photos {
              display: inline-block;
              margin-right: 10px;
            }
            .tags.__transparent {
              opacity: 0.6;
            }
            // .photos {
            //   .icon {
            //     @include svg-image("news-photo", 19, 19);
            //     float: left;
            //     margin-right: 5px;
            //   }
            //   .num {
            //     display: inline-block;
            //   }
            // }
          }
        }
      }
      @media #{$large-up} {
        article {
          width: 50%;
          padding-left: 4px;
          .photo-content {
            padding: 0 2em 2em 30px;
            .photo-title {
              font-size: 1.9em;
            }
          }
        }
      }
    }

    //Видео
    .videos-list {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-left: -15px;
      margin-right: -15px;

      article {
        flex-basis: 50%;
        background: $black;
        position: relative;
        color: $white;

        a {
          color: $white;
        }

        .img {
          background-size: cover;
          background-position: center center;
          position: relative;

          img {
            height: 100%;
            width: 100%;
            display: block;
          }

          .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            @include svg-image("play-button", 50, 50);
            cursor: pointer;
            margin-left: -25px;
            margin-top: -25px;
          }
        }

        .meta {
          padding: .3em 30px .3em .5em;
          position: relative;
          @extend .clearfix;

          .arrow {
            position: absolute;
            right: 5px;
            top: 10px;
            @include svg-image("arrow-right-white", 10, 18);
          }

          .name {
            margin: 0 0 .1em 0;
          }

          .views {
            float: left;
            margin: 0 .7em 0 0;
            @extend .clearfix;

            .icon {
              float: left;
              @include svg-image("views-white", 11, 7);
              margin: 4px 3px 0 0;
            }

            .num {
              float: left;
              font-size: .85em;
            }
          }

          .tags {
            float: left;
          }
        }
      }

      @media #{$medium-up} {
        margin-left: 0;
        margin-right: 0;
        article {
          flex-basis: 33.3%;
          min-height: 60px;
        }
      }

      @media #{$large-up} {
        article {
          .meta {
            padding: .5em 40px 1em 2em;

            .arrow {
              right: 10px;
              top: 25px;
              @include svg-image("arrow-right-white", 15, 27);
            }

            .name {
              font-size: 1.5em;
              margin-bottom: .5em;

            }

            .views {
              margin-right: 1.2em;

              .icon {
                @include svg-image("views-white", 19, 12);
              }

              .num {
                font-size: 1em;
              }
            }
          }
        }
      }
    }

    // Фотографы
    .cameramen {
      margin-top: 20px;
      .active-people-component {
        margin: 1em 0 0 0;
        .ct-item {
          width: 100%;
          padding: 15px 30px;
          border: 1px solid rgb(151, 151, 151);
          .ct-photo {
            margin: 0 30px 0 0;
            .ct-img {
              height: 71px;
              width: 71px;
            }
          }
          .ct-text-block {
            display: inline-block;
            max-width: 145px;
          }
        }
      }
      @media #{$large-up} {
        .active-people-component {
          .ct-item {
            width: 340px;
            &:not(:nth-child(3n)) {
              margin-right: 25px;
            }
            .ct-photo {
              .ct-img {
                height: 94px;
                width: 94px;
              }
            }
          }
        }
      }
    }



  }
}
