//Список фото
.photos-list-component {
  margin: 0 -15px 3em -15px;

  article {
    position: relative;
    overflow: hidden;

    .ct-photos {
      position: absolute;
      left: 0;
      top: 0;
      width: 20000em;
      height: 100%;
      @extend .clearfix;

      .ct-photo {
        float: left;
        height: 100%;
        display: inline-block;
      }
    }

    .ct-gradient {
      position: relative;
      padding: .7em 30px .7em 15px;
      background: linear-gradient(135deg, rgba(#C229A3, .8), rgba(#A87520, .8));
      color: $white;
      min-height: 80px;
      overflow: hidden;

      a {
        color: $white;
      }

      .ct-arrow {
        position: absolute;
        right: 10px;
        top: 20px;
        @include svg-image("arrow-right-white", 12, 20);
      }

      .ct-name {
        font-size: 1.2em;
        margin: 0 0 1em 0;
      }

      .ct-meta {
        @extend .clearfix;

        .ct-amount {
          float: left;
          margin: 0 1em 0 0;
          opacity: .8;
          font-size: .85em;
        }

        .ct-views {
          float: left;
          margin: 0 1em 0 0;
          @extend .clearfix;

          .ct-icon {
            float: left;
            @include svg-image("views-transparent", 11, 7);
            margin: 4px 3px 0 0;
          }

          .ct-num {
            float: left;
            opacity: .8;
            font-size: .85em;
          }
        }

        .ct-tags {
          float: left;

          a {
            margin-right: .6em;
          }
        }
      }
    }

    &:nth-child(2n) {
      .ct-gradient {
        background: linear-gradient(135deg, rgba(#7729C2, .8), rgba(#20A887, .8));
      }
    }

    &:nth-child(3n) {
      .ct-gradient {
        background: linear-gradient(135deg, rgba(#29C23A, .8), rgba(#A89820, .8));
      }
    }
  }

  @media #{$large-up} {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5em;

    article {
      .ct-gradient {
        padding: 1.5em 40px 1.2em 1.5em;
        min-height: 110px;

        .ct-arrow {
          right: 20px;
          top: 30px;
          @include svg-image("arrow-right-white", 15, 27);
        }

        .ct-name {
          font-size: 1.8em;
        }

        .ct-meta {
          .ct-amount {
            font-size: 1em;
            margin-right: 2em;
          }

          .ct-views {
            margin-right: 2em;

            .ct-icon {
              @include svg-image("views-transparent", 19, 12);
            }

            .ct-num {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}